/* eslint-disable react/require-default-props */
import React from 'react';
import { BoxProps } from 'rebass/styled-components';

import { mediumBreakpoint } from '../../../../utils/breakpoints';
import { chunkArrayCategories } from '../../../../utils/common';
import { useCurrentBreakpoint } from '../../../../utils/hooks/breakpoints';
import CategoryCard from '../../../Cards/CategoryCard';

import { StyledVerticalCardContainer, StyledVerticalCategoryWrapper, StyledWrapper } from './Styled';

interface VerticalProps extends BoxProps {
  categories: any[];
  twoColumns?: boolean;
}

export const Vertical = ({ categories, twoColumns }: VerticalProps) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const categoryChunkSize = currentBreakpoint === mediumBreakpoint || twoColumns ? 2 : 3;
  const chunkedCategories = chunkArrayCategories(categories, categoryChunkSize);

  return (
    <StyledWrapper>
      {chunkedCategories.map(({ chunk, key }) => (
        <StyledVerticalCardContainer key={key} className={`cards-${chunk.length}`} twoColumns={twoColumns}>
          {chunk.map(({ id, ...categoryRest }) => (
            <StyledVerticalCategoryWrapper key={id} className={`cards-${chunk.length}`} twoColumns={twoColumns}>
              <CategoryCard id={id} {...categoryRest} />
            </StyledVerticalCategoryWrapper>
          ))}
        </StyledVerticalCardContainer>
      ))}
    </StyledWrapper>
  );
};

export default Vertical;
