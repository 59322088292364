import { ContentfulSearchResultsSection, ContentfulSettings } from '../../generated/gatsby.types';
import { handleNetlifyFormSubmission, search } from '../../ui-lib/utils';

const extractShortDescription = (item: any) => {
  return item.searchContactsDescription && item.searchContactsDescription.text
    ? item.searchContactsDescription.text
    : null;
};

const transformSearchResultsSection = (
  item: ContentfulSearchResultsSection,
  queryString: any,
  contentfulSettings: ContentfulSettings,
) => {
  return {
    __typename: 'SearchResultsSection',
    contactsShortDescription: extractShortDescription(contentfulSettings),
    contactsTitle: contentfulSettings.searchContactsTitle,
    defaultQuery: queryString.q,
    eventPageSlug: contentfulSettings.eventsPage?.slug,
    handleFormSubmit: handleNetlifyFormSubmission,
    handleSearch: search,
    key: item.id,
    newsPageSlug: contentfulSettings.newsPage?.slug,
    productPageSlug: contentfulSettings.productsPage?.slug,
  };
};

export default transformSearchResultsSection;
