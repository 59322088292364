import { ContentfulNewsEdge } from '../../generated/gatsby.types';
import extractCta from '../Cta';

const transformCtaGroup = (data: Array<ContentfulNewsEdge>) => ({
  __typename: 'CtaGroup',
  items: data?.callToAction
    .map((edge: any) => extractCta({ cta: edge }))
    .filter(Boolean)
    .slice(0),
  title: data?.title,
});

export default transformCtaGroup;
