import React from 'react';
import { useIntl } from 'react-intl';

import { Calendar as CalendarIcon, LocationPin as LocationIcon } from '../../../icons';
import DocumentDownload, { DocumentDownloadProps } from '../../DocumentDownload';
import { ContactsForm } from '../../Forms';
import { HeroSectionModule, InfoSectionModule, UpcomingEventsModule } from '../../Modules';
import RichText, { RichTextProps } from '../../RichText';

import {
  DocumentDownloadContainer,
  DocumentDownloadContainerMobile,
  StyledContactsFormContainer,
  StyledContainer,
  StyledContentContainer,
  StyledEventInfo,
  StyledEventInfoMobileWrapper,
  StyledEventInfoWrapper,
  StyledHeaderContainer,
  StyledLeftColumn,
  StyledRightColumn,
} from './Styled';

export interface EventPageProps {
  title: string;
  shortDescription: string;
  eventDate?: string;
  eventLocation?: string;
  contentsSection: RichTextProps;
  image: any;
  showContactsFormSubscribeOptions?: boolean;
  handleFormSubmit: (name: string, formData: { [key: string]: string }) => void;
  eventModuleProps: any;
  documentDownload?: DocumentDownloadProps;
}

const renderEventInfo = (eventDate: string | undefined, eventLocation: string | undefined) => (
  <>
    {eventDate && (
      <StyledEventInfo>
        <CalendarIcon />
        {eventDate}
      </StyledEventInfo>
    )}
    {eventLocation && (
      <StyledEventInfo>
        <LocationIcon />
        {eventLocation}
      </StyledEventInfo>
    )}
  </>
);

const EventPage = ({
  title,
  shortDescription,
  contentsSection,
  eventDate,
  eventLocation,
  image,
  showContactsFormSubscribeOptions = true,
  handleFormSubmit,
  eventModuleProps,
  documentDownload,
}: EventPageProps) => {
  const intl = useIntl();

  return (
    <>
      <StyledContainer>
        <StyledHeaderContainer>
          <HeroSectionModule title={title} shortDescription={shortDescription} />
        </StyledHeaderContainer>
      </StyledContainer>
      <InfoSectionModule image={image} />
      <StyledContentContainer>
        <StyledLeftColumn>
          {eventDate || eventLocation ? (
            <StyledEventInfoWrapper>{renderEventInfo(eventDate, eventLocation)}</StyledEventInfoWrapper>
          ) : null}
          <StyledContactsFormContainer>
            <ContactsForm
              title={intl.formatMessage({ id: 'forms.contact.title' })}
              shortDescription={intl.formatMessage({ id: 'forms.contact.description' })}
              showSubscribeOptions={showContactsFormSubscribeOptions}
              handleFormSubmit={handleFormSubmit}
            />
          </StyledContactsFormContainer>
        </StyledLeftColumn>
        <StyledRightColumn>
          {eventDate || eventLocation ? (
            <StyledEventInfoMobileWrapper>{renderEventInfo(eventDate, eventLocation)}</StyledEventInfoMobileWrapper>
          ) : null}
          <RichText {...contentsSection} />
          {documentDownload && (
            <>
              <DocumentDownloadContainer>
                <DocumentDownload
                  documentTitle={documentDownload.documentTitle}
                  documentType={documentDownload.documentType}
                  fileSize={documentDownload.fileSize}
                  downloadLink={documentDownload.downloadLink}
                  showDocumentInfo
                />
              </DocumentDownloadContainer>
              <DocumentDownloadContainerMobile>
                <DocumentDownload
                  documentTitle={documentDownload.documentTitle}
                  documentType={documentDownload.documentType}
                  fileSize={documentDownload.fileSize}
                  downloadLink={documentDownload.downloadLink}
                  showDocumentInfo
                  renderMobileVariant
                />
              </DocumentDownloadContainerMobile>
            </>
          )}
        </StyledRightColumn>
      </StyledContentContainer>
      {eventModuleProps && <UpcomingEventsModule {...eventModuleProps} data-appsearch-ignore="true" />}
    </>
  );
};

export default EventPage;
