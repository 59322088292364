import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

//
import Media from '../../../utils/Media';

export const StyledModuleHeading = styled.h1`
  color: ${props => props.theme.colors.text};
  font-weight: ${props => props.theme.newsListing.titleFontWeight || 'normal'};
  word-break: break-word;
  margin-top: 0px;
  line-height: 1.14;
  margin-bottom: 0px;

  ${Media({
    fontSize: ['40px', '40px', '40px', '56px'],
  })}
`;

export const StyledModuleDescription = styled(Text)`
  color: ${props => props.theme.colors.text};
  line-height: 1.5;
  font-size: 1.25rem;

  ${Media({
    marginTop: ['1rem', null, null, '1.5rem'],
  })}
`;

export const StyledHeadingAndDescriptionWrapper = styled(Box)`
  ${Media({
    marginLeft: ['0px', null, 'auto', 'auto'],
    marginRight: ['auto', null, 'auto', 'auto'],
    maxWidth: ['none', 'none', '605px', '605px'],
  })}
`;

export const StyledContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;

  ${Media({
    fontSize: ['2rem', null, null, '2.5rem'],
    marginLeft: ['1rem', null, '2rem', '58px'],
    marginRight: ['1rem', null, '2rem', '58px'],
    marginTop: ['40px', null, null, '81px'],
  })}
`;

export const StyledLoadMoreWrapper = styled(Box)`
  align-self: flex-start;
`;

export const StyledNewsCardWrapper = styled(Box)`
  ${Media({
    marginBottom: ['3.5rem', null, null, '1rem'],
    width: ['100%', null, 'calc(50% - 0.5rem)', '100%'],
  })}

  &:nth-child(odd) {
    ${Media({
      marginRight: [null, null, '1rem', '0px'],
    })}
  }
`;

export const StyledCardsWrapper = styled(Flex)`
  flex-wrap: wrap;
  width: 100%;
  margin-top: 48px;
  overflow: hidden;
  ${Media({
    marginBottom: ['-3.5rem', null, null, '-1rem'],
  })}
`;

export const StyledModuleHeadingSecondary = styled.h1`
  font-size: 40px;
  line-height: 1.2;
  font-weight: normal;
  margin-top: 0px;
  width: 100%;
  color: ${props => props.theme.colors.text};
  margin-bottom: 16px;
  word-break: break-word;
  ${Media({
    maxWidth: ['100%', null, null, '60%'],
  })}
`;

export const StyledModuleDescriptionSecondary = styled(Text)`
  font-size: 20px;
  width: 100%;
  color: ${props => props.theme.colors.darkGrey};
  ${Media({
    maxWidth: ['100%', null, null, '60%'],
  })}
`;

export const StyledHeadingAndDescriptionWrapperSecondary = styled(Box)`
  margin: 0;
  width: 100%;
`;
