/* eslint-disable react/require-default-props */
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { BoxProps } from 'rebass/styled-components';

import Button from '../../Button';
import Cta from '../../Cta';
import { StyledModuleContainerInfoVariant } from '../../Layout/Styled';

import {
  StyledCategory,
  StyledImage,
  StyledText,
  StyledTextBox,
  StyledTextBoxCta,
  StyledTextBoxTitle,
  StyledTextBoxWrapper,
} from './Styled';

type CtaProps = {
  label: string;
  to: string;
};

interface CategoryCardModuleProps extends BoxProps {
  /** title */
  title?: string;
  /** optional module short description */
  shortDescription?: string;
  /** section category */
  sectionCategory?: string;
  /** card cta */
  cta?: CtaProps | null;
  /** the background image */
  image: any;
}

const InfoSectionModule = React.forwardRef(
  ({ title, shortDescription, cta, sectionCategory, image, ...rest }: CategoryCardModuleProps, ref) => (
    <StyledModuleContainerInfoVariant ref={ref} {...(rest as any)} data-appsearch-ignore="true">
      <StyledTextBoxWrapper>
        <StyledImage>
          {image?.asset && (
            // <Img
            //   loading="eager"
            //   fluid={image.asset.fluid}
            //   alt={image.alt}
            //   style={{ width: '100%', height: '100%' }}
            // />
            <GatsbyImage
              image={getImage(image.asset) as IGatsbyImageData}
              alt={image.alt ? image.alt : image.title}
              loading="eager"
              style={{ height: '100%', width: '100%' }}
            />
          )}
        </StyledImage>
        {title ? (
          <StyledTextBox>
            {sectionCategory && <StyledCategory>{sectionCategory}</StyledCategory>}

            <StyledTextBoxTitle>{title}</StyledTextBoxTitle>

            {shortDescription && <StyledText>{shortDescription}</StyledText>}

            {cta && (
              <StyledTextBoxCta>
                <Cta to={cta.to}>
                  <Button variant="primary-with-background">{cta.label}</Button>
                </Cta>
              </StyledTextBoxCta>
            )}
          </StyledTextBox>
        ) : null}
      </StyledTextBoxWrapper>
    </StyledModuleContainerInfoVariant>
  ),
);
InfoSectionModule.displayName = 'InfoSectionModule';

export default InfoSectionModule;
