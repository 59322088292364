import * as React from 'react';

const ChevronLeft = () => (
  <svg
    fill="none"
    height="24"
    stroke="currentColor"
    strokeWidth="2.3"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.75 19.5L8.25 12l7.5-7.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ChevronLeft;
