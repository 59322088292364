import * as React from 'react';

const Icon = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 28" width="100" height="28">
    <defs>
      <image
        width="100"
        height="28"
        id="img1"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAcAQMAAACtXYi7AAAAAXNSR0IB2cksfwAAAANQTFRFAAAAp3o92gAAAAF0Uk5TAEDm2GYAAAAOSURBVHicY2AYBYMBAAABiAAB8mGsUQAAAABJRU5ErkJggg=="
      />
    </defs>
    <style></style>
    <use href="#img1" x="0" y="0" />
    <path d="m0 28c0 0 13.3-4 17-12 3.5-7.4 16.5-9 22-9 5.5 0 52 0 52 0 0 0 9 0.5 9-7 0-7.5 0 28 0 28z" />
  </svg>
);

export default Icon;
