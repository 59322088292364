import { ContentfulNewsEdge } from '../generated/gatsby.types';

import extractShortDescription from './ShortDescription';

const transformNewsItems = (newsItems: Array<ContentfulNewsEdge>) => {
  return newsItems
    .map((edge: any) => {
      const newsItem = edge.node;
      if (newsItem) {
        return {
          date: newsItem.date,
          id: newsItem.id,
          image: newsItem.image,
          link: newsItem.slug,
          shortDescription: extractShortDescription(newsItem),
          title: newsItem.title,
        };
      }
      return null;
    })
    .filter(Boolean)
    .slice(0);
};

export default transformNewsItems;
