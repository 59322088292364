import { ContentfulNewsConnection } from '../generated/gatsby.types';

import transformNewsItems from './NewsItems';
import extractShortDescription, { ItemType } from './ShortDescription';

const transformNewsListingSection = (item: ItemType, newsItems: ContentfulNewsConnection, variant = 'primary') => {
  return {
    __typename: 'NewsListingSection',
    initialAmount: 5,
    key: item.id,
    news: transformNewsItems(newsItems.edges),
    shortDescription: extractShortDescription(item),
    title: item.title,
    variant,
  };
};

export default transformNewsListingSection;
