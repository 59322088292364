import extractCta from '../Cta';

const transformHeroBanner = (item: any) => {
  return {
    __typename: 'HeroBanner',
    cta: extractCta(item),
    ctaStyle: item.callToActionStyle,
    description: item.description?.raw || '',
    image: item.image,
    key: item.id,
    secondCta: extractCta({ cta: item.callToActionSecond }),
    secondCtaStyle: item.callToActionSecondStyle,
    title: item.title,
    video: item.video,
  };
};

export default transformHeroBanner;
