import React from 'react';
import { useIntl } from 'react-intl';

interface GetEmbedAttributesProps {
  allow?: string;
  allowFullScreen?: boolean;
  enablejsapi?: number;
  frameBorder?: string;
}

export const extractYouTubeVideoId = (url: string) => {
  const regExp =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/embed\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regExp);

  if (match && match[1]) {
    return match[1];
  }

  return url;
};

export const getEmbedAttributes = (): GetEmbedAttributesProps => {
  return {
    allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
    allowFullScreen: true,
    enablejsapi: 1,
    frameBorder: '0',
  };
};
export const getEmbedIframe = ({ url, title = '' }: GetEmbedIframeProps) => {
  return <iframe src={getEmbedSrc({ url })} title={title || ''} {...getEmbedAttributes()} />;
};

export const getEmbedProps = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl();
  const { locale } = intl;

  return `cc_locale_pref=${locale}&hl=${locale}&modestbranding=1&rel=0`;
};

interface GetEmbedSrcProps {
  url: string;
  autoplay?: boolean;
}

export const getEmbedSrc = ({ url }: GetEmbedSrcProps) =>
  `${getEmbedUrl(getID(url))}?${getEmbedProps()}&controls=1&autoplay=0`;

interface GetEmbedIframeProps extends GetEmbedSrcProps {
  url: string;
  title?: string;
}

export const getEmbedUrl = (id: string): string => `https://www.youtube-nocookie.com/embed/${id}`;

export const getID = (url: string): string => {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  if (match && match[2].length === 11) {
    return match[2];
  }

  return '';
};
