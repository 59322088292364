import { getSrc } from 'gatsby-plugin-image';
import * as React from 'react';

import {
  ContentfulAdvice,
  ContentfulBasicPage,
  ContentfulEvent,
  ContentfulImage,
  ContentfulMenuItem,
  ContentfulNews,
  ContentfulProduct,
  ContentfulSettings,
  ContentfulSettingsSiteDescriptionTextNode,
  Maybe,
} from '../../generated/gatsby.types';
import { MainLayout } from '../../ui-lib/components';
import { HEADER_VARIANTS } from '../../ui-lib/components/TopLevelHeader/Header';
import { getIntlSlug } from '../../ui-lib/utils/common';
import richTextRawToJson from '../../utils/contentful-rich-text-raw-to-json';
import handleNewsletterFormSubmission from '../../utils/handleNewsletterFormSubmission';

const dontPrefixPaths = process.env.DONT_PREFIX_PATHS_LOCALE === 'true';

type NavItemType = {
  to: string;
  label: string;
  children?: Array<NavItemType>;
  id: string;
  parentId: string;
  pageId: string;
};

type PageProps = {
  id: string;
  pageTitle?: string;
  pageDescription?: string;
  pageLocale: string;
  pageType: string;
  siteUrl: string;
  pageSlug: string;
  contentfulSettings: ContentfulSettings;
  isHomepage: boolean;
  ogPageType: string;
  pageSettings?: any;
  pageImage?: ContentfulImage;
  swiftTypeDate?: string;
  swiftTypeLocation?: string;
  updatedAt: string;
};

type SubscribeFormProps = {
  showFooterSubscribeForm: boolean;
  showFooterSubscribeFormCountryField: boolean;
  subscribeFormHeading?: any | null;
};

type LayoutProps = {
  pageProps: PageProps;
  children: React.ReactNode;
  subscribeForm: SubscribeFormProps;
  translatedSlugs: Array<ContentfulBasicPage | ContentfulEvent | ContentfulNews | ContentfulAdvice | ContentfulProduct>;
};

const buildCanonicalLink = (extractedPageSettings: any, siteUrl: string, locale: string, slug: string) => {
  const pagePath = dontPrefixPaths ? slug : `${locale}/${slug}`;

  return extractedPageSettings && extractedPageSettings.canonicalUrlpath
    ? extractedPageSettings.canonicalUrlpath
    : `${siteUrl}/${pagePath}`;
};

const buildMenuItem = (item: any, headerVariant: keyof typeof HEADER_VARIANTS, parentId?: string) => {
  const menuItem: NavItemType = { children: [], id: '', label: '', pageId: '', parentId: '', to: '' };

  if (item?.title) {
    menuItem.label = item?.title || '';
    if (item?.externalUrl) {
      menuItem.to = item.externalUrl;
    } else if (item.navigationElement?.slug) {
      const { slug, id } = item.navigationElement;
      menuItem.to = slug === 'homepage' ? '/' : slug;
      menuItem.pageId = id;
    }
  }

  if (item.id) menuItem.id = item.id;
  if (parentId) menuItem.parentId = parentId;

  return menuItem;
};

const buildMenuNavigation = (
  contentfulNav: Maybe<ContentfulMenuItem>[] | null | undefined,
  headerVariant: keyof typeof HEADER_VARIANTS,
  secondary = false,
) => {
  const navigation: Array<NavItemType> =
    contentfulNav?.map(item => {
      const menuItem: NavItemType = buildMenuItem(item, headerVariant);

      if (item?.childrens && secondary) {
        item.childrens.forEach(children => {
          const child: NavItemType = buildMenuItem(children, headerVariant, item.id);
          if (child && menuItem && menuItem.children) {
            menuItem.children.push(child);
          }
        });
      }

      return menuItem;
    }) || [];

  return navigation;
};

const extractSiteDescription = (item: any) => (item.text ? item.text : item);

const getPageSettings = (pageSettings: any) => {
  if (!pageSettings) {
    return {};
  }

  const { title, description, nofollow, noindex, canonicalUrlpath, keywords } = pageSettings;

  return {
    canonicalUrlpath,
    description,
    keywords,
    nofollow,
    noindex,
    title,
  };
};

const extractImage = (image: any) => {
  if (!image) {
    return null;
  }

  // if (image && image.socialImage && image.socialImage.fixed && image.socialImage.fixed.src) {
  //   return image.socialImage.fixed.src;
  // }
  if (image?.socialImage?.gatsbyImageData) {
    return getSrc(image?.socialImage?.gatsbyImageData);
  }

  // if (image && image.asset && image.asset.fixed && image.asset.fixed.src) {
  //   return image.asset.fixed.src;
  // }
  if (image?.asset?.gatsbyImageData) {
    return getSrc(image?.asset?.gatsbyImageData);
  }

  return null;
};

const getPageDescription = (
  pageDescription: string | undefined,
  extractedSettings: any,
  siteDescription: ContentfulSettingsSiteDescriptionTextNode | undefined | null,
) => {
  const settingsDescription = extractedSettings?.description || extractedSettings?.legacyDescription || '';

  return (
    pageDescription ||
    (extractedSettings && settingsDescription ? settingsDescription : extractSiteDescription(siteDescription))
  );
};

const getSiteData = ({
  id,
  pageTitle,
  pageDescription,
  pageLocale,
  pageType,
  siteUrl,
  pageSlug,
  contentfulSettings,
  isHomepage,
  ogPageType,
  pageSettings,
  pageImage,
  swiftTypeDate,
  swiftTypeLocation,
  updatedAt,
}: PageProps) => {
  const { siteDescription, siteImage, headerVariant, siteTitle, navigation, footer } = contentfulSettings;

  const extractedPageSettings = getPageSettings(pageSettings);

  const socialLinks = footer?.socialLinks?.map(link => {
    return {
      label: link?.title,
      to: link?.url,
    };
  });
  const siteData = {
    footer: {
      banner: footer?.banner,
      contacts: (footer?.officeInformation && richTextRawToJson(footer.officeInformation, pageLocale)) || '',
      legalLinks: buildMenuNavigation(footer.secondaryLinks, headerVariant as keyof typeof HEADER_VARIANTS),
      navigation: buildMenuNavigation(footer.primaryLinks, headerVariant as keyof typeof HEADER_VARIANTS),
      socialLinks,
    },
    header: {
      navigation: buildMenuNavigation(navigation.primaryLinks, headerVariant as keyof typeof HEADER_VARIANTS, true),
    },
    metaData: {
      canonical: isHomepage
        ? `${siteUrl}/${pageLocale}`
        : buildCanonicalLink(extractedPageSettings, siteUrl, pageLocale, pageSlug),
      description: getPageDescription(pageDescription, extractedPageSettings, siteDescription),
      image: pageImage ? extractImage(pageImage) : extractImage(siteImage),
      nofollow: extractedPageSettings && extractedPageSettings.nofollow ? extractedPageSettings.nofollow : undefined,
      noindex: extractedPageSettings && extractedPageSettings.noindex ? extractedPageSettings.noindex : undefined,
      ogPageType,
      pageLocale,
      pageType,
      searchTags: extractedPageSettings?.keywords,
      siteName: siteTitle,
      swiftTypeDate,
      swiftTypeLocation,
      title: pageTitle || (extractedPageSettings && extractedPageSettings.title ? extractedPageSettings.title : null),
      updatedAt,
    },
    pageId: id,
  };

  return siteData;
};

const Layout = ({ pageProps, children, subscribeForm, translatedSlugs }: LayoutProps) => {
  const { isHomepage, pageImage, pageLocale } = pageProps;
  const siteData = getSiteData(pageProps);

  const headerVariant = pageProps.contentfulSettings.headerVariant || '';

  const searchSlug = getIntlSlug(pageProps.contentfulSettings.searchPage?.slug || '');

  const handleSearch = (q: string) => {
    // eslint-disable-next-line no-undef
    if (window) {
      // eslint-disable-next-line no-undef
      window.location.href = `${searchSlug}?q=${q}`;
    }
  };

  let translatedSlugsCopy: Array<
    ContentfulBasicPage | ContentfulEvent | ContentfulNews | ContentfulAdvice | ContentfulProduct
  > = [];

  if (translatedSlugs) {
    translatedSlugsCopy = [...translatedSlugs];

    if (isHomepage) {
      translatedSlugsCopy = translatedSlugsCopy.map(translation => {
        // eslint-disable-next-line no-param-reassign
        translation.slug = '';
        return translation;
      });
    }
  }

  return (
    <MainLayout
      siteData={siteData}
      searchPreviewImage={pageImage}
      headerSearchFunction={handleSearch}
      subscribeForm={subscribeForm}
      headerVariant={headerVariant.toLowerCase()}
      onSubscribeFormSubmit={handleNewsletterFormSubmission}
      pageLocale={pageLocale}
      translatedSlugs={translatedSlugsCopy}
      isHomepage={isHomepage}
    >
      {children}
    </MainLayout>
  );
};

export default Layout;
