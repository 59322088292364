import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../utils/Media';

interface StyledText {
  alignment?: 'Left' | 'Center' | 'Right';
}

export const StyledCenteredText = styled.div`
  scroll-margin-top: 200px;
  ${Media({
    margin: ['48px 1rem auto 1rem', '48px auto auto 20px', '48px auto auto 20%', '57px auto'],
    width: ['auto', 'auto', 'auto', '645px'],
  })}
  blockquote {
    ${Media({
      width: ['auto', 'auto', 'auto', '1042px'],
    })}
  }
`;

export const StyledDocumentDownload = styled(Box)`
  ${Media({
    marginBottom: ['40px', null, '56px'],
  })}
`;

export const StyledImage = styled(Box)`
  ${Media({
    marginBottom: ['40px', null, '56px'],
  })}
`;

export const StyledInlineAsset = styled.div`
  padding: 1rem 2rem;
`;

export const StyledMedia = styled(Box)`
  ${Media({
    marginBottom: ['40px', null, '56px'],
  })}
`;

export const StyledShortBanner = styled(Box)`
  ${Media({
    marginBottom: ['40px', null, '56px'],
  })}
`;

export const StyledTable = styled(Box)`
  ${Media({
    marginBottom: ['40px', null, '56px'],
    paddingRight: ['1rem', null, null, '0'],
  })}
`;

export const StyledText = styled.div<StyledText>`
  scroll-margin-top: 200px;
  margin-top: 1rem;

  ${props =>
    props.alignment &&
    `
    text-align: ${props.alignment.toLowerCase()};
  `}

  p {
    ${props => ({ ...props.theme.styles.p })};
  }

  h1 {
    ${props => ({ ...props.theme.styles.h1 })};
  }

  h2 {
    ${props => ({ ...props.theme.styles.h2 })};

    ${Media({
      marginBottom: ['1rem', null, '1rem'],
      marginTop: ['2rem', null, '3.7rem'],
    })}
  }

  h3 {
    ${props => ({ ...props.theme.styles.h3 })};

    ${Media({
      marginBottom: ['1rem', null, '1rem'],
      marginTop: ['2rem', null, '3.7rem'],
    })}
  }

  h4 {
    ${props => ({ ...props.theme.styles.h4 })};
  }

  h5 {
    ${props => ({ ...props.theme.styles.h5 })};
  }

  h6 {
    ${props => ({ ...props.theme.styles.h6 })};
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-right: 1rem;
    white-space: break-spaces;
  }

  ${props =>
    props.alignment === 'Right' &&
    `
    p, h1, h2, h3, h4, h5, h6 {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  `}

  a {
    ${props => ({ ...props.theme.styles.a })};
  }

  ul,
  ol {
    list-style: none;
    margin: -1.2rem 0 0;
    padding: 0;

    ${Media({
      marginBottom: ['1rem', null, '1.7rem'],
    })}

    li {
      > p {
        margin: 0 0 0.2rem;
      }
    }
  }

  ol {
    counter-reset: new-counter;

    li {
      counter-increment: new-counter;
      padding-left: 2.25rem;
      position: relative;

      &::before {
        content: counter(new-counter) '.';
        position: absolute;
        left: 0;
        top: 3px;
      }
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 2.25rem;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 9px;
        display: block;
        width: 10px;
        height: 10px;
        background-color: ${props => props.theme.styles.li.bulletColor};
        border-radius: ${props => props.theme.styles.li.bulletBorderRadius};
      }
    }
  }

  blockquote {
    margin: 0;

    ${Media({
      marginBottom: ['1rem', null, '1.7rem'],
      marginRight: ['-70px', null, null, '0px'],
      paddingLeft: ['2rem', null, null, '56px'],
    })}
  }
`;

export const StyledTextHighlight = styled(Box)`
  background-color: ${props => props.theme.colors.superLightGrey};
  color: ${props => props.theme.colors.text};
  position: relative;
  margin-bottom: 2rem;
  padding-top: 1rem;

  p {
    font-size: 16px;
    line-height: 1.75;
  }

  ul li::before {
    background-color: ${props => props.theme.styles.li.bulletColor};
  }

  ${Media({
    marginLeft: ['-1rem', '-1rem', '-24px', '-62px'],
    marginRight: ['-1rem', null, '-24px', '0px'],
    paddingBottom: ['2.875rem', null, '1rem', '3rem'],
    paddingLeft: ['1rem', null, '24px', '62px'],
    paddingRight: ['1rem', null, '1.5rem', '2rem'],
  })}
`;

export const StyledVideo = styled(Box)`
  ${Media({
    marginBottom: ['40px', null, '56px'],
  })}
`;
