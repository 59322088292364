/* eslint-disable import/prefer-default-export */
import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';
import Cta from '../../Cta';

export const StyledCard = styled(Box)`
  height: 100%;
  &.vertical {
    width: 100%;
    max-width: 441px;
    ${Media({
      maxWidth: ['100%', '100%', '441px', null],
    })}
  }
  &.horizontal {
    width: 100%;
    display: flex;

    > div {
      display: flex;
      width: 100%;

      ${Media({
        flexDirection: ['column-reverse', null, null, 'row'],
        maxWidth: ['100%', '100%', '441px', 'none'],
      })}
    }
  }
`;

export const StyledImageBox = styled(Box)`
  width: 100%;
  display: block;
  background-size: cover;
  background-color: ${props => props.theme.colors.muted};
  position: relative;
`;

export const StyledImageBoxLink = styled(Cta)`
  text-decoration: none;
  position: relative;
  width: 100%;
  overflow: hidden;

  ${Media({
    height: ['236px', null, null, `300px`],
  })}

  > ${StyledImageBox} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  ${StyledCard}.horizontal & {
    ${Media({
      marginLeft: ['0', null, null, '20px'],
      width: ['100%', null, null, `calc(420 / 1324 * 100%)`],
    })}

    &::before {
      ${Media({
        content: ['', null, null, 'none'],
      })}
    }
  }
`;

export const StyledHorizontalImageBox = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  background-size: cover;
  background-color: ${props => props.theme.colors.muted};
`;

export const StyledContentBox = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${Media({
    padding: ['1.5rem 0', null, null, '2rem 0'],
  })}

  ${StyledCard}.horizontal & {
    flex: 1;

    ${Media({
      width: ['100%', null, null, 'auto'],
    })}

    &.no-image {
      width: 100%;
    }
  }
`;

export const StyledNewsDate = styled.p`
  font-size: 0.875rem;
  margin: 0 0 1rem 0;

  &::after {
    content: '';
    display: block;
    background-color: ${props => props.theme.colors.textMuted};
    height: 1px;
    width: 24px;
    margin-top: 0.875rem;
  }
`;

export const StyledTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;

  a {
    color: ${props => props.theme.colors.darkGray};
    text-decoration: none;
  }

  ${Media({
    marginBottom: ['1.375rem', null, null, '1.25rem'],
  })}
`;

export const StyledDescription = styled(Text)`
  flex: 1;
  font-size: 1rem;
  line-height: 1.75;
  max-width: 527px;
  color: ${props => props.theme.colors.darkGray};
  margin-bottom: 0px;
  white-space: pre-wrap;
`;

export const StyledCtaWrapper = styled.div`
  padding-bottom: 1rem;
  display: flex;

  ${props =>
    Media({
      borderTop: [
        `1px solid ${props.theme.colors.lightGray}`,
        `1px solid ${props.theme.colors.lightGray}`,
        `1px solid ${props.theme.colors.lightGray}`,
        'none',
      ],
      marginBottom: ['-1.5rem', null, null, '-2rem'],
      marginTop: ['1.5rem', null, null, '0px'],
      paddingTop: ['1.5rem', null, null, '1rem'],
    })}

  ${StyledCard}.horizontal & {
    ${Media({
      marginTop: [null, null, null, '1rem'],
    })}
  }

  ${StyledCard}.vertical & {
    margin-top: 1.5rem;
  }
`;

export const StyledCta = styled(Cta)`
  color: ${props => props.theme.cardCtas.color};
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
  text-transform: ${props => props.theme.cardCtas.textTransform};
  letter-spacing: ${props => props.theme.cardCtas.letterSpacing};
  font-size: ${props => props.theme.cardCtas.fontSize};
  line-height: ${props => props.theme.cardCtas.lineHeight};

  > span {
    vertical-align: middle;
  }

  > svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.75rem;
    transform: translateX(-4px);
    fill: ${props => props.theme.cardCtas.arrowColor};

    > * {
      fill: ${props => props.theme.cardCtas.arrowColor};
    }
  }

  &:hover {
    color: ${props => props.theme.cardCtas.hoverColor};

    > svg {
      fill: ${props => props.theme.cardCtas.arrowHoverColor};

      > * {
        fill: ${props => props.theme.cardCtas.arrowHoverColor};
      }
    }
  }
`;
