import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

//
import Media from '../../../utils/Media';

import Dealer from './Dealer/Dealer';

export const StyledDealersHeader = styled.div`
  color: ${props => `${props.theme.colors.text}`};

  @media (min-width: 600px) {
    max-width: 900px !important;
  }

  @media (max-width: 1084px) {
    > input {
      min-width: 350px;
    }
  }

  ${Media({
    marginBottom: ['2rem', null, '4.325rem'],
    marginLeft: ['0', null, 'auto'],
    marginRight: ['0', null, 'auto'],
    maxWidth: ['100%', null, '520px'],
  })}
`;

export const StyledModuleHeading = styled.h2`
  color: ${props => props.theme.colors.text};
  font-weight: ${props => props.theme.dealer.titleFontWeight || 'normal'};
  line-height: 1.2;
  word-break: break-word;
  margin-top: 0px;

  ${Media({
    fontSize: ['2.5rem', null, '3.5rem'],
    marginBottom: ['1rem', null, '1.25rem'],
  })}
`;

export const StyledModuleDescription = styled(Text)`
  color: ${props => props.theme.colors.text};
  line-height: 1.5;
  font-size: 1.25rem;

  ${Media({
    marginBottom: ['2.5rem', null, '2rem'],
  })}
`;

export const StyledDealersSearch = styled.div`
  display: flex;
  justify-content: space-between;

  > input {
    height: 48px;
    border-radius: ${props => props.theme.formInputs.borderRadius};
    padding: 2px 1.5rem 0;
    outline: none;
    min-width: 420px;
    font-family: ${props => props.theme.fonts.body};

    &:focus,
    &:hover {
      border: ${props => `1px solid ${props.theme.formInputs.activeColor}`};
    }

    ${Media({
      marginBottom: ['0.5rem', null, null, '0'],
      marginRight: [null, null, null, '1rem'],
      width: ['100%', null, `calc(100% - 156px)`],
    })}

    &::-webkit-input-placeholder {
      color: #999;
    }
    &::-moz-placeholder {
      color: #999;
    }
    &:-ms-input-placeholder {
      color: #999;
    }
    &:-moz-placeholder {
      color: #999;
    }
    &::placeholder {
      color: #999;
    }
  }

  > button {
    min-width: 147px;
    ${Media({
      width: ['100%', null, '147px'],
    })}
  }

  ${Media({
    flexDirection: ['column', null, 'row'],
  })}

  @media (max-width: 820px) {
    > input {
      min-width: 184px;
    }
  }

  @media (max-width: 1084px) {
    > input {
      min-width: 184px;
    }
  }
`;

export const StyledDealersResult = styled(Text)`
  margin-top: 1rem;
`;

export const StyledContainer = styled(Box)`
  position: relative;
  max-width: ${props => props.theme.sizes.xl};
  margin: 0 auto;
  overflow: hidden;
  min-height: 428px;

  ${Media({
    marginLeft: ['1rem', null, '2rem', '58px'],
    marginRight: ['1rem', null, '2rem', '58px'],
    marginTop: ['40px', null, null, '81px'],
  })}
`;

export const StyledDealersContainer = styled(Flex)`
  flex-wrap: wrap;
  ${Media({
    marginBottom: ['-2.5rem', null, null, '-3.5rem'],
  })}

  > div {
    padding-right: 1rem;

    ${Media({
      marginBottom: ['2.5rem', null, null, '3.5rem'],
      width: ['100%', '50%', '33.33%', '25%'],
    })}
  }
`;

export const StyledDealersContainerWrapper = styled(Flex)`
  min-height: 520px;
  width: 100%;
`;

export const StyledButtonContainer = styled(Flex)`
  ${props =>
    Media({
      borderTop: [
        'none',
        null,
        `1px solid ${props.theme.colors.lightGray}`,
        `1px solid ${props.theme.colors.lightGray}`,
      ],
      marginTop: ['32px', null, '30px', '30px'],
      paddingTop: ['32px', null, '56px', '56px'],
    })}
`;

export const StyledDealer = styled(Dealer)``;

export const StyledFailureMessage = styled.div`
  color: ${props => props.theme.colors.red};
  display: flex;
  align-items: center;
  font-size: 12px;

  p {
    margin-left: 7px;
    flex: 1;
  }

  svg path {
    fill: ${props => props.theme.colors.red};
  }
`;

export const StyledSearchResultDescription = styled(Text)`
  color: ${props => props.theme.colors.text};
  line-height: 1.5;
  font-size: 1.25rem;
  display: flex;
  margin-bottom: 40px;
  margin-top: 40px;
  flex-wrap: wrap;

  ${Media({
    flexDirection: ['column', null, 'row'],
  })}
`;

export const StyledCategoryTitle = styled.h4`
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0 0 0 0;
  white-space: nowrap;
`;

export const StyledCategoryTitleWithIcon = styled.div`
  display: flex;
`;

export const StyledCloseIcon = styled.svg`
  width: 32px;
  height: 24px;
  flex: 0 0 32px;
  margin-top: 3px;
  margin-left: 8px;
  margin-right: 10px;
  cursor: pointer;
  fill: ${props => props.theme.dealer.textColor};
`;

export const StyledResultsLength = styled.div`
  margin-right: 10px;
`;
