import extractShortDescription from '../ShortDescription';

const extractBrands = products => {
  return products
    .filter(productEdge => productEdge.node.isBrand)
    .map(productEdge => {
      const productItem = productEdge.node;

      return {
        address: productItem.address?.text || '',
        categories: productItem.categories || [],
        cta:
          productItem && productItem.enquiryCta
            ? {
                label: productItem.enquiryCta.title,
                to: productItem.enquiryCta.internalEntry
                  ? productItem.enquiryCta.internalEntry.slug
                  : productItem.enquiryCta.externalUrl,
              }
            : null,
        filters: productItem.filters || [],
        id: productItem.id,
        logo:
          productItem.logo && productItem.logo.asset && productItem.logo?.asset?.gatsbyImageData
            ? productItem.logo
            : null,
        media:
          productItem.image && productItem.image.asset && productItem.image?.asset?.gatsbyImageData
            ? productItem.image
            : null,
        productsAndServices: productItem.productsAndServices?.text || '',
        richText: productItem.contentsSection || {},
        shortDescription: extractShortDescription(productItem),
        title: productItem.title,
      };
    });
};

const transformBrandListingSection = (item, products) => {
  const brands = extractBrands(products.edges);
  const categories = [...new Set(brands.map(brand => brand.categories).flat())];
  const filters = [...new Set(brands.map(brand => brand.filters).flat())];

  return {
    __typename: 'BrandListing',
    brands,
    categories,
    filters,
    key: item.id,
    shortDescription: extractShortDescription(item),
    title: item.title,
  };
};

export default transformBrandListingSection;
