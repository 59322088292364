import React, { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';

import ArrowDown from '../../../../icons/ArrowDown';
import ArrowUp from '../../../../icons/ArrowUp';
import { smallBreakpoint } from '../../../../utils/breakpoints';
import { useCurrentBreakpoint } from '../../../../utils/hooks/breakpoints';
import useOverlay from '../../../../utils/hooks/overlay';

import {
  StyledButton,
  StyledChevronWrapper,
  StyledClearButton,
  StyledHeading,
  StyledHeadingText,
  StyledHeadingWrapper,
  StyledList,
  StyledListItem,
  StyledListWrapper,
  StyledWrapperInner,
  StyledWrapperOuter,
} from './Styled';

interface Category {
  children: ReactNode;
  isSelected: boolean;
  category: string | null;
  state: any;
  dispatch: (data: any) => void;
  close: () => void;
}

const Category = ({ children, isSelected, category, state, dispatch, close }: Category) => (
  <StyledListItem>
    <StyledButton
      isSelected={isSelected}
      onClick={() => {
        dispatch({ category });
        close();
      }}
      hasHover
    >
      {children} {isSelected && `(${state.brands.length})`}
    </StyledButton>
  </StyledListItem>
);

interface Categories {
  categories: string[];
  state: any;
  noReset: boolean;
  dispatch: (data: any) => void;
}

const Categories = ({ categories, state, noReset, dispatch }: Categories) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOverlay({ setIsOpen });
  const currentBreakpoint = useCurrentBreakpoint();
  const intl = useIntl();
  const noCategory = !state.category;
  const close = () => setIsOpen(false);

  return (
    <StyledWrapperOuter ref={ref}>
      <StyledWrapperInner isOpen={isOpen}>
        <StyledHeadingWrapper onClick={() => setIsOpen(!isOpen)}>
          <StyledHeading>
            {state.category && currentBreakpoint <= smallBreakpoint ? (
              <>
                <StyledHeadingText>{state.category}</StyledHeadingText>({state.brands.length})
              </>
            ) : (
              intl.formatMessage({ id: 'brandListing.byCategories' })
            )}
          </StyledHeading>
          <StyledChevronWrapper>{isOpen ? <ArrowUp /> : <ArrowDown />}</StyledChevronWrapper>
        </StyledHeadingWrapper>
        <StyledListWrapper isOpen={isOpen}>
          <StyledList>
            <Category isSelected={noCategory} category={null} state={state} dispatch={dispatch} close={close}>
              {intl.formatMessage({ id: 'brandListing.all' })}
            </Category>
            {categories.map(category => (
              <Category
                key={category}
                category={category}
                isSelected={category === state.category}
                state={state}
                dispatch={dispatch}
                close={close}
              >
                {category}
              </Category>
            ))}
          </StyledList>
          {!noReset && (
            <StyledClearButton onClick={() => dispatch({ category: null })}>
              {intl.formatMessage({ id: 'brandListing.clear' })}
            </StyledClearButton>
          )}
        </StyledListWrapper>
      </StyledWrapperInner>
    </StyledWrapperOuter>
  );
};

export default Categories;
