import React from 'react';

import Horizontal from './Horizontal';
import { StyledCard } from './Styled';
import EventCardProps from './types';
import Vertical from './Vertical';

const EventCard = React.forwardRef(
  (
    { id, title, shortDescription, image, link, displayDate, variant = 'vertical', location, ...rest }: EventCardProps,
    ref,
  ) => {
    const cardProps = {
      displayDate,
      id,
      image,
      link,
      location,
      shortDescription,
      title,
      variant,
    };

    return (
      <StyledCard ref={ref} {...(rest as any)} className={variant}>
        {variant === 'vertical' ? <Vertical {...cardProps} /> : <Horizontal {...cardProps} />}
      </StyledCard>
    );
  },
);
EventCard.displayName = 'EventCard';

export default EventCard;
