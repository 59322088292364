import React, { useEffect } from 'react';

type Props = {
  type: string;
  code?: string;
};

type WindowWithOneTrust = Window &
  typeof globalThis & {
    OneTrust: {
      initializeCookiePolicyHtml: () => void;
    };
  };

const ONETRUST_COOKIELIST = 'OneTrust CookieList';
const ONETRUST_COOKIEPREF = 'OneTrust CookiePreferences';

const EmbeddedContent = ({ type, code }: Props) => {
  useEffect(() => {
    if (type === ONETRUST_COOKIELIST) {
      if ((window as WindowWithOneTrust).OneTrust) {
        (window as WindowWithOneTrust).OneTrust.initializeCookiePolicyHtml();
      }
    }
  }, [type]);

  if (type === ONETRUST_COOKIELIST) {
    return <div id="ot-sdk-cookie-policy" />;
  }
  if (type === ONETRUST_COOKIEPREF) {
    return (
      <button id="ot-sdk-btn" className="ot-sdk-show-settings" type="button">
        {code || 'Cookie Settings'}
      </button>
    );
  }

  return null;
};

export default EmbeddedContent;
