import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';

//
import Media from '../../utils/Media';

export const StyledWrapper = styled.div`
  max-width: 645px;
  margin: 0 auto;
  position: relative;
`;

export const StyledModuleHeading = styled.h2`
  color: ${props => props.theme.colors.text};
  font-weight: ${props => props.theme.media.titleFontWeight};
  text-transform: ${props => props.theme.media.titleTextTransform};
  word-break: break-word;
  margin-top: 0;
  line-height: 1.14 !important;

  ${Media({
    fontSize: ['2.5rem !important', null, null, '3.5rem !important'],
    marginBottom: ['1rem !important', null, null, '1.5rem !important'],
  })}
`;

export const StyledModuleDescription = styled(Text)`
  color: ${props => props.theme.colors.text};
  line-height: 1.875rem !important;
  margin-bottom: 1.25rem !important;

  ${Media({
    fontSize: ['1.1rem !important', null, null, '1.25rem !important'],
  })}
`;

export const StyledHeadingAndDescriptionWrapper = styled(Box)``;

export const StyledColoredBox = styled(Box)`
  background-color: ${props => props.theme.media.backgroundColor || props.theme.colors.primary};
  position: absolute;

  ${Media({
    height: [168, null, null, 473],
    left: [-24, null, null, -60],
    width: [177, null, null, 367],
  })}
`;

export const StyledMediaWrapper = styled(Box)`
  position: relative;

  ${Media({
    marginBottom: [36, null, null, 44],
    top: [24, null, null, 32],
    width: ['100%', null, null, 872],
  })}
`;

interface StyledImageWrapper {
  halfWidthImage?: boolean;
}

export const StyledImageWrapper = styled(Box)<StyledImageWrapper>`
  ${props =>
    Media({
      maxWidth: ['100%', null, props.halfWidthImage ? '56%' : 'none'],
    })}
`;

export const StyledCaption = styled.p`
  margin: 0;
  color: ${props => props.theme.colors.text};
  font-size: 14px;
  line-hight: 20px;
  font-style: italic;
`;

export const StyledEmbedly = styled.div`
  ${Media({
    maxWidth: ['100%', '100%', '645px', '645px'],
  })}
`;
