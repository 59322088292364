import extractCta from '../Cta';
import extractShortDescription from '../ShortDescription';

const transformShortBanner = (item: any) => {
  return {
    __typename: 'ShortBanner',
    cta: extractCta(item),
    image: item.image,
    key: item.id,
    orientation: item?.orientation?.toLowerCase(),
    shortDescription: extractShortDescription(item),
    title: item.title,
  };
};

export default transformShortBanner;
