import React from 'react';
import { ButtonProps } from 'rebass';

import StyledButton from './StyledButton';

interface Props extends ButtonProps {
  /** The button type, primary or secondary, defaults to primary */
  variant?: 'primary' | 'secondary' | 'primary-with-background';
  /** Standard children prop: accepts any valid React Node */
  children: React.ReactNode;
}

const Button = React.forwardRef(({ children, variant = 'primary', disabled = false, ...rest }: Props, ref) => (
  <StyledButton
    ref={ref}
    variant={disabled ? 'disabled' : variant}
    disabled={disabled}
    {...(rest as any)}
    title={typeof children === 'string' ? children : ''}
  >
    {children}
  </StyledButton>
));
Button.displayName = 'Button';

export default Button;
