/* eslint-disable react/require-default-props */
import React from 'react';
import { BoxProps } from 'rebass/styled-components';

import Button from '../../Button';
import { CardProps } from '../../Cards/CategoryCard';
import Cta from '../../Cta';
import { StyledModuleContainer, StyledModuleCtaContainer } from '../../Layout/Styled';

import {
  StyledBlueBox,
  StyledCardContainer,
  StyledCategoryCard,
  StyledCenteredModuleTitle,
  StyledModuleDescription,
  StyledModuleHeading,
  StyledModuleHeadingNoDescription,
} from './Styled';
import { ImageProps, TertiaryCategories } from './TertiaryCategories';

type CtaProps = {
  to: string;
  label: string;
};

export type CategoriesModuleProps = BoxProps & {
  /** title */
  title: string;
  /** optional card short description */
  shortDescription?: string | null;
  /** categories */
  categories: CardProps[];
  /** card cta */
  cta?: CtaProps | null;
  /** optional variant (primary/secondary/tertiary) */
  variant?: 'primary' | 'secondary' | 'tertiary';
  /** optional image used for tertiary variant */
  image?: ImageProps;
};

const renderTitleWithShortDescription = (
  title: string,
  shortDescription: string | null | undefined,
  variant: string,
) => (
  <>
    {!shortDescription ? (
      <StyledModuleHeadingNoDescription>{title}</StyledModuleHeadingNoDescription>
    ) : (
      <StyledModuleHeading>{title}</StyledModuleHeading>
    )}

    {shortDescription && <StyledModuleDescription variant={variant}>{shortDescription}</StyledModuleDescription>}
  </>
);

export const CategoriesModule = React.forwardRef(
  ({ title, shortDescription, cta, categories, image, variant = 'primary', ...rest }: CategoriesModuleProps, ref) => (
    <StyledModuleContainer ref={ref} {...(rest as any)} className={variant}>
      {variant === 'primary' ? (
        <StyledCenteredModuleTitle>
          {renderTitleWithShortDescription(title, shortDescription, variant)}
        </StyledCenteredModuleTitle>
      ) : (
        <>{renderTitleWithShortDescription(title, shortDescription, variant)}</>
      )}

      {variant === 'tertiary' ? (
        <TertiaryCategories categories={categories} image={image as ImageProps} />
      ) : (
        <StyledCardContainer className={`cards-${categories.length}`}>
          {categories.map((card: CardProps) => (
            <StyledCategoryCard
              id={card.id}
              key={`category-${card.id}`}
              title={card.title}
              shortDescription={card.shortDescription || null}
              link={card.link}
              image={card.image}
              className={`cards-${categories.length}`}
            />
          ))}

          {variant === 'secondary' ? <StyledBlueBox /> : null}
        </StyledCardContainer>
      )}

      {cta && (
        <StyledModuleCtaContainer>
          <Cta to={cta.to}>
            <Button>{cta.label}</Button>
          </Cta>
        </StyledModuleCtaContainer>
      )}
    </StyledModuleContainer>
  ),
);
CategoriesModule.displayName = 'CategoriesModule';

export default CategoriesModule;
