import React from 'react';

const Icon = (rest: any) => (
  <svg
    height="100%"
    preserveAspectRatio="none"
    viewBox="0 0 1440 278.143"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M-69.372-13.4h-1440V-193.245H-472.788a88.632,88.632,0,0,0,64.343-27.677l13.092-13.818a88.632,88.632,0,0,1,64.343-27.677h223.176a39.391,39.391,0,0,0,36.189-23.837l2.273-5.288"
      fill="#536121"
      id="Proxani-shape"
      style={{ strokeWidth: '0px' }}
      transform="matrix(1, 0, 0, 1, 0.000006, -0.000015)"
      width="100%"
    />
  </svg>
);

export default Icon;
