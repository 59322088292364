import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

//
import Media from '../../../utils/Media';
import Cta from '../../Cta';

export const StyledContainer = styled(Box)``;

export const StyledCell = styled(Box)`
  color: ${props => props.theme.colors.text};
  font-size: 1rem;
  flex: 1;
  word-break: break-word;

  ${Media({
    textAlign: ['right', 'center'],
    width: ['100%', 'auto'],
  })}

  &:last-child {
    ${Media({
      minWidth: ['none', '135px'],
    })}
  }
`;

export const StyledHeaderCell = styled(Box)`
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.text};
  letter-spacing: 2px;
  flex: 1;

  ${Media({
    fontSize: ['1rem', '0.75rem', '0.75rem'],
    textAlign: ['left', 'center'],
    width: ['100%', 'auto'],
  })}

  &:last-child {
    ${Media({
      minWidth: ['0px', '135px'],
    })}
  }
`;

export const StyledHeaderRow = styled(Flex)`
  margin-bottom: 1.5rem;
  justify-content: space-between;
`;

export const StyledBodyRow = styled(Flex)`
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  justify-content: space-between;
  ${Media({
    flexDirection: ['column', 'row', 'row'],
    padding: ['0', '1.5rem 0', '1.5rem 0'],
  })}
`;

export const StyledMobileRow = styled(Flex)`
  justify-content: space-between;
  padding: 0.5rem 1.5rem;

  ${Media({
    flexWrap: ['wrap', 'nowrap'],
  })}
`;

export const StyledMobileTop = styled.div`
  padding: 1.25rem 0;
`;

export const StyledMobileBottom = styled.div`
  border-top: 1px solid ${props => props.theme.colors.lightGray};
  padding: 1rem 0;

  span {
    vertical-align: middle;
  }
`;

export const StyledDesktop = styled(Box)`
  ${Media({
    display: ['none', 'block', 'block'],
  })}
`;

export const StyledMobile = styled(Box)`
  ${Media({
    display: ['block', 'none', 'none'],
  })}
`;

export const StyledDownloadLink = styled(Cta)`
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  color: ${props => props.theme.documentDownload.color};
  font-weight: ${props => props.theme.documentDownload.fontWeight || 'normal'};
  text-align: right;

  ${Media({
    display: ['flex', 'inline-block'],
    justifyContent: ['left', null],
    marginTop: ['1rem', '0px'],
    width: ['100%', 'auto'],
  })}

  > span {
    vertical-align: middle;
  }

  > svg {
    background: ${props => props.theme.documentDownload.arrowColor};
    display: inline-block;
    vertical-align: middle;
    margin-left: 1.25rem;
    transform: translateX(-4px);
    fill: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.documentDownload.arrowColor};
    border-radius: 50%;

    > * {
      fill: ${props => props.theme.colors.white};
      transform-origin: center;
      transform: scale(0.8) rotate(90deg) translateY(-1px);
    }
  }

  &:hover {
    color: ${props => props.theme.documentDownload.hoverColor};

    svg {
      background-color: transparent;
      border-color: ${props => props.theme.documentDownload.arrowHoverColor};

      > * {
        fill: ${props => props.theme.documentDownload.arrowHoverColor};
      }
    }
  }
`;

export const StyledCtaText = styled(Box)`
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => props.theme.colors.text};
  letter-spacing: 2px;
  text-align: left;
  width: 100%;
`;
