import React from 'react';
import { useIntl } from 'react-intl';

import { ContactsForm } from '../../Forms';
import RichText from '../../RichText';

import Office from './Office/Office';
import SalesPerson, { SalesPersonType } from './SalesPerson/Salesperson';
import {
  StyledContactsInforamtionSection,
  StyledContainer,
  StyledForm,
  StyledFormContainer,
  StyledInfoContainer,
  StyledModuleDescription,
  StyledModuleHeading,
  StyledOfficeContainer,
  StyledRectangle,
  StyledRectangleTablet,
  StyledSalesTeamList,
  StyledSalesTeamTitle,
} from './Styled';

type OfficeType = {
  id: string;
  title: string;
  address: string;
  mapUrl: string;
  telephone: string;
  openingHours?: string;
};

export type ContactsModuleProps = {
  title: string;
  shortDescription: string;
  offices: Array<OfficeType>;
  contactsSection: any;
  salesTeam: Array<SalesPersonType>;
  contactsTitle: string;
  showContactForm?: boolean;
  contactsShortDescription: string;
  showContactsFormSubscribeOptions: boolean;
  handleFormSubmit: (name: string, formData: { [key: string]: string }) => void;
};

const ContactsModule = ({
  title,
  shortDescription,
  offices,
  contactsSection,
  salesTeam,
  contactsTitle,
  showContactForm = true,
  contactsShortDescription,
  showContactsFormSubscribeOptions = true,
  handleFormSubmit,
}: ContactsModuleProps) => {
  const intl = useIntl();

  return (
    <StyledContainer>
      {showContactForm && <StyledRectangle />}
      <StyledInfoContainer>
        <StyledModuleHeading>{title}</StyledModuleHeading>
        <StyledModuleDescription>{shortDescription}</StyledModuleDescription>
        <StyledOfficeContainer>
          {offices.map(office => (
            <Office key={office.id} {...office} />
          ))}
        </StyledOfficeContainer>
        <StyledContactsInforamtionSection>
          <RichText data={contactsSection.json} />
        </StyledContactsInforamtionSection>
        {salesTeam && !!salesTeam.length && (
          <>
            <StyledSalesTeamTitle>{intl.formatMessage({ id: 'sections.contact.salesteam' })}</StyledSalesTeamTitle>
            <StyledSalesTeamList>
              {salesTeam.map(person => (
                <SalesPerson key={person.id} {...person} />
              ))}
            </StyledSalesTeamList>
          </>
        )}
      </StyledInfoContainer>
      {showContactForm && (
        <StyledFormContainer>
          <StyledRectangleTablet />
          <StyledForm>
            <ContactsForm
              title={contactsTitle}
              shortDescription={contactsShortDescription}
              showSubscribeOptions={showContactsFormSubscribeOptions}
              handleFormSubmit={handleFormSubmit}
            />
          </StyledForm>
        </StyledFormContainer>
      )}
    </StyledContainer>
  );
};

export default ContactsModule;
