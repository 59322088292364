import styled from 'styled-components';

import { HEADER_VARIANTS } from '../Header';
//

interface StyledWrapper {
  variant?: string;
}

export const StyledWrapper = styled.div<StyledWrapper>`
  height: ${props => (props.variant === HEADER_VARIANTS.secondary ? '24px' : '40px')};
  width: ${props => (props.variant === HEADER_VARIANTS.secondary ? '75px' : '93px')};
  position: relative;
  z-index: 1;
`;

export const StyledDropdown = styled.div<any>`
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.search.borderRadius};
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  &.is-open {
    border: 1px solid #e0e0e0;
    box-shadow: 0px 0px 15px 5px rgba(209, 209, 209, 0.5);
    button {
      border: none;
    }
  }
  &.is-closed {
    button {
      border: ${props =>
        props.variant === HEADER_VARIANTS.secondary ? 'none' : `1px solid ${props.theme.colors.darkGray}`};
    }
  }

  &.upwards {
    border: none;
    box-shadow: none;
    button {
      border: none;
    }
  }
`;

interface StyledHeader {
  variant?: string;
}

export const StyledHeader = styled.button<StyledHeader>`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: ${props => props.theme.search.borderRadius};
  font-weight: ${props => props.theme.search.fontWeight || 'bold'};
  background-color: white;
  height: ${props => (props.variant === HEADER_VARIANTS.secondary ? '24px' : '40px')};
`;

interface StyledFlag {
  variant?: string;
}

export const StyledFlag = styled.span<StyledFlag>`
  display: flex;
  width: ${props => (props.variant === HEADER_VARIANTS.secondary ? '14px' : '24px')};
  height: ${props => (props.variant === HEADER_VARIANTS.secondary ? '14px' : '24px')};
  flex: 0 0 ${props => (props.variant === HEADER_VARIANTS.secondary ? '14px' : '24px')};
  svg {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
`;

export const StyledCountryId = styled.span`
  margin-left: 5px;
  text-transform: uppercase;
  font-size: 12px;
`;

interface StyledList {
  variant?: string;
}

export const StyledList = styled.ul<StyledList>`
  margin: 0px;
  padding: 2px 4px;
  margin-top: ${props => (props.variant === HEADER_VARIANTS.secondary ? '0' : '10px')};
  list-style: none;
  height: auto;
`;

interface StyledTopList {
  variant?: string;
}

export const StyledTopList = styled.ul<StyledTopList>`
  margin: 0px;
  padding: 2px 4px;
  margin-top: ${props => (props.variant === HEADER_VARIANTS.secondary ? '0' : '10px')};
  list-style: none;
  height: auto;
  position: absolute;
  bottom: 45px;
  background-color: white;
  border-radius: 10px;
  width: 82px;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 0px 15px 5px rgba(209, 209, 209, 0.5);
  border: 1px solid #e0e0e0;
  border-bottom: none;

  > li:first-child {
    border-top: none;
  }
`;

export const StyledListItem = styled.li`
  cursor: pointer;
  margin: 4px 0px;
  padding: 8px 4px 2px 4px;
  display: flex;
  align-items: center;
  border-top: 1px solid #f0f0f0;
`;

interface StyledChevron {
  variant?: string;
}

export const StyledChevron = styled.span<StyledChevron>`
  display: inline-block;
  margin-left: auto;

  width: ${props => (props.variant === HEADER_VARIANTS.secondary ? '20px' : '24px')};

  svg {
    width: 100%;
    height: 100%;
  }
`;
