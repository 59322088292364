/* eslint-disable react/require-default-props */
import React from 'react';
import { BoxProps } from 'rebass/styled-components';

import { StyledModuleContainer } from '../../Layout/Styled';
import { CardProps } from '../types';

import {
  StyledBlueBox,
  StyledCardContainer,
  StyledCustomBlockCard,
  StyledModuleDescription,
  StyledModuleHeading,
  StyledModuleHeadingContainer,
  StyledModuleHeadingNoDescription,
} from './Styled';
import { Vertical } from './Vertical/Vertical';

export interface CustomBlocksModuleProps extends BoxProps {
  /** title */
  title: string;
  /** optional card short description */
  shortDescription?: string | null;
  /** customBlocks */
  customBlocks: CardProps[];
  twoColumns?: boolean;
  displayCtaCopy: boolean;
}

const renderTitleWithShortDescription = (title: string, shortDescription: string | null | undefined) => (
  <StyledModuleHeadingContainer>
    {!shortDescription ? (
      <StyledModuleHeadingNoDescription>{title}</StyledModuleHeadingNoDescription>
    ) : (
      <StyledModuleHeading>{title}</StyledModuleHeading>
    )}

    {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
  </StyledModuleHeadingContainer>
);

const CustomBlocksModule = React.forwardRef(
  (
    { title, shortDescription, customBlocks, twoColumns, displayCtaCopy = false, ...rest }: CustomBlocksModuleProps,
    ref,
  ) => (
    <StyledModuleContainer ref={ref}>
      {renderTitleWithShortDescription(title, shortDescription)}

      {twoColumns ? (
        <Vertical customBlocks={customBlocks} twoColumns={twoColumns} displayCtaCopy={displayCtaCopy} />
      ) : (
        <StyledCardContainer className={`cards-${customBlocks.length}`}>
          {customBlocks.map((card: CardProps) => (
            <StyledCustomBlockCard
              id={card.id}
              key={`customBlock-${card.id}`}
              title={card.title}
              shortDescription={card.shortDescription || null}
              link={card.link}
              linkLabel={card.linkLabel}
              image={card.image}
              className={`cards-${customBlocks.length}`}
              displayCtaCopy={displayCtaCopy}
              showLink={card.showLink}
            />
          ))}

          <StyledBlueBox />
        </StyledCardContainer>
      )}
    </StyledModuleContainer>
  ),
);
CustomBlocksModule.displayName = 'CustomBlocksModule';

export default CustomBlocksModule;
