import { Box } from 'rebass';
import styled from 'styled-components';

import Media from '../../utils/Media';
import { HEADER_VARIANTS } from '../TopLevelHeader/Header';

export const StyledRootContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: stretch;
  font-family: ${props => props.theme.fonts.body};
`;

export const StyledContainer = styled(Box)`
  flex-grow: 1;
`;

type IStyledMain = {
  headerVariant: string;
};

export const StyledMain = styled.main<IStyledMain>`
  padding-top: 90px;
  padding-bottom: 1rem;
  max-width: ${props => props.theme.sizes.xl};
  flex-shrink: 0;
  margin: 0 auto;
  overflow-x: hidden;
  margin-top: ${props => (props.headerVariant === HEADER_VARIANTS.topLevel ? '80px' : '0')};

  &.active-secondary-menu {
    ${Media({
      paddingTop: ['79px', null, null, '180px'],
    })}
  }

  ${Media({
    paddingTop: ['79px', null, null, '88px'],
  })}
`;

export const StyledHeader = styled(Box)`
  flex-shrink: 0;
`;

export const StyledFooter = styled(Box)`
  flex-shrink: 0;
`;

export const StyledModuleCtaContainer = styled.div`
  ${Media({
    marginTop: ['23px', null, null, '39px'],
  })}
`;

interface StyledModuleContainer {
  smallTopSpacer?: boolean;
  extraSmallTopSpacer?: boolean;
}

export const StyledModuleContainer = styled.div<StyledModuleContainer>`
  max-width: ${props => props.theme.sizes.xl};
  position: relative;

  ${Media({
    marginLeft: ['1rem', null, '32px', '58px'],
    marginRight: ['1rem', null, '32px', '58px'],
    marginTop: ['56px', null, null, '81px'],
  })}

  ${props =>
    props.extraSmallTopSpacer &&
    `
    margin-top: 1.7rem !important;
  `}

  ${props =>
    props.smallTopSpacer &&
    `
    margin-top: 56px !important;
  `}
`;

export const StyledModuleContainerInfoVariant = styled.div`
  max-width: ${props => props.theme.sizes.xl};
  position: relative;

  ${Media({
    marginTop: ['56px', null, null, '81px'],
  })}
`;

export const StyledLegacyContentWrapper = styled.div`
  padding-bottom: 1rem;
  max-width: ${props => props.theme.sizes.xl};
  flex-shrink: 0;
  overflow-x: hidden;
  margin: 0 auto;
`;
