/* eslint-disable react/require-default-props */
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { BoxProps } from 'rebass/styled-components';
// import Img from 'gatsby-image';

import { StyledModuleContainer } from '../../Layout/Styled';
import RichText from '../../RichText';
import Video from '../../Video';

import { StyledContainer, StyledImageWrapper, StyledTextWrapper } from './Styled';

interface ImageAndTextModuleProps extends BoxProps {
  image?: {
    alt?: string;
    title: string;
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  videoUrl?: string;
  videoTitle?: string;
  richText: any;
  alignImageRight?: boolean;
  textAlignment?: 'Left' | 'Center' | 'Right';
}

const ImageAndTextModule = React.forwardRef(
  ({ image, videoUrl, richText, alignImageRight, textAlignment, ...rest }: ImageAndTextModuleProps, ref) => (
    <StyledModuleContainer ref={ref} {...(rest as any)} extraSmallTopSpacer>
      <StyledContainer alignImageRight={alignImageRight}>
        <StyledImageWrapper alignImageRight={alignImageRight}>
          {image?.asset && (
            // <Img
            //   loading="eager"
            //   fluid={image.asset.fluid}
            //   alt={image.alt}
            //   style={{ width: '100%', height: '100%' }}
            // />
            <GatsbyImage
              loading="eager"
              image={image.asset.gatsbyImageData}
              alt={image.alt ? image.alt : image.title}
              style={{ height: '100%', width: '100%' }}
            />
          )}
          {!image && videoUrl && <Video videoUrl={videoUrl} />}
        </StyledImageWrapper>
        <StyledTextWrapper alignImageRight={alignImageRight}>
          <RichText data={richText.json} alignment={textAlignment} />
        </StyledTextWrapper>
      </StyledContainer>
    </StyledModuleContainer>
  ),
);
ImageAndTextModule.displayName = 'ImageAndTextModule';

export default ImageAndTextModule;
