import { WindowLocation } from '@reach/router';

import {
  ContentfulAdviceListingSection,
  ContentfulAdvicesSection,
  ContentfulAttachment,
  ContentfulCategoryListingSection,
  ContentfulContactsSection,
  ContentfulCtaBanner,
  ContentfulCustomBlock,
  ContentfulCustomBlocksSection,
  ContentfulEmbeddedContent,
  ContentfulEventsListingSection,
  ContentfulFindADealerSection,
  ContentfulHeroBanner,
  ContentfulHeroSection,
  ContentfulIconCards,
  ContentfulImageAndText,
  ContentfulImageCard,
  ContentfulInfoSection,
  ContentfulLatestNewsSection,
  ContentfulListingAutomatic,
  ContentfulListingHighlightedContent,
  ContentfulListingMagentoProducts,
  ContentfulListingManual,
  ContentfulMediaBlock,
  ContentfulNewsListingSection,
  ContentfulNewsletterSubscription,
  ContentfulPastEventsSection,
  ContentfulProductCategoriesSection,
  ContentfulProductListingSection,
  ContentfulProductsListingTableSection,
  ContentfulProductsServicesSection,
  ContentfulRichTextSection,
  ContentfulSettings,
  ContentfulShortBanner,
  ContentfulSubcategoryListingSection,
  ContentfulTable,
  ContentfulUpcomingEvents,
} from '../generated/gatsby.types';

type Module =
  | ContentfulAdviceListingSection
  | ContentfulAdvicesSection
  | ContentfulAttachment
  | ContentfulCategoryListingSection
  | ContentfulContactsSection
  | ContentfulCtaBanner
  | ContentfulCustomBlock
  | ContentfulCustomBlocksSection
  | ContentfulEmbeddedContent
  | ContentfulEventsListingSection
  | ContentfulFindADealerSection
  | ContentfulHeroSection
  | ContentfulIconCards
  | ContentfulImageAndText
  | ContentfulInfoSection
  | ContentfulLatestNewsSection
  | ContentfulListingAutomatic
  | ContentfulListingHighlightedContent
  | ContentfulListingMagentoProducts
  | ContentfulListingManual
  | ContentfulMediaBlock
  | ContentfulNewsletterSubscription
  | ContentfulNewsListingSection
  | ContentfulPastEventsSection
  | ContentfulProductCategoriesSection
  | ContentfulProductListingSection
  | ContentfulProductsListingTableSection
  | ContentfulProductsServicesSection
  | ContentfulRichTextSection
  | ContentfulShortBanner
  | ContentfulSubcategoryListingSection
  | ContentfulTable
  | ContentfulImageCard
  | ContentfulHeroBanner
  | ContentfulUpcomingEvents;

type ModuleWithType = Partial<Module> & {
  type: string;
};

export const parseModule = (module: Module): ModuleWithType | null => {
  if (module === null) {
    return null;
  }

  // eslint-disable-next-line no-underscore-dangle
  switch (module.__typename) {
    case 'ContentfulHeroSection':
      return { type: 'hero-section', ...module };
    case 'ContentfulInfoSection':
      return { type: 'info-section', ...module };
    case 'ContentfulUpcomingEvents':
      return { type: 'upcoming-events-section', ...module };
    case 'ContentfulLatestNewsSection':
      return { type: 'latest-news-section', ...module };
    case 'ContentfulProductsServicesSection':
      return { type: 'products-services-section', ...module };
    case 'ContentfulCtaBanner':
      return { type: 'cta-banner', ...module };
    case 'ContentfulProductCategoriesSection':
      return { type: 'product-categories-section', ...module };
    case 'ContentfulRichTextSection':
      return { type: 'text-section', ...module };
    case 'ContentfulContactsSection':
      return { type: 'contacts-section', ...module };
    case 'ContentfulFindADealerSection':
      return { type: 'find-a-dealer-section', ...module };
    case 'ContentfulAdviceListingSection':
      return { type: 'advice-listing-section', ...module };
    case 'ContentfulAdvicesSection':
      return { type: 'advice-section', ...module };
    case 'ContentfulProductsListingTableSection':
      return { type: 'products-listing-table-section', ...module };
    case 'ContentfulNewsListingSection':
      return { type: 'news-listing-section', ...module };
    case 'ContentfulEventsListingSection':
      return { type: 'events-listing-section', ...module };
    case 'ContentfulPastEventsSection':
      return { type: 'past-events-section', ...module };
    case 'ContentfulProductListingSection':
      return { type: 'product-listing-section', ...module };
    case 'ContentfulCategoryListingSection':
      return { type: 'category-listing-section', ...module };
    case 'ContentfulAttachment':
      return { type: 'document', ...module };
    case 'ContentfulImageAndText':
      return { type: 'image-and-text', ...module };
    case 'ContentfulSubcategoryListingSection':
      return { type: 'subcategory-listing-section', ...module };
    case 'ContentfulShortBanner':
      return { type: 'short-banner', ...module };
    case 'ContentfulMediaBlock':
      return { type: 'media', ...module };
    case 'ContentfulTable':
      return { type: 'table', ...module };
    case 'ContentfulCustomBlocksSection':
      return { type: 'custom-blocks-section', ...module };
    case 'ContentfulEmbeddedContent':
      return { type: 'embedded-content', ...module };
    case 'ContentfulNewsletterSubscription':
      return { type: 'news-letter-subscription', ...module };
    case 'ContentfulCustomBlock':
      return { type: 'custom-block', ...module };
    case 'ContentfulListingManual':
      return { type: 'listing-manual', ...module };
    case 'ContentfulHeroBanner':
      return { type: 'hero-banner', ...module };
    case 'ContentfulImageCard':
      return { type: 'image-card', ...module };
    case 'ContentfulCtaGroup':
      return { type: 'cta-group', ...module };
    case 'ContentfulFeaturesSection':
      return { type: 'features-section', ...module };
    case 'ContentfulColumns':
      return { type: 'columns-component', ...module };
    case 'ContentfulVideo':
      return { type: 'video', ...module };
    case 'ContentfulArticleListingSection':
      return { type: 'article-listing-section', ...module };
    case 'ContentfulImage':
      return { type: 'image', ...module };
    default:
  }

  return null;
};

export const parseModules = (
  modules: Module[],
  settings: ContentfulSettings,
  location: WindowLocation<WindowLocation['state']>,
): ModuleWithType[] => {
  const modulesWithType: ModuleWithType[] = [];
  modulesWithType.push(...modules.map(module => parseModule(module)));

  if (location.pathname.includes(settings.searchPage.slug)) {
    modulesWithType.push({ type: 'search-results-section' });
  }

  return modulesWithType;
};
