import * as React from 'react';
import { BoxProps } from 'rebass';

import { IntlContext } from '../../../i18n';
//
import { ArrowDown as ArrowDownIcon, Arrow as ArrowIcon, ArrowUp as ArrowUpIcon } from '../../../icons';
import Cta from '../../Cta';

import { StyledArrow, StyledNavItem, StyledNavItemWithChildren, StyledSubMenu, StyledSubMenuItem } from './Styled';

type NavItemType = {
  to: string;
  label: string;
  children?: Array<NavItemType>;
  open: boolean;
};

const renderSubMenu = (items: Array<NavItemType>, isOpen: boolean, activeMenuUrl: string, onClose: () => void) => {
  if (isOpen) {
    return (
      <StyledSubMenu>
        {items.map((item: NavItemType) => {
          let prefixedTo = item.to;
          const locale: any = React.useContext(IntlContext);
          if (item.to && locale && !item.to.startsWith(`/${locale.path}`)) {
            prefixedTo = `/${locale.path}/${item.to}`.replace('//', '/');
          }
          const active = prefixedTo === activeMenuUrl;

          return (
            <StyledSubMenuItem key={item.to}>
              <Cta
                to={item.to}
                onClick={
                  active
                    ? () => {
                        onClose();
                      }
                    : undefined
                }
              >
                <span>{item.label}</span>
                <StyledArrow>
                  <ArrowIcon />
                </StyledArrow>
              </Cta>
            </StyledSubMenuItem>
          );
        })}
      </StyledSubMenu>
    );
  }

  return null;
};

interface Props extends BoxProps {
  item: any;
  open: boolean;
  activeMenuUrl: string;
  onClose: () => void;
  onClickItem: (clickedItem: NavItemType) => void;
}

const SecondaryNavigationMobileItem = ({ item, open, onClickItem, onClose, activeMenuUrl }: Props) => {
  let prefixedTo = item.to;
  const locale: any = React.useContext(IntlContext);

  if (item.to && locale && !item.to.startsWith(`/${locale.path}`)) {
    prefixedTo = `/${locale.path}/${item.to}`.replace('//', '/');
  }

  const active = prefixedTo === activeMenuUrl;
  const isParent = activeMenuUrl.startsWith(prefixedTo);

  const clickAction = () => {
    if (isParent || active) {
      if (open) {
        onClose();
      } else {
        onClickItem(item);
      }
    }
  };

  if (item.children && item.children.length > 0) {
    return (
      <StyledNavItemWithChildren key={item.to}>
        <span className="mobile-menu-cta">
          <Cta to={item.to} onClick={clickAction}>
            {item.label}
          </Cta>
          {open ? (
            <StyledArrow>
              <span
                onClick={() => {
                  onClickItem(item);
                }}
                onKeyPress={() => {
                  onClickItem(item);
                }}
                role="button"
                tabIndex={0}
              >
                <ArrowUpIcon />
              </span>
            </StyledArrow>
          ) : (
            <StyledArrow>
              <span
                onClick={() => {
                  onClickItem(item);
                }}
                onKeyPress={() => {
                  onClickItem(item);
                }}
                role="button"
                tabIndex={0}
              >
                <ArrowDownIcon />
              </span>
            </StyledArrow>
          )}
        </span>
        {renderSubMenu(item.children, open, activeMenuUrl, onClose)}
      </StyledNavItemWithChildren>
    );
  }

  return (
    <StyledNavItem key={item.to}>
      <Cta to={item.to} onClick={clickAction}>
        {item.label}
      </Cta>
    </StyledNavItem>
  );
};

export default SecondaryNavigationMobileItem;
