import { navigate } from 'gatsby-link';
import * as React from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { locales } from '../../i18n';
import { LocaleType } from '../../i18n/LocaleType';

import {
  StyledLangSwitcherContainer,
  StyledLogo,
  StyledNavItem,
  StyledNavItemLink,
  StyledNavItemLinkMobile,
  StyledNavItemMobile,
  StyledNavigation,
  StyledNavigationList,
  StyledNavigationListMobile,
  StyledNavigationMobile,
  StyledNavigationWrapper,
  StyledSelect,
  StyledToggleButton,
  StyledToggleButtonIcon,
  StyledToggleButtonText,
} from './Styled';

const localeMap: Record<string, string> = {
  de: 'Deutsch',
  en: 'English',
  fr: 'French',
  nl: 'Nederlands',
};

interface Props {
  currentLocale: LocaleType;
  activeLocales: Array<string>;
}

const renderNavItemsDesktop = (
  currentLocale: LocaleType,
  enabledLocales: Array<LocaleType>,
  handleLocaleChange: (locale: string) => void,
  intl: IntlShape,
) => (
  <StyledNavigation>
    <StyledNavigationList>
      <StyledNavItem>
        <StyledNavItemLink href={`https://arvesta.eu/${currentLocale.id}/products-services`}>
          {intl.formatMessage({ id: 'legacy.header.products' })}
        </StyledNavItemLink>
      </StyledNavItem>
      <StyledNavItem>
        <StyledNavItemLink href={`https://arvesta.eu/${currentLocale.id}/about`}>
          {intl.formatMessage({ id: 'legacy.header.about' })}
        </StyledNavItemLink>
      </StyledNavItem>
      <StyledNavItem>
        <StyledNavItemLink href={`https://arvesta.eu/${currentLocale.id}/news`}>
          {intl.formatMessage({ id: 'legacy.header.news' })}
        </StyledNavItemLink>
      </StyledNavItem>
      <StyledNavItem>
        <StyledNavItemLink href={`https://arvesta.eu/${currentLocale.id}/jobs`}>
          {intl.formatMessage({ id: 'legacy.header.jobs' })}
        </StyledNavItemLink>
      </StyledNavItem>
      <StyledNavItem>
        <StyledNavItemLink href={`https://arvesta.eu/${currentLocale.id}/contact`}>
          {intl.formatMessage({ id: 'legacy.header.contact' })}
        </StyledNavItemLink>
      </StyledNavItem>
    </StyledNavigationList>
    <StyledLangSwitcherContainer>
      <StyledSelect value={currentLocale.id} onChange={e => handleLocaleChange(e.target.value)}>
        {enabledLocales.map((item: LocaleType) => (
          <option key={item.id} value={item.path}>
            {localeMap[item.path]}
          </option>
        ))}
      </StyledSelect>
    </StyledLangSwitcherContainer>
  </StyledNavigation>
);

const renderNavItemsMobile = (currentLocale: LocaleType, intl: IntlShape) => (
  <StyledNavigationMobile>
    <StyledNavigationListMobile>
      <StyledNavItemMobile>
        <StyledNavItemLinkMobile href={`https://arvesta.eu/${currentLocale.id}/products-services`}>
          {intl.formatMessage({ id: 'legacy.header.products' })}
        </StyledNavItemLinkMobile>
      </StyledNavItemMobile>
      <StyledNavItemMobile>
        <StyledNavItemLinkMobile href={`https://arvesta.eu/${currentLocale.id}/about`}>
          {intl.formatMessage({ id: 'legacy.header.about' })}
        </StyledNavItemLinkMobile>
      </StyledNavItemMobile>
      <StyledNavItemMobile>
        <StyledNavItemLinkMobile href={`https://arvesta.eu/${currentLocale.id}/news`}>
          {intl.formatMessage({ id: 'legacy.header.news' })}
        </StyledNavItemLinkMobile>
      </StyledNavItemMobile>
      <StyledNavItemMobile>
        <StyledNavItemLinkMobile href={`https://arvesta.eu/${currentLocale.id}/jobs`}>
          {intl.formatMessage({ id: 'legacy.header.jobs' })}
        </StyledNavItemLinkMobile>
      </StyledNavItemMobile>
      <StyledNavItemMobile>
        <StyledNavItemLinkMobile href={`https://arvesta.eu/${currentLocale.id}/contact`}>
          {intl.formatMessage({ id: 'legacy.header.contact' })}
        </StyledNavItemLinkMobile>
      </StyledNavItemMobile>
    </StyledNavigationListMobile>
  </StyledNavigationMobile>
);

const LegacyHeader = ({ currentLocale, activeLocales }: Props) => {
  const intl = useIntl();
  const enabledLocales: Array<LocaleType> = [];
  const [mobileSecondaryNavOpen, toggleSecondaryNav] = React.useState(false);

  Object.keys(locales).forEach((id: string) => {
    const locale: LocaleType = locales[id];
    if (activeLocales.includes(locale.id)) {
      enabledLocales.push(locale);
    }
  });

  const handleLocaleChange = (locale: string) => {
    navigate(`/${locale}`);
  };

  return (
    <StyledNavigationWrapper>
      <div>
        <StyledLogo href="https://arvesta.eu/en">
          <img src="/images/legacy-header-logo.png" width="200" alt="arvesta" />
        </StyledLogo>
      </div>
      <StyledToggleButton type="button" onClick={() => toggleSecondaryNav(!mobileSecondaryNavOpen)}>
        <StyledToggleButtonText>
          MENU
          {mobileSecondaryNavOpen ? (
            <StyledToggleButtonIcon className="fa fa-times ng-star-inserted" />
          ) : (
            <StyledToggleButtonIcon className="fa fa-bars ng-star-inserted" />
          )}
        </StyledToggleButtonText>
      </StyledToggleButton>
      {renderNavItemsDesktop(currentLocale, enabledLocales, handleLocaleChange, intl)}
      {mobileSecondaryNavOpen && renderNavItemsMobile(currentLocale, intl)}
    </StyledNavigationWrapper>
  );
};

export default LegacyHeader;
