import React from 'react';
import { useIntl } from 'react-intl';

import {
  StyledCertificationText,
  StyledCertificationsList,
  StyledCertificationsListItem,
  StyledHeading,
  StyledImage,
  StyledWrapper,
} from './Styled';

export interface ICertification {
  title: string;
  imageUrl: string;
  to?: string;
}

type Props = {
  certifications: Array<ICertification>;
};

export const renderCertificationLink = (
  imageUrl: string,
  title: string,
  link: string | undefined,
  renderText = true,
) => (
  <a href={link}>
    <StyledImage
      sx={{
        backgroundImage: `url(${imageUrl})`,
        textDecoration: 'none',
      }}
    />
    {renderText && <StyledCertificationText>{title}</StyledCertificationText>}
  </a>
);

export const renderCertificationListItem = (imageUrl: string, title: string, renderText = true) => (
  <>
    <StyledImage
      sx={{
        backgroundImage: `url(${imageUrl})`,
      }}
    />
    {renderText && <StyledCertificationText>{title}</StyledCertificationText>}
  </>
);

const ProductCertificationList = ({ certifications }: Props) => {
  const intl = useIntl();

  return (
    <StyledWrapper>
      <StyledHeading>{intl.formatMessage({ id: 'product.certifications.title' })}</StyledHeading>
      <StyledCertificationsList>
        {certifications.map((certification: ICertification) => {
          return (
            <StyledCertificationsListItem key={certification.title}>
              {certification.to
                ? renderCertificationLink(certification.imageUrl, certification.title, certification.to)
                : renderCertificationListItem(certification.imageUrl, certification.title)}
            </StyledCertificationsListItem>
          );
        })}
      </StyledCertificationsList>
    </StyledWrapper>
  );
};

export default ProductCertificationList;
