import * as React from 'react';

import Desktop from './Desktop';
import Mobile from './Mobile';
import { StyledContainer } from './Styled';
import { ProductType } from './types';

type ProductBasicTableProps = {
  rows: Array<ProductType>;
};

const ProductBasicTable = React.forwardRef(({ rows, ...rest }: ProductBasicTableProps, ref) => (
  <StyledContainer ref={ref} {...(rest as any)}>
    <Desktop rows={rows} />
    <Mobile rows={rows} />
  </StyledContainer>
));
ProductBasicTable.displayName = 'ProductBasicTable';

export default ProductBasicTable;
