import Img from 'gatsby-image';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { Box } from 'rebass';

//
import Arrow from '../../../icons/Arrow';
import Button from '../../Button';
import Cta from '../../Cta';
import { fileTypeIconMap } from '../../DocumentDownload';

import {
  StyledCommerceWrapperHorizontal,
  StyledCommerceWrapperHorizontalNoCta,
  StyledContentBox,
  StyledCta,
  StyledCtaWrapper,
  StyledDescription,
  StyledImageBoxLinkHorizontal,
  StyledImageBoxLinkWrapper,
  StyledImageBoxWrapper,
  StyledProductInfo,
  StyledTitle,
} from './Styled';
import ProductCardProps from './types';

const renderEnquiryBox = (price: string | undefined, enquiryCta: any, variant: any) => {
  return (
    <>
      {price && !enquiryCta ? (
        <StyledCommerceWrapperHorizontal className={variant}>
          {price ? <p>{price}</p> : null}
        </StyledCommerceWrapperHorizontal>
      ) : null}

      {price && enquiryCta ? (
        <StyledCommerceWrapperHorizontal className={variant}>
          {price ? <p>{price}</p> : null}

          {enquiryCta ? (
            <Cta to={enquiryCta.link}>
              <Button>{enquiryCta.label}</Button>
            </Cta>
          ) : null}
        </StyledCommerceWrapperHorizontal>
      ) : null}

      {enquiryCta && !price ? (
        <StyledCommerceWrapperHorizontalNoCta className={variant}>
          <Cta to={enquiryCta.link}>
            <Button>{enquiryCta.label}</Button>
          </Cta>
        </StyledCommerceWrapperHorizontalNoCta>
      ) : null}
    </>
  );
};

const Horizontal = ({
  title,
  shortDescription,
  image,
  link,
  showLink,
  price,
  enquiryCta,
  variant,
  showCommerceSection,
  cardCTAText,
  linkToDetailPage,
  linkToCustomPage,
  documents = [],
}: ProductCardProps) => {
  const intl = useIntl();
  const imageBoxProps: any = {};
  if (linkToDetailPage) {
    imageBoxProps.to = link;
  }

  if (linkToCustomPage) {
    imageBoxProps.to = linkToCustomPage.internalEntry
      ? linkToCustomPage.internalEntry.slug
      : linkToCustomPage.externalUrl;
  }

  return (
    <>
      {variant === 'horizontalLeft' ? (
        <StyledImageBoxWrapper>
          <StyledImageBoxLinkWrapper>
            <StyledImageBoxLinkHorizontal {...imageBoxProps}>
              {image && image.asset && (
                // <Img
                //   fluid={image.asset.fluid}
                //   alt={image.alt}
                //   style={{ width: '80%', maxHeight: '80%', position: 'inherit', margin: '0 auto' }}
                //   imgStyle={{ objectFit: 'contain' }}
                // />
                <GatsbyImage
                  image={getImage(image.asset) as IGatsbyImageData}
                  alt={image.alt ? image.alt : image.title}
                  objectFit="contain"
                  style={{ margin: '0 auto', maxHeight: '80%', position: 'inherit', width: '80%' }}
                />
              )}
            </StyledImageBoxLinkHorizontal>
          </StyledImageBoxLinkWrapper>
        </StyledImageBoxWrapper>
      ) : null}

      <StyledContentBox className={price || enquiryCta ? 'incl-commerce' : ''}>
        <StyledProductInfo>
          <Box>
            <StyledTitle>{linkToDetailPage ? <Cta to={link}>{title}</Cta> : title}</StyledTitle>
            <StyledDescription>{shortDescription}</StyledDescription>
          </Box>
          <Box>
            {showLink && linkToDetailPage && !linkToCustomPage && (
              <StyledCtaWrapper>
                <StyledCta to={link}>
                  <Arrow />
                  <span>{cardCTAText || intl.formatMessage({ id: 'cards.product.link' })}</span>
                </StyledCta>
              </StyledCtaWrapper>
            )}

            {linkToCustomPage && (linkToCustomPage.internalEntry || linkToCustomPage.externalUrl) && (
              <StyledCtaWrapper>
                <StyledCta
                  to={
                    linkToCustomPage.internalEntry ? linkToCustomPage.internalEntry.slug : linkToCustomPage.externalUrl
                  }
                >
                  <Arrow />
                  <span>{linkToCustomPage.title}</span>
                </StyledCta>
              </StyledCtaWrapper>
            )}

            {!linkToDetailPage && !linkToCustomPage && !!documents.length && (
              <StyledCtaWrapper horizontal>
                {documents.map(({ documentTitle, documentType, downloadLink }) => (
                  <StyledCta key={documentTitle} horizontal to={downloadLink} target="_blank">
                    {documentType && fileTypeIconMap[documentType]}
                    <span>{cardCTAText || documentTitle}</span>
                  </StyledCta>
                ))}
              </StyledCtaWrapper>
            )}
          </Box>
        </StyledProductInfo>

        {showCommerceSection && !price && !enquiryCta ? (
          <StyledCommerceWrapperHorizontal className={variant} />
        ) : (
          renderEnquiryBox(price, enquiryCta, variant)
        )}
      </StyledContentBox>

      {variant === 'horizontalRight' ? (
        <StyledImageBoxWrapper>
          <StyledImageBoxLinkWrapper>
            <StyledImageBoxLinkHorizontal to={link}>
              {image && image.asset && (
                <Img
                  fluid={image.asset.fluid}
                  alt={image.alt}
                  style={{ margin: '0 auto', maxHeight: '80%', position: 'inherit', width: '80%' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              )}
            </StyledImageBoxLinkHorizontal>
          </StyledImageBoxLinkWrapper>
        </StyledImageBoxWrapper>
      ) : null}
    </>
  );
};

export default Horizontal;
