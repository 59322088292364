import React from 'react';

import Horizontal from './Horizontal';
import { StyledCard } from './Styled';
import NewsCardProps from './types';
import Vertical from './Vertical';

const NewsCard = React.forwardRef(
  ({ id, title, shortDescription, image, link, date, variant = 'vertical', ...rest }: NewsCardProps, ref) => {
    const cardProps = {
      date,
      id,
      image,
      link,
      shortDescription,
      title,
      variant,
    };

    return (
      <StyledCard ref={ref} {...(rest as any)} className={variant}>
        {variant === 'vertical' ? <Vertical {...cardProps} /> : <Horizontal {...cardProps} />}
      </StyledCard>
    );
  },
);
NewsCard.displayName = 'NewsCard';

export default NewsCard;
