import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

//
import Media from '../../../../utils/Media';

export const StyledWrapper = styled(Box)`
  width: 100%;
  margin-bottom: 13px;
`;

interface StyledVerticalCardContainer {
  twoColumns?: boolean;
}

export const StyledVerticalCardContainer = styled(Flex)<StyledVerticalCardContainer>`
  display: flex;
  align-items: top;
  justify-content: left;

  &.cards-2 {
    ${props =>
      Media({
        width: ['auto', null, null, props.twoColumns ? '100%' : 'calc(66.66% + 16px)'],
      })}
  }

  &.cards-1 {
    ${props =>
      Media({
        width: ['auto', null, 'calc(50% - 16px)', props.twoColumns ? 'calc(50% - 32px)' : 'calc(33.33% + 11px)'],
      })}
  }

  ${Media({
    flexWrap: ['wrap', null, null, 'nowrap'],
    margin: ['0 0 1rem 0', null, '0 -16px 32px -16px'],
    position: ['relative', null, 'static'],
  })}

  > div {
    ${Media({
      margin: ['0 0 1rem 0', null, '0 16px 0 16px', '0 16px'],
    })}
  }
`;

interface StyledVerticalCategoryWrapper {
  twoColumns?: boolean;
}

export const StyledVerticalCategoryWrapper = styled(Box)<StyledVerticalCategoryWrapper>`
  ${Media({
    marginBottom: ['1rem', null, '0'],
    width: ['100%', null, 'calc(50% - 32px)', 'calc(33.33% - 16px)'],
  })}

  > div {
    max-width: none;
    margin-right: none;
    height: 100%;

    ${props =>
      props.twoColumns &&
      `
      a:before {
        padding-top: calc((311 / 441) * 63%);
      }
    `}
  }

  &.cards-2 {
    ${Media({
      width: ['100%', 'auto', 'calc(50% - 32px)'],
    })}
  }

  &.cards-1 {
    ${Media({
      width: ['100%'],
    })}
  }

  > .vertical {
    max-width: none;
    height: 100%;
  }
`;
