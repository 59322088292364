import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

//
import Media from '../../../utils/Media';
import CategoryCard from '../../Cards/CategoryCard';

export const StyledModuleHeading = styled.h2`
  color: ${props => props.theme.colors.text};
  font-weight: ${props => props.theme.adviceCardModule.titleFontWeight || 'bold'};
  word-break: break-word;

  ${Media({
    fontSize: ['2rem', null, null, '2.5rem'],
    marginBottom: ['1rem', null, null, '0.5rem'],
    marginRight: ['1rem', null, null, '58px'],
  })}
`;

export const StyledModuleDescription = styled(Text)`
  color: ${props => props.theme.colors.text};
  line-height: 1.5;
  font-size: 1.25rem;

  ${Media({
    marginBottom: ['3.25rem', null, '2rem', '3.25rem'],
    maxWidth: ['none', null, '60%', '50%'],
  })}
`;

export const StyledContainer = styled(Box)`
  position: relative;
  max-width: ${props => props.theme.sizes.xl};

  ${Media({
    marginLeft: ['1rem', null, '2rem', '58px'],
    marginRight: ['1rem', null, '2rem', '58px'],
  })};
`;

export const StyledBlueBox = styled(Box)`
  position: absolute;
  width: calc(591 / 1440 * 100%);
  height: 402px;
  right: 0px;
  top: 0px;
  background-color: ${props => props.theme.adviceCardModule.backgroundColor};
  transform:;
  z-index: -1;

  ${Media({
    marginTop: ['0px', null, '95px', '0px'],
    transform: ['translate(15px, -2.125rem)', null, 'translate(59px, -2rem)'],
    width: [`calc(266 / 375 * 100%)`, null, `calc(591 / 1440 * 100%)`],
  })}
`;

export const StyledCardContainer = styled(Flex)`
  align-items: top;
  justify-content: left;
  width: 100%;

  &.cards-2 {
    ${Media({
      width: ['100%', null, null, '66.66%'],
    })}
  }

  &.cards-1 {
    ${Media({
      width: ['100%', null, null, '33.33%'],
    })}
  }

  ${Media({
    boxShadow: [null, null, null, '0 2px 20px 0 rgba(0, 0, 0, 0.1)'],
    flexWrap: ['wrap', null, null, 'nowrap'],
    marginBottom: ['2.5rem', null, null, '3.25rem'],
    position: ['relative', null, 'static'],
  })}

  > *:not(:last-child) {
    ${Media({
      boxShadow: [null, null, null, 'inset -1px 0px 0px 0px #dedede'],
      marginBottom: ['1rem', null, null, '0'],
    })}
  }
`;

export const StyledCategoryCard = styled(CategoryCard)`
  max-width: none;

  ${Media({
    boxShadow: [null, null, null, 'none'],
    width: ['100%', null, 'calc(50% - 0.5rem)', '33.33%'],
  })}

  &.cards-2 {
    ${Media({
      width: ['100%', null, 'calc(50% - 0.5rem)', '50%'],
    })}
  }

  &.cards-1 {
    ${Media({
      width: ['100%', null, 'calc(50% - 0.5rem)', '100%'],
    })}
  }

  &:nth-child(odd) {
    ${Media({
      marginRight: [null, null, '1rem', '0px'],
    })}
  }
`;
