import React, { useState } from 'react';

import { mediumBreakpoint } from '../../../../utils/breakpoints';
import { useCurrentBreakpoint } from '../../../../utils/hooks/breakpoints';
import BrandCard from '../BrandCard';

import {
  StyledCardsWrapper,
  StyledPaginationButton,
  StyledPaginationList,
  StyledPaginationListItem,
  StyledWrapper,
} from './Styled';

const chunk = (array: any[], size: number) => {
  const chunkedArr = [];
  let index = 0;
  while (index < array.length) {
    chunkedArr.push(array.slice(index, size + index));
    index += size;
  }
  return chunkedArr;
};

interface BrandCards {
  state: any;
  dispatch: (data: any) => void;
}

const BrandCards = ({ state, dispatch }: BrandCards) => {
  const [page, setPage] = useState(0);
  const currentBreakpoint = useCurrentBreakpoint();
  const isPaginated = currentBreakpoint <= mediumBreakpoint;
  const pages = isPaginated && state.brands.length ? chunk(state.brands, 10) : [state.brands];

  return (
    <StyledWrapper>
      <StyledCardsWrapper>
        {pages[page].map((brand: any) => (
          <BrandCard key={brand.id} {...brand} dispatch={dispatch} />
        ))}
      </StyledCardsWrapper>
      {pages.length > 1 && (
        <StyledPaginationList>
          {pages.map((_page, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <StyledPaginationListItem key={index}>
              <StyledPaginationButton isSelected={index === page} onClick={() => setPage(index)}>
                {index + 1}
              </StyledPaginationButton>
            </StyledPaginationListItem>
          ))}
        </StyledPaginationList>
      )}
    </StyledWrapper>
  );
};

export default BrandCards;
