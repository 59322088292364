import styled from 'styled-components';

//
import Media from '../../../utils/Media';

export const StyledWrapper = styled.div`
  ${Media({
    margin: ['48px auto auto 20px', '48px auto auto 20px', '48px auto auto 214px', '57px auto'],
    width: ['auto', 'auto', 'auto', '645px'],
  })}
`;

export const DocumentDownloadContainer = styled.div`
  margin-top: 64px;
  margin-right: 1rem;
  ${Media({
    maxWidth: ['100%', ' 100%', '420px'],
  })}
`;
