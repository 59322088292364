import React from 'react';

import Cta from '../../Cta';

import { StyledFooterMainNavigationList, StyledFooterMainNavigationListItem } from './Styled';

type Link = {
  to: string;
  label: string;
};

type propsType = {
  navigation: Link[];
};

const MainNavigation = ({ navigation }: propsType): JSX.Element => {
  return (
    <StyledFooterMainNavigationList>
      {navigation.map((item: Link, idx: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <StyledFooterMainNavigationListItem key={`footernav-${item.to}-${idx}`}>
          <Cta to={item.to}>{item.label}</Cta>
        </StyledFooterMainNavigationListItem>
      ))}
    </StyledFooterMainNavigationList>
  );
};

export default MainNavigation;
