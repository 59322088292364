import React from 'react';
import { BoxProps } from 'rebass/styled-components';

import Button from '../../Button';
import Cta from '../../Cta';

import {
  StyledContainer,
  StyledHeadingAndDescriptionWrapper,
  StyledHeroCtaWrapper,
  StyledModuleDescription,
  StyledModuleHeading,
  StyledModuleHeadingNoDescription,
} from './Styled';

type CtaProps = {
  label: string;
  to: string;
};

interface HeroSectionModuleProps extends BoxProps {
  title?: string;
  shortDescription?: string;
  cta?: CtaProps;
  wide?: boolean;
}

const HeroSectionModule = React.forwardRef(
  ({ title, shortDescription, cta, wide, ...rest }: HeroSectionModuleProps, ref) => (
    <StyledContainer ref={ref} {...(rest as any)}>
      <StyledHeadingAndDescriptionWrapper wide={wide}>
        {shortDescription ? (
          <>
            <StyledModuleHeading>{title}</StyledModuleHeading>
            <StyledModuleDescription wide={wide}>{shortDescription}</StyledModuleDescription>
          </>
        ) : (
          <StyledModuleHeadingNoDescription>{title}</StyledModuleHeadingNoDescription>
        )}
        {cta && (
          <StyledHeroCtaWrapper>
            <Cta to={cta.to}>
              <Button>{cta.label}</Button>
            </Cta>
          </StyledHeroCtaWrapper>
        )}
      </StyledHeadingAndDescriptionWrapper>
    </StyledContainer>
  ),
);
HeroSectionModule.displayName = 'HeroSectionModule';

export default HeroSectionModule;
