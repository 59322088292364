import styled from 'styled-components';

import Media from '../../../utils/Media';

export const StyledFooterMainNavigationList = styled.ul`
  list-style-type: none;
  padding: 0px;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  margin-top: 8px;
`;

export const StyledFooterMainNavigationListItem = styled.li`
  text-transform: ${props => props.theme.footer.textTransform || 'uppercase'};
  ${Media({
    lineHeight: ['1.71', null, null, '2'],
    marginBottom: ['8px', '8px', '0px', '0px'],
  })}
  a {
    color: ${props => props.theme.colors.darkGray};
    letter-spacing: ${props => props.theme.footer.letterSpacing || '2px'};
    text-decoration: none;
    &:hover {
      color: ${props => props.theme.footer.activeColor};
      border-bottom: 1px solid ${props => props.theme.footer.activeColor};
    }
    font-size: ${props => props.theme.footer.navigationFontSize || '12px'};
  }
`;
