const transformArticles = articles => {
  if (!articles) {
    return [];
  }

  return articles?.filter(Boolean)?.map(article => {
    return {
      contentfulId: article.contentful_id,
      description: article.shortDescription?.text,
      id: article.id,
      image: article.imageCard?.image,
      link: article.slug,
      title: article.title,
    };
  });
};

type Category = {
  children: any[];
  contentful_id: string;
  id: string;
  title: string;
  article_detail_page: any;
  slug: string;
};

const calculateArticleCount = (category: Category) => {
  const uniqueArticleIds: string[] = [];
  let numberOfArticles = 0;

  if (category.children) {
    category.children.forEach(child => {
      if (child.article_detail_page) {
        child.article_detail_page.forEach(article => {
          if (uniqueArticleIds.includes(article.id)) {
            return;
          }
          uniqueArticleIds.push(article.id);
          numberOfArticles++;
        });
      }
    });
  }

  if (category.article_detail_page) {
    category.article_detail_page.forEach(article => {
      if (uniqueArticleIds.includes(article.id)) {
        return;
      }
      uniqueArticleIds.push(article.id);
      numberOfArticles++;
    });
  }

  return numberOfArticles;
};

const transformCategories = (categories: Category[]) => {
  return categories?.map(category => {
    return {
      __typename: 'Category',
      articleCount: calculateArticleCount(category),
      articles: transformArticles([
        ...(category.article_detail_page ?? []),
        ...(category?.children?.map(child => child.article_detail_page).flat() ?? []),
      ]),
      children: category.children?.map(child => {
        return {
          __typename: 'Category',
          articleCount: child.article_detail_page?.length,
          articles: transformArticles(child.article_detail_page),
          contentfulId: child.contentful_id,
          id: child.id,
          label: child.title,
          slug: child.slug,
        };
      }),
      contentfulId: category.contentful_id,
      id: category.id,
      label: category.title,
      slug: category.slug,
    };
  });
};

const getArticlesFromCategories = (categories: Category[]) => {
  const articles: any[] = [];
  const uniqueArticleIds: string[] = [];

  categories?.forEach(category => {
    if (category.children) {
      category.children.forEach(child => {
        if (child.article_detail_page) {
          child.article_detail_page.forEach(article => {
            if (uniqueArticleIds.includes(article.id)) {
              return;
            }
            articles.push({
              contentfulId: article.contentful_id,
              description: article.shortDescription?.text,
              id: article.id,
              image: article.imageCard?.image,
              link: article.slug,
              title: article.title,
            });
            uniqueArticleIds.push(article.id);
          });
        }
      });
    }

    if (category.article_detail_page) {
      category.article_detail_page.forEach(article => {
        if (uniqueArticleIds.includes(article.id)) {
          return;
        }
        articles.push({
          contentfulId: article.contentful_id,
          description: article.shortDescription?.text,
          id: article.id,
          image: article.imageCard?.image,
          link: article.slug,
          title: article.title,
        });
        uniqueArticleIds.push(article.id);
      });
    }
  });

  return articles;
};

type ArticleListingSectionData = {
  categories: Category[];
  contentful_id: string;
  id: string;
  shortDescription: { text: string };
  title: string;
};

const transformArticleListingSection = (data: ArticleListingSectionData) => {
  const articles = getArticlesFromCategories(data.categories);

  return {
    __typename: 'ArticleListingSection',
    articles,
    categories: transformCategories(data.categories),
    contentfulId: data.contentful_id,
    id: data.id,
    shortDescription: data.shortDescription?.text,
    title: data.title,
  };
};

export default transformArticleListingSection;
