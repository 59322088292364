import * as React from 'react';

const SearchSmall = () => (
  <svg viewBox="0 0 12.925 12.917" width="27" xmlns="http://www.w3.org/2000/svg">
    <g id="search" transform="translate(-5.993)">
      <path
        d="M15.785,11.119a5.474,5.474,0,1,0-.965.965l.03.031,2.9,2.9a.684.684,0,1,0,.968-.968l-2.9-2.9-.031-.029ZM14.366,4.863a4.1,4.1,0,1,1-5.8,0,4.1,4.1,0,0,1,5.8,0Z"
        data-name="Path 1388"
        fill="#57602b"
        fillRule="evenodd"
        id="Path_1388"
        transform="translate(0 -2.299)"
      />
    </g>
  </svg>
);

export default SearchSmall;
