import React from 'react';

import { Alert as AlertIcon } from '../../../icons';

import { StyledError, StyledFormRow, StyledLabel, StyledTextArea, StyledTextInput } from './Styled';

type InputProps = {
  field: any;
  label: string;
  placeholder: string;
  handleChange: (e: React.FormEvent<HTMLInputElement>, name: string) => void;
  textarea?: boolean;
};

const InputField = ({ field, label, placeholder, textarea, handleChange }: InputProps) => {
  const inputProps = {
    autoComplete: 'off',
    id: field.name,
    name: field.name,
    onBlur: (e: any) => {
      handleChange(e, field.name);
    },
    onChange: (e: any) => {
      handleChange(e, field.name);
    },
    placeholder,
    value: field.value,
  };

  return (
    <StyledFormRow className={field.error.length && 'error'}>
      <StyledLabel htmlFor={field.name}>{label}</StyledLabel>
      {textarea ? <StyledTextArea {...inputProps} /> : <StyledTextInput {...inputProps} />}
      {field.error && (
        <StyledError>
          <AlertIcon />
          <span>{field.error}</span>
        </StyledError>
      )}
    </StyledFormRow>
  );
};

export default InputField;
