import { WithTypename } from '../../../@types';
import { ContentfulCategory, ContentfulSubcategory } from '../../../__generated__/graphql-types';

const isCategory = (category: ContentfulCategory | ContentfulSubcategory): category is ContentfulCategory => {
  return (category as WithTypename<ContentfulCategory, 'ContentfulCategory'>)['__typename'] === 'ContentfulCategory';
};

const isSubcategory = (category: ContentfulCategory | ContentfulSubcategory): category is ContentfulSubcategory => {
  return (
    (category as WithTypename<ContentfulSubcategory, 'ContentfulSubcategory'>)['__typename'] === 'ContentfulSubcategory'
  );
};

const parseUrl = (category: ContentfulCategory | ContentfulSubcategory) => {
  if (isCategory(category)) {
    if (!category?.article_listing_section?.length) {
      return category?.slug;
    }

    return `${category?.article_listing_section[0].basic_page[0].slug}?category=${category.slug}`;
  }

  if (isSubcategory(category)) {
    if (!category?.category?.length) {
      return category?.slug;
    }

    if (!category?.category[0]?.article_listing_section?.length) {
      return category?.slug;
    }

    return `${category?.category[0].article_listing_section[0].basic_page[0].slug}?category=${category.slug}`;
  }
};

type ReturnCategory = {
  id: string;
  label: string;
  to: string;
};

const parseCategory = (
  category: ContentfulCategory | ContentfulSubcategory,
  uniqueCategoryIds: string[],
): ReturnCategory | unknown[] => {
  if (uniqueCategoryIds.includes(category.contentful_id)) {
    return [];
  }

  uniqueCategoryIds.push(category.contentful_id);

  return {
    id: category.contentful_id,
    label: category.title,
    to: parseUrl(category),
  };
};

const isReturnCategory = (category: ReturnCategory | unknown[]): category is ReturnCategory => {
  return (category as ReturnCategory)['id'] !== undefined;
};

const getCategories = (categories: (ContentfulCategory | ContentfulSubcategory)[]) => {
  const uniqueCategoryIds: string[] = [];
  const allCategories: ReturnCategory[] = [];

  categories?.forEach(category => {
    if (isCategory(category)) {
      const parsedCategory = parseCategory(category, uniqueCategoryIds);
      if (isReturnCategory(parsedCategory)) {
        allCategories.push(parsedCategory);
      }
    }

    if (isSubcategory(category)) {
      if (category?.category?.length) {
        category.category.forEach(parent => {
          const parsedCategory = parseCategory(parent, uniqueCategoryIds);
          if (isReturnCategory(parsedCategory)) {
            allCategories.push(parsedCategory);
          }
        });
      }

      const parsedCategory = parseCategory(category, uniqueCategoryIds);

      if (isReturnCategory(parsedCategory)) {
        allCategories.push(parsedCategory);
      }
    }
  });

  return allCategories;
};

export const getCategoryLinks = (categories: (ContentfulCategory | ContentfulSubcategory)[]) => {
  if (!categories?.length) return [];

  return getCategories(categories);
};
