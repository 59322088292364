import extractCta from '../Cta';
import extractShortDescription from '../ShortDescription';

const extractCategories = (categories: Array<any>) => {
  return categories?.map((categoryItem: any) => {
    const link = extractCta(categoryItem);
    return {
      id: categoryItem.id,
      image: categoryItem.image,
      link: link ? `${link.to}?subcategory=${categoryItem.slug}` : null,
      shortDescription: extractShortDescription(categoryItem),
      title: categoryItem.title,
    };
  });
};

const transformSubcategoryListingSection = (item: any) => {
  return {
    __typename: 'CategoryListingSection',
    categories: item?.subcategories && Array.isArray(item?.subcategories) ? extractCategories(item.subcategories) : [],
    key: item.id,
    shortDescription: extractShortDescription(item),
    title: item.title,
    twoColumns: true,
  };
};

export default transformSubcategoryListingSection;
