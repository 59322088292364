import { getSrc } from 'gatsby-plugin-image';

import { ContentfulContactsSection, ContentfulLocation, ContentfulSales, Maybe } from '../../generated/gatsby.types';
import { handleNetlifyFormSubmission } from '../../ui-lib/utils';
import richTextToJson from '../../utils/contentful-rich-text-raw-to-json';
import extractShortDescription, { ItemType } from '../ShortDescription';

const extractSalesTeam = (salesTeam: Maybe<Array<Maybe<ContentfulSales>>>) => {
  if (!salesTeam) {
    return [];
  }

  return salesTeam
    .map((person: Maybe<ContentfulSales>): any => {
      if (!person) {
        return null;
      }
      return {
        category: person.category,
        email: person.email,
        id: person.id,
        image: getSrc(person.image?.asset?.gatsbyImageData),
        telephone: person.telephone,
        title: person.title,
      };
    })
    .filter(Boolean);
};

const extractOffices = (offices: Maybe<Array<Maybe<ContentfulLocation>>>): any => {
  if (!offices) {
    return [];
  }

  return offices
    .map((office: Maybe<ContentfulLocation>): any => {
      if (!office) {
        return null;
      }
      return {
        address: office.address?.address,
        id: office.id,
        mapUrl: office.mapUrl,
        openingHours: office.openingHours?.openingHours,
        telephone: office.telephone?.telephone,
        title: office.title,
      };
    })
    .filter(Boolean);
};

const transformContactsSection = (
  item: ContentfulContactsSection,
  showContactForm: boolean,
  locale: string,
  basicPageTitle = '',
): any => {
  return {
    __typename: 'ContactsSection',
    basicPageTitle,
    contactsSection: item.contentsSection ? richTextToJson(item.contentsSection, locale) : { json: null },
    contactsShortDescription:
      item.contactsShortDescription && item.contactsShortDescription.contactsShortDescription
        ? item.contactsShortDescription.contactsShortDescription
        : null,
    contactsTitle: item.contactsTitle,
    handleFormSubmit: handleNetlifyFormSubmission,
    key: item.id,
    offices: item.offices ? extractOffices(item.offices) : [],
    salesTeam: item.salesTeam ? extractSalesTeam(item.salesTeam) : [],
    shortDescription: extractShortDescription(item as ItemType),
    showContactsFormSubscribeOptions: showContactForm,
    title: item.title ? item.title : '',
  };
};

export default transformContactsSection;
