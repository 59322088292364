import React from 'react';
import Helmet from 'react-helmet';
import { withTheme } from 'styled-components';

import {
  ContentfulAdvice,
  ContentfulBasicPage,
  ContentfulEvent,
  ContentfulNews,
  ContentfulProduct,
} from '../../../generated/gatsby.types';
import { IntlContext } from '../../i18n';
import Footer from '../Footer';
import Header from '../Header';
import TopLevelHeader from '../TopLevelHeader';
import { HEADER_VARIANTS } from '../TopLevelHeader/Header';

import Metatags from './Metatags';
import { EnabledLocalesContext } from './PageWrapper';
import { StyledContainer, StyledFooter, StyledHeader, StyledMain, StyledRootContainer } from './Styled';
import { TranslatedSlugProvider } from './TranslatedSlugContext';

type SubscribeFormProps = {
  showFooterSubscribeForm: boolean;
  showFooterSubscribeFormCountryField: boolean;
  subscribeFormHeading?: any | null;
};

type PageProps = {
  /** Sitedata including navigation elements */
  siteData: any;
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode;
  /** controlls if swifttype can index this pages contents */
  headerSearchFunction: (serchTerm: string) => void;
  subscribeForm: SubscribeFormProps;
  searchPreviewImage: any;
  theme: any;
  headerVariant: string;
  pageLocale: string;
  onSubscribeFormSubmit: (email: string, country: string, locale: string) => void;
  translatedSlugs: Array<ContentfulBasicPage | ContentfulEvent | ContentfulNews | ContentfulAdvice | ContentfulProduct>;
  isHomepage: boolean;
};

const FONTMAP: Record<string, string[]> = {
  alliance: [
    'https://fonts.googleapis.com/css2?family=Encode+Sans:wght@300;400;600;700&display=swap',
    'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap',
  ],
  arieblok: ['/fonts/ropasans.css'],
  arvesta: ['/fonts/encodesans.css'],
  aveveagrarisch: ['https://use.typekit.net/hji8mfd.css'],
  avevebiochem: ['https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap'],
  aveveevents: ['/fonts/encodesans.css'],
  belcrop: [],
  cofabel: ['/fonts/johndeeresans.css'],
  dumoulin: ['https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600;700&display=swap'],
  equifirst: ['/fonts/myriadpro.css'],
  globalfeedmill: ['https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap'],
  hafner: ['/fonts/futura.css'],
  hermoo: ['https://fonts.googleapis.com/css2?family=Lato:wght@300;400;600;700&display=swap'],
  hobbyfirst: ['/fonts/marine.css'],
  hortiplan: [
    'https://fonts.googleapis.com/css2?family=Encode+Sans&family=Nunito+Sans:wght@300;400;600;700&display=swap',
  ],
  kasperfaunafood: ['https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600;700&display=swap'],
  lannoo: ['https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap'],
  naturalgranen: ['/fonts/stagsans.css'],
  osmo: ['https://fonts.googleapis.com/css2?family=Hind:wght@300;400;600;700&display=swap'],
  palital: [],
  peka: ['/fonts/helvetica.css'],
  pentatrading: ['https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap'],
  pomagro: ['https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600;700&display=swap'],
  sabe: ['https://fonts.googleapis.com/css2?family=Krub:wght@300;400;600;700&display=swap'],
  sanac: ['/fonts/encodesans.css'],
  scoriethom: ['/fonts/encodesans.css'],
  servagri: ['/fonts/encodesans.css'],
  spoormans: ['https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600;700&display=swap'],
  walagri: ['/fonts/encodesans.css'],
  wimlybaert: [
    'https://fonts.googleapis.com/css2?family=Source+Serif+4:wght@400;700&display=swap',
    'https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap',
  ],
};

const MainLayout = ({
  siteData,
  children,
  headerSearchFunction,
  subscribeForm,
  searchPreviewImage,
  headerVariant,
  pageLocale,
  translatedSlugs,
  onSubscribeFormSubmit,
  isHomepage,
  ...rest
}: PageProps) => {
  const locale: any = React.useContext(IntlContext);
  const locales: any = React.useContext(EnabledLocalesContext);
  const activeLocales = Object.keys(locales);

  const { header, footer } = siteData;

  return (
    <StyledRootContainer>
      <Helmet>
        {FONTMAP[rest.theme.name.toLowerCase()].map((font: string) => (
          <link key={font} rel="stylesheet" href={font} />
        ))}
        <script src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&libraries=geocoding`} />
      </Helmet>
      <Metatags
        siteName={siteData.metaData.siteName ? siteData.metaData.siteName : ''}
        title={siteData.metaData.title ? siteData.metaData.title : ''}
        description={siteData.metaData.description ? siteData.metaData.description : ''}
        canonical={siteData.metaData.canonical ? siteData.metaData.canonical : null}
        nofollow={siteData.metaData.nofollow ? siteData.metaData.nofollow : null}
        noindex={siteData.metaData.noindex ? siteData.metaData.noindex : null}
        swiftTypeDate={siteData.metaData.swiftTypeDate ? siteData.metaData.swiftTypeDate : null}
        swiftTypeLocation={siteData.metaData.swiftTypeLocation ? siteData.metaData.swiftTypeLocation : null}
        image={siteData.metaData.image ? siteData.metaData.image : null}
        pageLocale={siteData.metaData.pageLocale ? siteData.metaData.pageLocale : locale.id}
        pageType={siteData.metaData.pageType ? siteData.metaData.pageType : ''}
        updatedAt={siteData.metaData.updatedAt ? siteData.metaData.updatedAt : ''}
        searchPreviewImage={searchPreviewImage}
        locale={siteData.metaData.pageLocale}
        ogPageType={siteData.metaData.ogPageType}
        searchTags={siteData.metaData.searchTags}
      />
      <StyledContainer>
        <StyledHeader data-appsearch-ignore="true">
          <TranslatedSlugProvider value={translatedSlugs}>
            {headerVariant === HEADER_VARIANTS.topLevel ? (
              <TopLevelHeader
                pageId={siteData.pageId}
                siteName={siteData.metaData.siteName}
                defaultLocale={locale}
                activeLocales={activeLocales}
                navigation={header.navigation}
                searchFunction={headerSearchFunction}
                variant={headerVariant}
                isHomepage={isHomepage}
              />
            ) : (
              <Header
                siteName={siteData.metaData.siteName}
                defaultLocale={locale}
                activeLocales={activeLocales}
                navigation={header.navigation}
                searchFunction={headerSearchFunction}
                variant={headerVariant}
                isHomepage={isHomepage}
              />
            )}
          </TranslatedSlugProvider>
        </StyledHeader>

        <StyledMain headerVariant={headerVariant} id="main-wrapper">
          {children}
        </StyledMain>
      </StyledContainer>
      <StyledFooter>
        <Footer
          footerNavigation={footer.navigation}
          legalLinks={footer.legalLinks}
          socialLinks={footer.socialLinks}
          contacts={footer.contacts}
          onSubmitSubscribe={onSubscribeFormSubmit}
          pageLocale={pageLocale}
          subscribeForm={subscribeForm}
          banner={footer.banner}
        />
      </StyledFooter>
    </StyledRootContainer>
  );
};

export default withTheme(MainLayout);
