/* eslint-disable react/require-default-props */
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { Box, BoxProps } from 'rebass/styled-components';

import Button from '../Button';
import Cta from '../Cta';

import { StyledImage, StyledTextBox, StyledTextBoxDescription, StyledTextBoxTitle, StyledWrapper } from './Styled';

type CtaProps = {
  to: string;
  label: string;
};

interface ShortBannerProps extends BoxProps {
  title?: string;
  shortDescription?: string;
  cta?: CtaProps | null;
  image?: {
    title: string;
    alt?: string;
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  orientation?: 'left' | 'right';
}

const ShortBanner = ({ title, shortDescription, cta, orientation = 'right', image }: ShortBannerProps) => (
  <StyledWrapper>
    <StyledImage>
      {image?.asset && (
        <GatsbyImage
          loading="eager"
          image={image.asset.gatsbyImageData}
          alt={image.alt ? image.alt : image.title}
          style={{ height: '100%', width: '100%' }}
        />
      )}
    </StyledImage>
    <StyledTextBox className={orientation}>
      {title && <StyledTextBoxTitle>{title}</StyledTextBoxTitle>}
      {shortDescription && <StyledTextBoxDescription>{shortDescription}</StyledTextBoxDescription>}
      {cta && (
        <Box>
          <Cta to={cta.to}>
            <Button variant="primary-with-background">{cta.label}</Button>
          </Cta>
        </Box>
      )}
    </StyledTextBox>
  </StyledWrapper>
);

export default ShortBanner;
