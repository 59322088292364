import { ContentfulCtaBanner } from '../../generated/gatsby.types';
import { documentToReactComponents } from '../../ui-lib/components/RichText';
import extractCta from '../Cta';

const transformCtaSection = (item: ContentfulCtaBanner) => {
  return {
    __typename: 'CtaSection',
    cta: extractCta(item),
    image: item.image,
    key: item.id,
    orientation: item?.orientation?.toLowerCase(),
    sectionCategory: null as string | null,
    shortDescription: item?.description?.raw ? documentToReactComponents(JSON.parse(item.description.raw)) : '',
    title: item.title,
  };
};

export default transformCtaSection;
