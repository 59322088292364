import { ContentfulEmbeddedContent } from '../../generated/gatsby.types';

const transformEmbeddedContent = (item: ContentfulEmbeddedContent) => {
  return {
    __typename: 'EmbeddedContent',
    ID: item.ID,
    description: item.description,
    embedType: item.embedType,
    key: item.id,
    title: item.title,
  };
};
export default transformEmbeddedContent;
