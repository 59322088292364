import { darken } from 'polished';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

//
import Media from '../../../../utils/Media';

export const StyledDealerContent = styled(Box)`
  color: ${props => `${props.theme.colors.text}`};
  flex-direction: column;
  line-height: 1.75;
  display: flex;
  height: 100%;

  ${Media({})}

  a {
    color: ${props => props.theme.contacts.office.mapTextColor};
  }

  .dealer-location-link {
    color: ${props => `${props.theme.dealer.textColor}`};
    text-transform: ${props => props.theme.contacts.office.mapTextTransform};
    font-size: ${props => props.theme.contacts.office.mapTextFontSize};
    letter-spacing: ${props => props.theme.contacts.office.mapTextLetterSpacing};
    line-height: ${props => props.theme.contacts.office.mapTextLineHeight};
    color: ${props => props.theme.contacts.office.mapTextColor};
    font-weight: ${props => props.theme.contacts.office.mapTextFontWeight};
    text-decoration: none;
    line-height: 1.7;

    ${Media({
      margin: ['1rem 0', null, '1.5rem 0'],
      order: ['1', null, '0'],
    })}

    > span {
      vertical-align: middle;
    }

    svg {
      transform: scale(0.9);
      fill: ${props => `${props.theme.dealer.textColor}`};
      vertical-align: middle;
      margin-right: 0.5rem;

      > * {
        fill: ${props => `${props.theme.dealer.textColor}`};
      }
    }

    &:hover {
      color: ${props => darken(0.1, props.theme.dealer.textColor)};

      > svg {
        fill: ${props => darken(0.1, props.theme.dealer.textColor)};

        > * {
          fill: ${props => darken(0.1, props.theme.dealer.textColor)};
        }
      }
    }
  }

  .dealer-telephone {
    color: ${props => `${props.theme.colors.text}`};
    text-decoration: none;
  }
`;

export const StyledDealerTitle = styled.h4`
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0 0 0.3rem 0;
`;

export const StyledDealerTelephone = styled.div`
  ${Media({
    minHeight: ['none', '28px', null],
  })}
`;
