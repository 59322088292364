import * as React from 'react';
import { useIntl } from 'react-intl';

//
import { Cancel as CloseIcon, Search as SearchIcon } from '../../../icons';

import { StyledClosedButton, StyledInput, StyledSearchIconButton, StyledWrapper } from './Styled';

type Props = {
  searchFunction: (searchTerm: string) => void;
  variant?: string;
};

export const Search = ({ searchFunction, variant }: Props) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [focused, setFocusedState] = React.useState(false);
  const inputRef = React.createRef<HTMLInputElement>();
  const intl = useIntl();

  React.useEffect(() => {
    if (!focused) {
      setSearchTerm('');
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  }, [focused, setSearchTerm, inputRef]);

  /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
  return (
    <StyledWrapper className={`${focused ? 'focused-input' : ''}`} variant={variant}>
      <StyledSearchIconButton
        role="button"
        tabIndex={0}
        onClick={() => {
          if (focused) {
            searchFunction(searchTerm);
          }
        }}
        onKeyDown={() => {
          if (focused) {
            searchFunction(searchTerm);
          }
        }}
        variant={variant}
      >
        <SearchIcon />
      </StyledSearchIconButton>
      <StyledInput
        name="search"
        role="search"
        ref={inputRef}
        placeholder={intl.formatMessage({ id: 'header.search.placeholder' })}
        autoComplete="off"
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          setSearchTerm(e.currentTarget.value);
          setFocusedState(true);
        }}
        onBlur={() => setFocusedState(false)}
        onKeyPress={(e: KeyboardEvent) => {
          if (e.key === 'Enter') {
            searchFunction(searchTerm);
          }
        }}
        onKeyDown={(e: KeyboardEvent) => {
          if (e.keyCode === 27) {
            setFocusedState(false);
          }
        }}
        onFocus={() => setFocusedState(true)}
      />
      {focused ? (
        <StyledClosedButton
          role="button"
          tabIndex={0}
          onKeyDown={() => {
            searchFunction(searchTerm);
          }}
          onClick={() => setFocusedState(false)}
          variant={variant}
        >
          <CloseIcon />
        </StyledClosedButton>
      ) : null}
    </StyledWrapper>
  );
};

export default Search;
