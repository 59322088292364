import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import richTextToJson from '../../../../../utils/contentful-rich-text-raw-to-json';
import { IntlContext } from '../../../../i18n';
import Close from '../../../../icons/Close';
import Cta from '../../../Cta';
import RichText from '../../../RichText';
import Video from '../../../Video';

import {
  StyledAddress,
  StyledAddressWrapper,
  StyledBrandButton,
  StyledCTAButton,
  StyledCloseButton,
  StyledCloseButtonWrapper,
  StyledContentWrapper,
  StyledMediaWrapper,
  StyledModal,
  StyledModalBottom,
  StyledModalTop,
  StyledOverlay,
  StyledProductsAndServices,
  StyledProductsAndServicesWrapper,
  StyledRichText,
  StyledSimilarBrandsList,
  StyledSimilarBrandsWrapper,
  StyledSubTitle,
  StyledTitle,
} from './Styled';

interface Modal {
  state: any;
  dispatch: (data: any) => void;
}

const Modal = ({ state, dispatch }: Modal) => {
  const intl = useIntl();
  const locale: any = useContext(IntlContext);
  const brand = state.allBrands.find(({ id }: any) => id === state.selectedBrandId);

  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  };
  const shortDescription = richTextToJson(brand.richText, locale);

  return (
    <>
      <StyledOverlay onClick={() => dispatch({ selectedBrandId: null })} />
      <StyledModal>
        <StyledModalTop>
          <StyledMediaWrapper>
            {brand.media && brand.media.asset && (
              <GatsbyImage
                image={getImage(brand.media.asset) as IGatsbyImageData}
                alt={brand.media.alt ? brand.media.alt : brand.media.title}
                loading="eager"
              />
            )}
            {brand.media && brand.media.videoUrl && <Video videoUrl={brand.media.videoUrl} />}
          </StyledMediaWrapper>
          <StyledContentWrapper>
            <StyledTitle>{brand.title}</StyledTitle>
            <StyledRichText>
              <RichText data={shortDescription.json} />
            </StyledRichText>
          </StyledContentWrapper>
          <StyledCloseButtonWrapper>
            <StyledCloseButton onClick={() => dispatch({ selectedBrandId: null })}>
              <Close />
            </StyledCloseButton>
          </StyledCloseButtonWrapper>
        </StyledModalTop>
        <StyledModalBottom>
          <StyledAddressWrapper>
            {brand.logo && brand.logo.asset && (
              <GatsbyImage
                image={getImage(brand.logo.asset) as IGatsbyImageData}
                alt={brand.logo.alt ? brand.logo.alt : brand.logo.title}
                style={{ marginRight: '20px', maxWidth: '150px' }}
              />
            )}
            <StyledAddress>{brand.address}</StyledAddress>
            {brand.cta && (
              <Cta to={brand.cta.to}>
                <StyledCTAButton>{brand.cta.label}</StyledCTAButton>
              </Cta>
            )}
          </StyledAddressWrapper>
          <StyledProductsAndServicesWrapper>
            <StyledSubTitle>{intl.formatMessage({ id: 'brandListing.productsAndServices' })}</StyledSubTitle>
            <StyledProductsAndServices>{brand.productsAndServices}</StyledProductsAndServices>
          </StyledProductsAndServicesWrapper>
          <StyledSimilarBrandsWrapper>
            <StyledSubTitle>{intl.formatMessage({ id: 'brandListing.similarBrands' })}</StyledSubTitle>
            <StyledSimilarBrandsList>
              {brand.categories.map((category: string) => (
                <StyledBrandButton
                  key={category}
                  variant="secondary"
                  onClick={() => {
                    dispatch({
                      category,
                      selectedBrandId: null,
                    });
                    scrollToTop();
                  }}
                >
                  {category}
                </StyledBrandButton>
              ))}
            </StyledSimilarBrandsList>
          </StyledSimilarBrandsWrapper>
        </StyledModalBottom>
      </StyledModal>
    </>
  );
};

export default Modal;
