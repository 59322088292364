import React from 'react';
import { BoxProps } from 'rebass/styled-components';

import Button from '../../Button';
import EventCard from '../../Cards/EventCard';
import Cta from '../../Cta';
import { StyledModuleCtaContainer } from '../../Layout/Styled';

import {
  StyledBlueBox,
  StyledHorizontalEventCardWrapper,
  StyledHorizontalEventsCardWrapper,
  StyledModuleContainer,
  StyledModuleDescription,
  StyledTitle,
} from './Styled';

type CtaProps = {
  to: string;
  label: string;
};

type Event = {
  id: string;
  title: string;
  shortDescription: string;
  date: string;
  location: string;
  image: string;
  link: string;
};

export interface UpcomingEventsModuleProps extends BoxProps {
  /** title */
  title: string;
  /** optional module short description */
  shortDescription: string;
  /** events  */
  upcomingEvents: Array<Event>;

  cta?: CtaProps;
}

const UpcomingEventsModule = React.forwardRef(
  ({ title, shortDescription, upcomingEvents, cta, ...rest }: UpcomingEventsModuleProps, ref) => (
    <>
      {upcomingEvents && upcomingEvents.length > 0 ? (
        <StyledModuleContainer ref={ref} {...(rest as any)} data-appsearch-ignore="true">
          <StyledBlueBox>
            <StyledTitle>{title}</StyledTitle>
            {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
          </StyledBlueBox>
          <StyledHorizontalEventsCardWrapper>
            {upcomingEvents
              .map((event: any) => (
                <StyledHorizontalEventCardWrapper key={event.id}>
                  <EventCard
                    id={event.id}
                    title={event.title}
                    image={event.image}
                    displayDate={event.date}
                    location={event.location}
                    shortDescription={event.shortDescription}
                    link={event.link}
                    variant="horizontal"
                  />
                </StyledHorizontalEventCardWrapper>
              ))
              .slice(0, 4)}
          </StyledHorizontalEventsCardWrapper>

          {cta && (
            <StyledModuleCtaContainer>
              <Cta to={cta.to}>
                <Button>{cta.label}</Button>
              </Cta>
            </StyledModuleCtaContainer>
          )}
        </StyledModuleContainer>
      ) : null}
    </>
  ),
);
UpcomingEventsModule.displayName = 'UpcomingEventsModule';

export default UpcomingEventsModule;
