import extractCta from '../Cta';
import extractShortDescription, { ItemType } from '../ShortDescription';

const transformInfoSection = (item: ItemType) => {
  return {
    __typename: 'InfoSection',
    cta: extractCta(item),
    image: item.image,
    key: item.id,
    sectionCategory: null,
    shortDescription: extractShortDescription(item),
    title: item.title,
  };
};

export default transformInfoSection;
