import * as React from 'react';

import { ArrowDown as ArrowDownIcon, ArrowUp as ArrowUpIcon } from '../../../icons';
import Cta from '../../Cta';
import { NavItemType } from '../types';

import { StyledArrow, StyledNavItem, StyledNavigation } from './Styled';

interface Props {
  items: Array<NavItemType>;
  currentItem: NavItemType | null;
  activeMenuItem: NavItemType | null;
  handleClickMenuItem: (item: NavItemType) => void;
  variant?: string;
}

const PrimaryNavigation = ({ items, activeMenuItem, currentItem, handleClickMenuItem, variant }: Props) => {
  const [clicked, setClicked] = React.useState(false);

  React.useEffect(() => {
    setClicked(false);
  }, [currentItem]);

  return (
    <StyledNavigation variant={variant}>
      {items.map((item: NavItemType, idx: number) => {
        return (
          <StyledNavItem
            // eslint-disable-next-line react/no-array-index-key
            key={`primary-active-${item.to}-${idx}`}
            onFocus={() => handleClickMenuItem(item)}
            variant={variant}
            isOpen={currentItem?.to === item.to}
            isActive={activeMenuItem?.to === item.to}
          >
            <Cta
              to={item.to}
              onClick={e => {
                if (!clicked && currentItem && currentItem.to === item.to) {
                  e.preventDefault();
                  setClicked(true);
                }
                handleClickMenuItem(item);
              }}
            >
              {item.label}
            </Cta>
            {item.children?.length > 0 && (
              <StyledArrow>
                <span
                  onClick={e => {
                    if (!clicked && currentItem && currentItem.to === item.to) {
                      e.preventDefault();
                      setClicked(true);
                    }
                    handleClickMenuItem(item);
                  }}
                  role="button"
                  tabIndex={0}
                >
                  {currentItem && currentItem.to === item.to ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </span>
              </StyledArrow>
            )}
          </StyledNavItem>
        );
      })}
    </StyledNavigation>
  );
};

export default PrimaryNavigation;
