import React from 'react';

import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Linkedin as LinkedinIcon,
  Twitter as TwitterIcon,
  Youtube as YoutubeIcon,
} from '../../../icons';

import { StyledFooterSocialList, StyledFooterSocialListItem } from './Styled';

const socialIconMap: Record<string, React.ReactElement> = {
  facebook: <FacebookIcon />,
  instagram: <InstagramIcon />,
  linkedin: <LinkedinIcon />,
  twitter: <TwitterIcon />,
  youtube: <YoutubeIcon />,
};

type Link = {
  to: string;
  label: string;
};

type propsType = {
  socialLinks: Link[];
};

const SocialIcons = ({ socialLinks }: propsType): JSX.Element => {
  return (
    <StyledFooterSocialList>
      {socialLinks?.map((item: Link, idx: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <StyledFooterSocialListItem key={`social-${item.to}-${idx}`}>
          <a target="_blank" rel="noopener noreferrer" href={item.to}>
            {socialIconMap[item.label]}
          </a>
        </StyledFooterSocialListItem>
      ))}
    </StyledFooterSocialList>
  );
};

export default SocialIcons;
