import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

//
import Media from '../../../../utils/Media';

export const StyledWrapper = styled(Box)`
  width: 100%;
  margin-bottom: 13px;
`;

export const StyledVerticalCardContainer = styled(Flex)`
  display: flex;
  align-items: top;
  justify-content: left;
  margin: 0 -16px;

  &.cards-2 {
    ${Media({
      width: ['auto', null, null, 'calc(66.66% + 24px)'],
    })}
  }

  &.cards-1 {
    ${Media({
      width: ['auto', null, '50%', 'calc(33.33% - 21px)'],
    })}
  }

  ${Media({
    flexWrap: ['wrap', null, null, 'nowrap'],
    marginBottom: ['0', null, '32px'],
    position: ['relative', null, 'static'],
  })}

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledVerticalProductWrapper = styled(Box)`
  ${Media({
    margin: ['0 16px 1rem 16px', null, '0 16px 0 16px'],
    width: ['auto', null, 'calc(50% - 32px)', 'calc(33.33% - 16px)'],
  })}

  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.1);

  &.cards-3 {
    width: 100%;
  }

  &.cards-2 {
    ${Media({
      width: ['100%', null, 'calc(50% - 32px)'],
    })}
  }

  &.cards-1 {
    ${Media({
      width: ['100%', null, 'calc(100% - 16px)'],
    })}
  }

  > .vertical {
    max-width: none;
    height: 100%;
  }
`;
