import { getSrc } from 'gatsby-plugin-image';
import * as React from 'react';
import { useIntl } from 'react-intl';

import Cta from '../../Cta';

import StyledFooterContainer, {
  StyledCopywriteMessage,
  StyledImageContainer,
  StyledInfoContainer,
  StyledLinkItem,
  StyledLinkList,
} from './Styled';

export interface Props {
  items: Array<{
    to: string;
    label: string;
  }>;
  banner: any;
}

const LegalFooter = ({ items, banner }: Props) => {
  const intl = useIntl();

  const extractImage = (image: any) => {
    if (image?.gatsbyImageData) {
      return getSrc(image?.gatsbyImageData);
    }
  };

  const imageSrc = extractImage(banner);

  return (
    <>
      <StyledFooterContainer
        sx={{
          backgroundImage: `url(${imageSrc})`,
        }}
      >
        <StyledInfoContainer>
          <StyledCopywriteMessage>
            {intl.formatMessage({ id: 'footer.copyright' }, { year: new Date().getFullYear() })}
          </StyledCopywriteMessage>
          <StyledLinkList>
            {items.map((item, key) => (
              <StyledLinkItem key={`${item.to}-${key}`}>
                <Cta to={item.to}>{item.label}</Cta>
              </StyledLinkItem>
            ))}
          </StyledLinkList>
        </StyledInfoContainer>
      </StyledFooterContainer>
      {banner && (
        <StyledImageContainer>
          <img src={imageSrc} alt="Arvesta" />
        </StyledImageContainer>
      )}
    </>
  );
};

export default LegalFooter;
