import React from 'react';
import { useIntl } from 'react-intl';
import { Box } from 'rebass/styled-components';

import { mediumBreakpoint } from '../../../utils/breakpoints';
import { chunkArray } from '../../../utils/common';
import { useCurrentBreakpoint } from '../../../utils/hooks/breakpoints';
import Button from '../../Button';
import EventCard from '../../Cards/EventCard';
import Cta from '../../Cta';
import { StyledModuleContainer, StyledModuleCtaContainer } from '../../Layout/Styled';

import {
  StyledHorizontalCardContainer,
  StyledModuleDescription,
  StyledTitle,
  StyledVerticalCardContainer,
  StyledVerticalCardsDesktop,
  StyledVerticalCardsMobile,
  StyledVerticalEventWrapper,
} from './Styled';

type PastEvent = {
  id: string;
  title: string;
  shortDescription: string;
  date: string;
  location: string;
  image: string;
  link: string;
};

export interface PastEventsModuleProps {
  /** title */
  title: string;
  /** optional module short description */
  shortDescription: string;
  /** events  */
  pastEvents: Array<PastEvent>;
  initialAmount?: number;
  incrementAmount?: number;
}

const Vertical = ({ events }: { events: Array<PastEvent> }) => {
  const currentBreakpoint = useCurrentBreakpoint();

  const eventsChunkSize = currentBreakpoint === mediumBreakpoint ? 2 : 3;

  const chunkedProducts = chunkArray(events, eventsChunkSize);

  return (
    <Box>
      {chunkedProducts.map(({ chunk, key }) => (
        <StyledVerticalCardContainer key={key} className={`cards-${chunk.length}`}>
          {chunk.map((item: any) => {
            return (
              <StyledVerticalEventWrapper key={item.id} className={`cards-${chunk.length}`}>
                <EventCard key={item.id} {...item} variant="vertical" />
              </StyledVerticalEventWrapper>
            );
          })}
        </StyledVerticalCardContainer>
      ))}
    </Box>
  );
};

const PastEventsModule = ({
  title,
  shortDescription,
  pastEvents,
  initialAmount = 3,
  incrementAmount = 8,
}: PastEventsModuleProps) => {
  const firstEvent = pastEvents[0];
  const [amountOfEventsToDisplay, setDisplayLimit] = React.useState(initialAmount);
  const intl = useIntl();

  return pastEvents.length > 0 ? (
    <StyledModuleContainer data-appsearch-ignore="true">
      <StyledTitle>{title}</StyledTitle>
      {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
      <StyledHorizontalCardContainer>
        <EventCard
          id={firstEvent.id}
          title={firstEvent.title}
          image={firstEvent.image}
          displayDate={firstEvent.date}
          location={firstEvent.location}
          shortDescription={firstEvent.shortDescription}
          link={firstEvent.link}
          variant="horizontal"
        />
      </StyledHorizontalCardContainer>
      <StyledVerticalCardsDesktop>
        <Vertical events={pastEvents.slice(1, amountOfEventsToDisplay + 1)} />
      </StyledVerticalCardsDesktop>
      <StyledVerticalCardsMobile>
        <Vertical events={pastEvents.slice(0, amountOfEventsToDisplay + 1)} />
      </StyledVerticalCardsMobile>
      {pastEvents.length > amountOfEventsToDisplay + 1 ? (
        <StyledModuleCtaContainer>
          <Cta
            onClick={() => {
              setDisplayLimit(amountOfEventsToDisplay + incrementAmount);
            }}
          >
            <Button>{intl.formatMessage({ id: 'sections.events.loadmorepast' })}</Button>
          </Cta>
        </StyledModuleCtaContainer>
      ) : null}
    </StyledModuleContainer>
  ) : null;
};

export default PastEventsModule;
