import {
  ContentfulAttachment,
  ContentfulProductTableRow,
  ContentfulProductsListingTableSection,
  Maybe,
} from '../../generated/gatsby.types';
import extractCta from '../Cta';
import extractFileInforamtion from '../File';
import extractShortDescription from '../ShortDescription';

const extractRowBrochure = (brochure: Maybe<ContentfulAttachment> | null | undefined): any => {
  if (!brochure || !brochure.asset || !brochure.asset.file || !brochure.asset.file.details) {
    return null;
  }

  return {
    asset: {
      contentType: brochure.asset.file.contentType,
      fileName: brochure.asset.file.fileName,
      size: brochure.asset.file?.details.size,
      url: brochure.asset.file.url || './',
    },
    title: brochure.title,
  };
};

const extractTableRows = (tableRows: Maybe<Array<Maybe<ContentfulProductTableRow>>> | null | undefined): any => {
  if (!tableRows) {
    return [];
  }

  return tableRows
    .map((row: Maybe<ContentfulProductTableRow>) => {
      if (!row) {
        return null;
      }

      return {
        brochure: extractRowBrochure(row.brochure),
        number: row.number,
        product: row.product,
        weight: row.weight,
      };
    })
    .filter(Boolean);
};

const extractSidebarDescription = (sidebarDescription: any) => {
  return sidebarDescription && sidebarDescription.text ? sidebarDescription.text : null;
};

const transformProductListingTableSection = (item: ContentfulProductsListingTableSection) => {
  return {
    __typename: 'ProductsListingTableSection',
    cta: extractCta(item),
    key: item.id,
    productCatalogue: extractFileInforamtion(item.productCatalogue),
    shortDescription: extractShortDescription(item),
    sidebarDescription: extractSidebarDescription(item.sidebarDescription),
    sidebarTitle: item.sidebarTitle,
    tableRows: extractTableRows(item.tableRows),
    title: item.title,
  };
};

export default transformProductListingTableSection;
