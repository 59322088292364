import React from 'react';
import { useIntl } from 'react-intl';

import {
  Arrow as ArrowDownIcon,
  Doc as DocIcon,
  Jpg as JpgIcon,
  Pdf as PdfIcon,
  Png as PngIcon,
  Ppt as PptIcon,
  Xls as XlsIcon,
} from '../../icons';

import {
  StyledDocumentDownload,
  StyledDocumentDownloadMobile,
  StyledDocumentFileSize,
  StyledDocumentInfo,
  StyledDocumentInfoMobile,
  StyledDocumentTitle,
  StyledDocumentTitleContainer,
  StyledDownloadButton,
  StyledDownloadButtonMobile,
  StyledDownloadButtonText,
  StyledDownloadButtonTextMobile,
  StyledDownloadIcon,
  StyledFileExtentionIcon,
} from './Styled';

export const fileTypeIconMap: Record<string, React.ReactElement> = {
  doc: <DocIcon />,
  jpg: <JpgIcon />,
  pdf: <PdfIcon />,
  png: <PngIcon />,
  ppt: <PptIcon />,
  xls: <XlsIcon />,
};

export type DocumentDownloadProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  id?: string;
  documentTitle: string;
  documentType: string | null;
  fileSize?: string | null;
  downloadLink: string;
  renderMobileVariant?: boolean;
  showDocumentInfo?: boolean;
};

const DocumentDownload = ({
  documentTitle,
  documentType,
  fileSize,
  downloadLink,
  renderMobileVariant = false,
  showDocumentInfo = true,
}: DocumentDownloadProps) => {
  const intl = useIntl();
  if (renderMobileVariant) {
    return (
      <StyledDocumentDownloadMobile>
        <StyledDocumentInfoMobile>
          <StyledDocumentTitleContainer>
            <StyledDocumentTitle>{documentTitle}</StyledDocumentTitle>
          </StyledDocumentTitleContainer>
        </StyledDocumentInfoMobile>
        <StyledDownloadButtonMobile target="_blank" rel="nofollow" href={downloadLink}>
          <StyledDownloadButtonTextMobile>
            {intl.formatMessage({ id: 'globals.download' })}
          </StyledDownloadButtonTextMobile>
          <StyledDownloadIcon>
            <ArrowDownIcon />
          </StyledDownloadIcon>
        </StyledDownloadButtonMobile>
      </StyledDocumentDownloadMobile>
    );
  }
  return (
    <StyledDocumentDownload>
      <StyledDocumentInfo>
        {showDocumentInfo && documentType ? (
          <StyledFileExtentionIcon>{fileTypeIconMap[documentType]}</StyledFileExtentionIcon>
        ) : null}
        <StyledDocumentTitleContainer>
          <StyledDocumentTitle>{documentTitle}</StyledDocumentTitle>
          {showDocumentInfo ? (
            <StyledDocumentFileSize>
              {documentType}, {fileSize}
            </StyledDocumentFileSize>
          ) : null}
        </StyledDocumentTitleContainer>
      </StyledDocumentInfo>
      <StyledDownloadButton target="_blank" rel="nofollow" href={downloadLink}>
        <StyledDownloadButtonText>{intl.formatMessage({ id: 'globals.download' })}</StyledDownloadButtonText>
        <StyledDownloadIcon>
          <ArrowDownIcon />
        </StyledDownloadIcon>
      </StyledDownloadButton>
    </StyledDocumentDownload>
  );
};

export default DocumentDownload;
