import * as React from 'react';
import { useIntl } from 'react-intl';

import { ContactsForm } from '../../Forms';

import {
  StyledContactsFormModule,
  StyledNoResultsAside,
  StyledNoResultsContainer,
  StyledNoResultsMain,
} from './Styled';

type NoResultsProps = {
  /** Title for the contacts form */
  title: string;
  /** Short description for the contacts form */
  shortDescription?: string;
  /** submit handler for contacts form */
  handleFormSubmit: (name: string, formData: { [key: string]: string }) => void;
  /** the search query */
  query: string;
  /** shows email subscribe option on contact form */
  showContactFormSubscribeOption: boolean;
};

const NoResults = ({
  title,
  shortDescription,
  handleFormSubmit,
  query,
  showContactFormSubscribeOption,
}: NoResultsProps) => {
  const intl = useIntl();

  return (
    <StyledNoResultsContainer>
      <StyledNoResultsMain>
        <p>{intl.formatMessage({ id: 'sections.search.noresults.title' })}</p>
        <h2>{`"${query}"`}</h2>
      </StyledNoResultsMain>

      <StyledNoResultsAside>
        <StyledContactsFormModule>
          <ContactsForm
            title={title}
            shortDescription={shortDescription}
            handleFormSubmit={handleFormSubmit}
            showSubscribeOptions={showContactFormSubscribeOption}
          />
        </StyledContactsFormModule>
      </StyledNoResultsAside>
    </StyledNoResultsContainer>
  );
};

export default NoResults;
