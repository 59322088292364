import * as React from 'react';
import { useIntl } from 'react-intl';

//
import { Cancel as CloseIcon, Search as SearchIcon } from '../../../icons';

import { StyledClosedButton, StyledInput, StyledWrapper } from './Styled';

export type Props = {
  searchFunction: (searchTerm: string) => void;
  onClose: () => void;
};

export const MobileSearch = ({ searchFunction, onClose }: Props) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [focused, setFocusedState] = React.useState(false);
  const inputRef = React.createRef<HTMLInputElement>();
  const intl = useIntl();

  React.useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  });

  /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
  return (
    <StyledWrapper className={`${focused ? 'focused-input' : ''}`}>
      <span
        role="button"
        tabIndex={0}
        onClick={() => {
          if (focused) {
            searchFunction(searchTerm);
          }
        }}
        onKeyDown={() => {
          if (focused) {
            searchFunction(searchTerm);
          }
        }}
      >
        <SearchIcon />
      </span>
      <StyledInput
        name="search"
        role="search"
        ref={inputRef}
        placeholder={`${intl.formatMessage({ id: 'header.search.placeholder' })} Aveve BioChem`}
        autoComplete="off"
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          setSearchTerm(e.currentTarget.value);
        }}
        onKeyPress={(e: KeyboardEvent) => {
          if (e.key === 'Enter') {
            searchFunction(searchTerm);
          }
        }}
        onKeyDown={(e: KeyboardEvent) => {
          if (e.keyCode === 27) {
            setSearchTerm('');
            onClose();
            if (inputRef.current) {
              inputRef.current.value = '';
            }
          }
        }}
        onFocus={() => setFocusedState(true)}
        onBlur={() => {
          setFocusedState(false);
          onClose();
        }}
      />
      <StyledClosedButton
        role="button"
        tabIndex={0}
        onKeyDown={() => {
          searchFunction(searchTerm);
          onClose();
        }}
        onClick={() => {
          setSearchTerm('');
          if (inputRef.current) {
            inputRef.current.value = '';
          }
          onClose();
        }}
      >
        <CloseIcon />
      </StyledClosedButton>
    </StyledWrapper>
  );
};

export default MobileSearch;
