import * as React from 'react';
import { Helmet } from 'react-helmet';

import Utils from '../../utils';
//

export type SiteMetaDataProps = {
  /** Used for swifttype indexing */
  pageLocale: string;
  /** Defines content type for swift type, news, events etc */
  pageType: string;
  /** Site name */
  siteName: string;
  /** Meta title */
  title: string;
  /** Meta description */
  description?: string;
  /** Canonical URL */
  canonical?: string;
  /** Noindex */
  noindex?: boolean;
  /** Nofollow */
  nofollow?: boolean;
  /** Swift type date */
  swiftTypeDate?: string;
  /** Swift type location */
  swiftTypeLocation?: string;
  // image used for social media shares
  image?: string;
  updatedAt: string;
  // used for the search page card images
  searchPreviewImage?: any;
  locale: string;
  ogPageType: string;
  searchTags: string;
};

const Metatags = ({
  siteName,
  title,
  description,
  canonical,
  nofollow,
  noindex,
  swiftTypeDate,
  swiftTypeLocation,
  image,
  pageType,
  pageLocale,
  updatedAt,
  searchPreviewImage,
  locale,
  ogPageType,
  searchTags,
}: SiteMetaDataProps) => {
  const metaLinks = [];
  const metaTags = [];

  if (canonical) {
    metaLinks.push({
      href: canonical,
      rel: 'canonical',
    });
    metaTags.push({
      content: canonical,
      name: 'og:url',
    });
  }

  if (image) {
    metaTags.push({
      content: image,
      name: 'og:image',
    });

    metaTags.push({
      content: image,
      name: 'image',
    });

    metaTags.push({
      content: image,
      name: 'twitter:image',
    });
  }

  // Handle nofollow and noindex
  if (nofollow && noindex) {
    metaTags.push({
      content: 'noindex, nofollow',
      name: 'robots',
    });
  } else if (nofollow) {
    metaTags.push({
      content: 'nofollow',
      name: 'robots',
    });
  } else if (noindex) {
    metaTags.push({
      content: 'noindex',
      name: 'robots',
    });
  }

  metaTags.push({
    content: title || siteName,
    property: 'og:title',
  });

  metaTags.push({
    content: title || siteName,
    property: 'twitter:title',
  });

  metaTags.push({
    content: 'summary',
    name: 'twitter:card',
  });

  metaTags.push({
    content: ogPageType,
    property: 'og:type',
  });

  metaTags.push({
    content: 'summary_large_image',
    name: 'twitter:card',
  });

  metaTags.push({
    content: siteName,
    name: 'og:site_name',
  });

  metaTags.push({
    content: locale,
    name: 'og:locale',
  });

  if (description) {
    metaTags.push({
      content: description,
      name: 'description',
    });
    metaTags.push({
      content: description,
      property: 'og:description',
    });
    metaTags.push({
      content: description,
      name: 'twitter:description',
    });
  }

  return (
    <Helmet>
      <meta charSet="utf-8" name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      {searchTags && <meta name="keywords" content={searchTags} />}
      <script type="text/javascript">{Utils.getBrowserPolyfills()}</script>
      {siteName && title ? <title>{`${siteName} | ${title}`}</title> : null}
      {siteName && !title ? <title>{`${siteName}`}</title> : null}
      {metaTags.map((tag: any) => (
        <meta key={tag.name || tag.property} {...tag} />
      ))}
      {metaLinks.map((tag: any) => (
        <link key={tag.rel} {...tag} />
      ))}
      <meta name="page-updated" data-type="string" content={updatedAt} />
      <meta name="page-lang" data-type="string" content={pageLocale} />
      <meta name="page-type" data-type="string" content={pageType} />
      {siteName === 'Osmo' && <meta name="facebook-domain-verification" content="rrmlbq6kbgvok3uzhzvs1t4tikgnx6" />}
      {searchPreviewImage && (
        <meta name="preview-image" data-type="string" content={JSON.stringify(searchPreviewImage)} />
      )}
      {swiftTypeDate && <meta name="date" data-type="string" content={swiftTypeDate} />}
      {swiftTypeLocation && <meta name="location" data-type="string" content={swiftTypeLocation} />}
    </Helmet>
  );
};

export default Metatags;
