import React, { useReducer } from 'react';

import { StyledModuleContainer } from '../../Layout/Styled';

import BrandCards from './BrandCards';
import Categories from './Categories';
import Filters from './Filters';
import Modal from './Modal';
import {
  StyledContentWrapper,
  StyledDescription,
  StyledFilterWrapper,
  StyledHeaderWrapper,
  StyledTitle,
  StyledWrapper,
} from './Styled';

type Brand = {
  id: string;
  title: string;
  shortDescription: string;
  logo: string;
  media: any;
  categories: string[];
  filters: string[];
  richText: any;
  address: string;
  cta?: {
    to: string;
    label: string;
  };
  productsAndServices: string;
};

const filterArray = (brandArr: string[], filterArr: string[]) => {
  if (filterArr.length) {
    for (let i = 0; i < brandArr.length; i += 1) {
      if (filterArr.includes(brandArr[i])) {
        return true;
      }
    }

    return false;
  }

  return true;
};

interface FilterBrands {
  allBrands: Brand[];
  category: string | null;
  filters: string[];
}

const filterBrands = ({ allBrands, category, filters }: FilterBrands) =>
  allBrands
    // filter on categories
    .filter(brand => !category || brand.categories.includes(category))
    // filter on filters
    .filter(brand => filterArray(brand.filters, filters));

const reducer = (state: any, action: any) => ({
  ...state,
  ...action,
  brands: filterBrands({ ...state, ...action }),
});

interface BrandListingModule {
  title: string;
  shortDescription: string;
  brands: Brand[];
  categories: string[];
  filters: string[];
}

const BrandListingModule = ({ title, shortDescription, brands, categories, filters }: BrandListingModule) => {
  const [state, dispatch] = useReducer(reducer, {
    allBrands: brands,
    brands,
    category: null,
    filters: [],
    selectedBrandId: null,
  });

  return (
    <>
      <StyledModuleContainer smallTopSpacer>
        <StyledWrapper>
          <StyledHeaderWrapper>
            <StyledTitle>{title}</StyledTitle>
            <StyledDescription>{shortDescription}</StyledDescription>
          </StyledHeaderWrapper>
          <StyledContentWrapper>
            <StyledFilterWrapper>
              <Categories categories={categories} state={state} dispatch={dispatch} noReset />
              <Filters filters={filters} state={state} dispatch={dispatch} />
            </StyledFilterWrapper>
            <BrandCards state={state} dispatch={dispatch} />
          </StyledContentWrapper>
        </StyledWrapper>
      </StyledModuleContainer>
      {state.selectedBrandId && <Modal state={state} dispatch={dispatch} />}
    </>
  );
};

export default BrandListingModule;
