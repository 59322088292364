import * as React from 'react';
import { useIntl } from 'react-intl';

import { Alert as AlertIcon, Check as CheckIcon } from '../../../icons';
import { EMAIL_REGEX } from '../../../utils';
import Button from '../../Button';
import { SelectField } from '../../Forms';

import StyledSubscribeFormContainer, {
  StyledFailureMessage,
  StyledForm,
  StyledInfoText,
  StyledInput,
  StyledSuccessMessage,
} from './Styled';

interface Props {
  onSubmit: (emailAddress: string, country: string) => any;
  showCountrySelector: boolean;
}

const STATUS_SUBMITTING = 'submitting';
const STATUS_INVALID_EMAIL = 'invalid_email';
const STATUS_INVALID_COUNTRY = 'invalid_country';
const STATUS_ERROR = 'error';
const STATUS_SUCCESS = 'success';

const SubscribeForm = ({ onSubmit, showCountrySelector }: Props) => {
  const intl = useIntl();

  const options = [
    {
      label: intl.formatMessage({ id: 'footer.subscribe.belgium' }),
      value: 'BE',
    },
    {
      label: intl.formatMessage({ id: 'footer.subscribe.netherlands' }),
      value: 'NL',
    },
  ];

  const [emailText, setEmailAddress] = React.useState('');
  const [country, setCountry] = React.useState<string>('BE');
  const inputRef = React.createRef<HTMLInputElement>();

  const [status, setStatus] = React.useState('');

  const handleSubmit = async () => {
    if (!EMAIL_REGEX.test(emailText)) {
      setStatus(STATUS_INVALID_EMAIL);
      return;
    }
    if (!country) {
      setStatus(STATUS_INVALID_COUNTRY);
      return;
    }
    setStatus(STATUS_SUBMITTING);
    const result = await onSubmit(emailText, country);
    setStatus(result === 200 ? STATUS_SUCCESS : STATUS_ERROR);
  };

  const submitting = status === STATUS_SUBMITTING;

  return (
    <StyledSubscribeFormContainer>
      <StyledInfoText>{intl.formatMessage({ id: 'footer.newsletter.heading' })}</StyledInfoText>
      <StyledForm>
        <StyledInput
          name="search"
          role="search"
          ref={inputRef}
          disabled={submitting}
          placeholder={intl.formatMessage({ id: 'footer.subscribe.enteremail' })}
          autoComplete="off"
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            setEmailAddress(e.currentTarget.value);
          }}
          onKeyPress={(e: KeyboardEvent) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
          onKeyDown={(e: KeyboardEvent) => {
            if (e.keyCode === 27) {
              setEmailAddress('');
              if (inputRef.current) {
                inputRef.current.value = '';
              }
            }
          }}
        />
        {showCountrySelector && (
          <SelectField
            options={options}
            label={options[0].label || intl.formatMessage({ id: 'footer.subscribe.countryLabel' })}
            selected={(option: { label: string; value: string }) => setCountry(option.value)}
          />
        )}
        <Button onClick={handleSubmit} disabled={submitting}>
          {submitting
            ? intl.formatMessage({ id: 'footer.subscribe.subscribing' })
            : intl.formatMessage({ id: 'footer.subscribe.subscribe' })}
        </Button>
      </StyledForm>

      {status === STATUS_INVALID_EMAIL ? (
        <StyledFailureMessage>
          <AlertIcon />
          <p>{intl.formatMessage({ id: 'footer.subscribe.invalid_email' })}</p>
        </StyledFailureMessage>
      ) : null}

      {status === STATUS_INVALID_COUNTRY ? (
        <StyledFailureMessage>
          <AlertIcon />
          <p>{intl.formatMessage({ id: 'footer.subscribe.invalid_country' })}</p>
        </StyledFailureMessage>
      ) : null}

      {status === STATUS_ERROR ? (
        <StyledFailureMessage>
          <AlertIcon />
          <p>{intl.formatMessage({ id: 'footer.subscribe.failure' })}</p>
        </StyledFailureMessage>
      ) : null}

      {status === STATUS_SUCCESS ? (
        <StyledSuccessMessage>
          <CheckIcon />
          <p>{intl.formatMessage({ id: 'footer.subscribe.success' })}</p>
        </StyledSuccessMessage>
      ) : null}
    </StyledSubscribeFormContainer>
  );
};

export default SubscribeForm;
