import * as React from 'react';

import Cta from '../../Cta';
import { NavItemType } from '../types';

import { StyledNavItem, StyledNavigation, StyledSelectedNavItem } from './Styled';

interface Props {
  items: Array<NavItemType>;
  currentHoverItem: NavItemType | null;
  currentPageUrl: string;
  activeMenuItem: NavItemType | null;
  onMouseEnter: (item: NavItemType) => void;
  variant?: string;
  isHomepage: boolean;
  pageId: string;
}

const showActiveParentItem = (
  navigation: NavItemType[],
  currentPageUrl: string,
  currentItem: NavItemType,
  pageId: string,
) => {
  const parentItem = navigation.find(
    item =>
      item.children?.some(childItem => {
        return childItem.to !== '' && currentPageUrl.includes(childItem.to);
      }),
  );

  return parentItem?.to === currentItem.to;
};

const PrimaryNavigation = ({
  items,
  activeMenuItem,
  currentPageUrl,
  onMouseEnter,
  variant,
  isHomepage,
  pageId,
}: Props) => {
  if (isHomepage) {
    return null;
  }

  return (
    <StyledNavigation variant={variant}>
      {items.map((item: NavItemType, idx: number) => {
        if (activeMenuItem?.to === item.to || showActiveParentItem(items, currentPageUrl, item, pageId)) {
          return (
            <StyledSelectedNavItem
              // eslint-disable-next-line react/no-array-index-key
              key={`primary-active-${item.to}-${idx}`}
              onMouseLeave={() => onMouseEnter({} as NavItemType)}
              onFocus={() => onMouseEnter(item)}
              variant={variant}
            >
              <Cta
                to={item.to !== '' ? item.to : item.children[0].to}
                onClick={e => {
                  onMouseEnter(item);
                }}
              >
                {item.label}
              </Cta>
            </StyledSelectedNavItem>
          );
        }

        return (
          <StyledNavItem
            // eslint-disable-next-line react/no-array-index-key
            key={`primary-${item.to}-${idx}`}
            onMouseLeave={() => onMouseEnter({} as NavItemType)}
            variant={variant}
            onFocus={() => onMouseEnter(item)}
          >
            <Cta
              to={item.to !== '' ? item.to : item.children[0].to}
              onClick={e => {
                onMouseEnter(item);
              }}
            >
              {item.label}
            </Cta>
          </StyledNavItem>
        );
      })}
    </StyledNavigation>
  );
};

export default PrimaryNavigation;
