import * as React from 'react';

const ChevronRightList = ({ className }: any) => (
  <svg className={className} height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm2.037,16.886a.923.923,0,1,1-1.3,1.3L7.194,12.652a.923.923,0,0,1,0-1.3l5.538-5.538a.923.923,0,0,1,1.3,1.3L9.152,12Z"
      fill="currentColor"
      id="chevron-right-features-list"
      opacity="0.8"
      transform="translate(24 24) rotate(180)"
    />
  </svg>
);

export default ChevronRightList;
