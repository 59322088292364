const transformImage = (item: any) => {
  return {
    __typename: 'Image',
    altText: item?.altText ? item.altText : item.title,
    asset: item.asset,
    key: item.id,
    position: item.position,
    size: item.size,
  };
};

export default transformImage;
