import styled from 'styled-components';

export const StyledFooterSocialList = styled.ul`
  display: flex;
  padding: 0;
  margin-top: 10px;
  list-style-type: none;
  margin-top: 41px;
`;

export const StyledFooterSocialListItem = styled.li`
  margin-right: 25px;
  a svg path {
    fill: ${props => props.theme.colors.darkGray};
  }

  &:hover {
    svg > * {
      fill: ${props => props.theme.footer.activeColor};
    }
  }
`;
