type Cta = {
  id: string;
  label: string;
  to: string;
};

const extractCta = (item: any): Cta => {
  // TODO adjust for other types than CTA
  if (item?.cta?.internalEntry?.slug) {
    const to = item.cta.internalEntry.slug === 'homepage' ? '/' : item.cta.internalEntry.slug;

    return {
      id: item.cta.id,
      label: item.cta.title,
      to,
    };
  }

  if (item.cta) {
    return {
      id: item.cta.id,
      label: item.cta.title,
      to: item.cta.externalUrl,
    };
  }

  return null;
};

export default extractCta;
