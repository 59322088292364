import React from 'react';
import { useIntl } from 'react-intl';
import { BoxProps } from 'rebass/styled-components';

import Button from '../../Button';
import ProductCardProps from '../../Cards/ProductCard/types';
import { StyledModuleContainer } from '../../Layout/Styled';

import Horizontal from './Horizontal';
import {
  StyledHeadingAndDescriptionContainer,
  StyledHeadingAndDescriptionWrapper,
  StyledLoadMoreWrapper,
  StyledModuleDescription,
  StyledModuleHeading,
} from './Styled';
import Vertical from './Vertical';

interface ProductListingModuleProps extends BoxProps {
  title: string;
  shortDescription?: string | null;
  products: ProductCardProps[];
  variant?: 'vertical' | 'horizontalLeft' | 'horizontalRight';
  initialAmount?: number;
  incrementAmount?: number;
  categorySearchTerm?: string;
}

const ProductListingModule = React.forwardRef(
  (
    {
      title,
      shortDescription,
      products,
      variant = 'vertical',
      initialAmount = 6,
      incrementAmount = 8,
      categorySearchTerm,
      ...rest
    }: ProductListingModuleProps,
    ref,
  ) => {
    const [amountOfProductsToDisplay, setDisplayLimit] = React.useState(initialAmount);
    const intl = useIntl();

    return (
      <StyledModuleContainer ref={ref} {...(rest as any)} data-appsearch-ignore="true">
        {products && products.length > 0 ? (
          <StyledHeadingAndDescriptionContainer>
            <StyledHeadingAndDescriptionWrapper>
              <StyledModuleHeading>{title}</StyledModuleHeading>
              {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
            </StyledHeadingAndDescriptionWrapper>
          </StyledHeadingAndDescriptionContainer>
        ) : (
          <StyledHeadingAndDescriptionContainer>
            <StyledModuleHeading>
              {intl.formatMessage({ id: 'sections.product.noproductsfoundfor' })} {categorySearchTerm}
            </StyledModuleHeading>
          </StyledHeadingAndDescriptionContainer>
        )}
        {variant === 'vertical' ? (
          <Vertical products={products.slice(0, amountOfProductsToDisplay)} />
        ) : (
          <Horizontal products={products.slice(0, amountOfProductsToDisplay)} variant={variant} />
        )}
        {products.length > amountOfProductsToDisplay ? (
          <StyledLoadMoreWrapper>
            <Button
              onClick={e => {
                e.preventDefault();
                setDisplayLimit(amountOfProductsToDisplay + incrementAmount);
              }}
            >
              {intl.formatMessage({ id: 'sections.product.loadmore' })}
            </Button>
          </StyledLoadMoreWrapper>
        ) : null}
      </StyledModuleContainer>
    );
  },
);
ProductListingModule.displayName = 'ProductListingModule';

export default ProductListingModule;
