/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';

import {
  StyledDropDown,
  StyledDropDownContainer,
  StyledDropDownItemSeparator,
  StyledDropDownSeparator,
  StyledHeader,
  StyledMultiSelectContainer,
} from './Styled';

interface ArrowProps {
  expanded?: boolean;
}

const Arrow = ({ expanded }: ArrowProps) => {
  return (
    <svg width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d={expanded ? 'M18 15 12 9 6 15' : 'M6 9L12 15 18 9'} />
    </svg>
  );
};

const DropdownHeader = ({ label }: any) => <span>{label}</span>;

const MultiSelect = ({ selected, options, expanded, setExpanded, label }: any) => {
  const [labelText, setLabelText] = useState(label);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const onItemSelect = (option: { label: string; value: any }) => {
    selected(option);
    setLabelText(option.label);
    toggleExpanded();
  };

  return (
    <StyledDropDownContainer expanded={expanded}>
      <StyledHeader onClick={toggleExpanded}>
        <DropdownHeader label={labelText} />
        <Arrow expanded={expanded} />
      </StyledHeader>
      <StyledDropDownSeparator expanded={expanded} />
      {expanded && (
        <StyledDropDown expanded={expanded}>
          {options.map((option: any) => {
            return (
              <StyledDropDownItemSeparator key={option.value} onClick={() => onItemSelect(option)}>
                <label
                  htmlFor={`cb-${option.value}`}
                  onClick={e => {
                    e.stopPropagation();
                    onItemSelect(option);
                  }}
                >
                  <span
                    id={`cb-${option.value}`}
                    style={{ marginLeft: 8 }}
                    onClick={e => {
                      e.stopPropagation();
                      onItemSelect(option);
                    }}
                  >
                    {option.label}
                  </span>
                </label>
              </StyledDropDownItemSeparator>
            );
          })}
        </StyledDropDown>
      )}
    </StyledDropDownContainer>
  );
};

const useMultiSelectContainer = (ref: any, setExpanded: any) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setExpanded(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};

const MultiSelectContainer = (props: any) => {
  const {
    children: {
      props: { setExpanded },
    },
  } = props;
  const { children } = props;

  const wrapperRef = useRef(null);

  useMultiSelectContainer(wrapperRef, setExpanded);

  return <StyledMultiSelectContainer ref={wrapperRef}>{children}</StyledMultiSelectContainer>;
};

const SelectField = ({ selected, options, label }: any) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <MultiSelectContainer>
      <MultiSelect selected={selected} options={options} label={label} setExpanded={setExpanded} expanded={expanded} />
    </MultiSelectContainer>
  );
};

export default SelectField;
