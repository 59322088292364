import { Link } from 'gatsby';
import React from 'react';
import { LinkProps } from 'rebass';
import scrollToElement from 'scroll-to-element';
import styled from 'styled-components';

import { IntlContext } from '../../i18n';
import Utils from '../../utils';

const dontPrefixPaths = process.env.DONT_PREFIX_PATHS_LOCALE === 'true';

interface Props extends LinkProps {
  /** Destination route or url */
  to?: string;
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode;
  /** Optional click callback function */
  onClick?: (e: React.SyntheticEvent) => void;
  /** Optional boolean to force locale prefix */
  forceLocale?: boolean;
}

const StyledLink = styled(Link)`
  ${props => props.theme.styles.a};
`;

const StyledA = styled.a`
  ${props => props.theme.styles.a.color};
`;

const Cta = React.forwardRef(
  ({ to, children, onClick = () => undefined, forceLocale = false, ...rest }: Props, ref) => {
    if (!to) {
      return (
        <StyledA
          ref={ref}
          href="#"
          onClick={e => {
            e.preventDefault();
          }}
          {...(rest as any)}
        >
          {children}
        </StyledA>
      );
    }

    const isInternal = to ? Utils.isInternalUrl(to) : false;

    if (to && to.startsWith('#') && to.length > 1) {
      return (
        <StyledA
          ref={ref}
          href={to}
          onClick={e => {
            e.preventDefault();
            scrollToElement(to, { offset: -50 });
            if (onClick) {
              onClick(e);
            }
          }}
          {...(rest as any)}
        >
          {children}
        </StyledA>
      );
    }

    if (!isInternal) {
      return (
        <StyledA
          ref={ref}
          href={to.startsWith('http') || to.startsWith('mailto') || to.startsWith('//') ? to : `https://${to}`}
          onClick={onClick}
          target="_blank"
          rel="nofollow"
          {...(rest as any)}
        >
          {children}
        </StyledA>
      );
    }

    if (rest.target && rest.target === '_blank') {
      return (
        <StyledA ref={ref} href={to} onClick={onClick} target="_blank" rel="nofollow" {...(rest as any)}>
          {children}
        </StyledA>
      );
    }

    let prefixedTo = to;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const locale: any = React.useContext(IntlContext);

    if (dontPrefixPaths) {
      prefixedTo = `/${to}`.replace('//', '/');
    } else {
      // eslint-disable-next-line no-lonely-if
      if (to && forceLocale && locale && !to.startsWith(`/${locale.path}`)) {
        prefixedTo = `/${locale.path}/${to}`.replace('//', '/');
      } else if (to && locale && !to.startsWith(`/${locale.path}`)) {
        prefixedTo = `/${locale.path}/${to}`.replace('//', '/');
      }
    }

    return (
      <StyledLink ref={ref} to={prefixedTo} onClick={onClick} {...(rest as any)}>
        {children}
      </StyledLink>
    );
  },
);
Cta.displayName = 'Cta';

export default Cta;
