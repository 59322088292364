import {
  AdviceCardModule,
  AdviceListingModule,
  BrandListingModule,
  CategoriesModule,
  CategoryListingModule,
  ContactsModule,
  CtaSectionModule,
  CustomBlocksModule,
  DocumentDownloadModule,
  EmbeddedContentModule,
  EventListingModule,
  FindADealerModule,
  HeroSectionModule,
  ImageAndTextModule,
  InfoSectionModule,
  LatestNewsModule,
  MediaModule,
  NewsListingModule,
  PastEventsModule,
  ProductCardModule,
  ProductListingModule,
  ProductListingTableModule,
  SearchResultsModule,
  ShortBannerModule,
  TableModule,
  UpcomingEventsModule,
} from '../../Modules';
import { CenteredRichText } from '../../RichText';

const componentMap: Record<string, any> = {
  AdviceListingSection: AdviceListingModule,
  AdvicesSection: AdviceCardModule,
  BrandListing: BrandListingModule,
  CategoryListingSection: CategoryListingModule,
  ContactsSection: ContactsModule,
  CtaSection: CtaSectionModule,
  CustomBlocksSection: CustomBlocksModule,
  DocumentDownloadSection: DocumentDownloadModule,
  EmbeddedContent: EmbeddedContentModule,
  EventsListingSection: EventListingModule,
  FindADealerSection: FindADealerModule,
  HeroSection: HeroSectionModule,
  ImageAndText: ImageAndTextModule,
  InfoSection: InfoSectionModule,
  LatestNewsSection: LatestNewsModule,
  Media: MediaModule,
  NewsListingSection: NewsListingModule,
  PastEventsSection: PastEventsModule,
  ProductCategoriesSection: CategoriesModule,
  ProductListingSection: ProductListingModule,
  ProductsListingTableSection: ProductListingTableModule,
  ProductsServicesSection: ProductCardModule,
  SearchResultsSection: SearchResultsModule,
  ShortBanner: ShortBannerModule,
  Table: TableModule,
  Text: CenteredRichText,
  UpcomingEvents: UpcomingEventsModule,
};

export default componentMap;
