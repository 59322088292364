import { getSrc } from 'gatsby-plugin-image';

const dontPrefixPaths = process.env.DONT_PREFIX_PATHS_LOCALE === 'true';

const buildCanonicalLink = (extractedPageSettings: any, siteUrl: string, locale: string, slug: string) => {
  const pagePath = dontPrefixPaths ? slug : `${locale}/${slug}`;

  return extractedPageSettings && extractedPageSettings.canonical
    ? extractedPageSettings.canonical
    : `${siteUrl}/${pagePath}`;
};

const extractImage = (image: any) => {
  if (!image) {
    return null;
  }

  // if (image && image.socialImage && image.socialImage.fixed && image.socialImage.fixed.src) {
  //   return image.socialImage.fixed.src;
  // }
  if (image?.socialImage?.gatsbyImageData) {
    return getSrc(image?.socialImage?.gatsbyImageData);
  }

  // if (image && image.asset && image.asset.fixed && image.asset.fixed.src) {
  //   return image.asset.fixed.src;
  // }

  // if (image && image.asset && image.asset.fluid && image.asset.fluid.src) {
  //   return image.asset.fluid.src;
  // }
  if (image?.asset?.gatsbyImageData) {
    return getSrc(image?.asset?.gatsbyImageData);
  }

  return null;
};

const metatagBuilder = (
  metadata: any,
  siteUrl: string,
  siteName: string,
  siteDescription: string,
  siteImage: any,
  locale: any,
  slug: string,
) => {
  const metaLinks = [];
  const metaTags = [];

  const canonical = buildCanonicalLink(metadata, siteUrl, locale, slug);
  metaLinks.push({
    rel: 'canonical',
    href: canonical,
  });
  metaTags.push({
    name: 'og:url',
    content: canonical,
  });

  if (metadata.image || siteImage) {
    const imageUrl = metadata.image ? extractImage(metadata.image) : extractImage(siteImage);
    metaTags.push({
      name: 'og:image',
      content: imageUrl,
    });

    metaTags.push({
      name: 'image',
      content: imageUrl,
    });

    metaTags.push({
      name: 'twitter:image',
      content: imageUrl,
    });
  }

  if (metadata.nofollow && metadata.noindex) {
    metaTags.push({
      name: 'robots',
      content: 'noindex, nofollow',
    });
  } else if (metadata.nofollow) {
    metaTags.push({
      name: 'robots',
      content: 'nofollow',
    });
  } else if (metadata.noindex) {
    metaTags.push({
      name: 'robots',
      content: 'noindex',
    });
  }

  if (siteDescription || metadata.description) {
    const description = metadata.description || siteDescription;
    metaTags.push({
      name: 'description',
      content: description,
    });
    metaTags.push({
      property: 'og:description',
      content: description,
    });
    metaTags.push({
      name: 'twitter:description',
      content: description,
    });
  }

  metaTags.push({
    property: 'og:title',
    content: metadata.title || siteName,
  });

  metaTags.push({
    property: 'twitter:title',
    content: metadata.title || siteName,
  });

  metaTags.push({
    property: 'og:type',
    content: 'event',
  });

  metaTags.push({
    name: 'twitter:card',
    content: 'summary_large_image',
  });

  metaTags.push({
    name: 'twitter:card',
    content: 'summary',
  });

  metaTags.push({
    name: 'og:site_name',
    content: siteName,
  });

  metaTags.push({
    name: 'og:locale',
    content: locale.id,
  });

  return {
    metaTags,
    metaLinks,
  };
};

export default metatagBuilder;
