import extractCta from '../Cta';

const transformHeroSection = (item: any) => {
  return {
    __typename: 'HeroSection',
    cta: extractCta(item),
    key: item.id,
    shortDescription: item.shortDescription && item.shortDescription.text ? item.shortDescription.text : '',
    title: item.title,
  };
};

export default transformHeroSection;
