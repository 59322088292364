import styled from 'styled-components';

import Media from '../../../utils/Media';

export const StyledWrapper = styled.div``;

export const StyledHeaderWrapper = styled.div`
  max-width: 660px;
  margin: 0 auto;
  ${Media({
    margin: ['0 auto 0 0', null, null, '0 auto 0 25%'],
    paddingLeft: [null, null, null, '20px'],
  })}
`;

export const StyledTitle = styled.div`
  color: ${props => props.theme.colors.text};
  font-weight: ${props => props.theme.heroSection.titleFontWeight || 'normal'};
  word-break: break-word;
  margin-bottom: 0px;

  ${Media({
    marginTop: ['40px', null, null, '93px'],
    fontSize: ['2.5rem', null, null, '3.5rem'],
    lineHeight: ['1.2', null, null, '1.14'],
  })}
`;

export const StyledDescription = styled.div`
  color: ${props => props.theme.colors.text};
  line-height: 1.875rem;
  font-size: 1.25rem;

  ${Media({
    marginTop: ['1rem', null, null, '1.5rem'],
  })}
`;

export const StyledContentWrapper = styled.div`
  display: flex;

  ${Media({
    padding: ['20px 0', null, '60px 0'],
    flexDirection: ['column', null, 'row'],
  })}
`;

export const StyledFilterWrapper = styled.div`
  ${Media({
    width: ['100%', null, '25%'],
    marginBottom: ['20px', null, 0],
    marginRight: [0, null, '32px'],
  })}
`;
