import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';

//
import Media from '../../../utils/Media';

export const StyledVerticalCardContainer = styled.div`
  display: flex;
  align-items: top;
  justify-content: left;

  &.cards-2 {
    ${Media({
      width: ['100%', null, null, '66.66%'],
    })}
  }

  &.cards-1 {
    ${Media({
      width: ['100%', null, null, '33.33%'],
    })}
  }

  ${Media({
    boxShadow: [null, null, null, '0 2px 20px 0 rgba(0, 0, 0, 0.1)'],
    flexWrap: ['wrap', null, null, 'nowrap'],
    marginBottom: ['0', null, null, '3.25rem'],
    position: ['relative', null, null, 'static'],
  })}

  &:last-child {
    margin-bottom: 0;
  }

  > *:not(:last-child) {
    ${Media({
      boxShadow: [null, null, null, 'inset -1px 0px 0px 0px #dedede'],
      marginBottom: ['1rem', null, null, '0'],
    })}
  }

  > *:last-child {
    ${Media({
      boxShadow: [null, null, null, 'none'],
      marginBottom: ['1rem', null, null, '0'],
    })}
  }
`;

export const StyledHorizontalCardContainer = styled.div`
  ${Media({
    display: ['none', 'none', 'none', 'block'],
    marginBottom: ['1rem', null, null, '3rem'],
    width: ['100%', null, 'calc(50% - 0.5rem)', '100%'],
  })}
  > .horizontal,
  > .horizontal div {
    max-width: none;
  }
`;

export const StyledTitle = styled.h2`
  ${Media({
    fontSize: ['32px', '36px', null, '40px'],
  })}

  font-weight: ${props => props.theme.upComingEvents.titleFontWeight || 'normal'};
  line-height: 1.2;
  color: ${props => props.theme.colors.text};
  margin-bottom: 16px;
  word-break: break-word;
  margin-top: 0px;
`;

export const StyledModuleDescription = styled(Text)`
  font-size: 20px;
  line-height: 1.5;
  color: ${props => props.theme.colors.text};

  ${Media({
    marginBottom: ['1rem', null, null, '3.25rem'],
  })}
`;

export const StyledVerticalEventWrapper = styled(Box)`
  ${Media({
    width: ['100%', null, 'calc(50% - 0.5rem)', '33.33%'],
  })}

  &.cards-2 {
    ${Media({
      width: ['100%', null, 'calc(50% - 0.5rem)', '50%'],
    })}
  }

  &.cards-1 {
    ${Media({
      width: ['100%', null, 'calc(50% - 0.5rem)', '100%'],
    })}
  }

  > .vertical {
    max-width: none;
    height: 100%;

    ${Media({
      boxShadow: ['0 2px 20px 0 rgba(0, 0, 0, 0.1)', null, null, 'rgb(222, 222, 222) -1px 0px 0px 0px inset'],
    })}
  }

  &:last-child {
    .vertical {
      max-width: none;
      height: 100%;

      ${Media({
        boxShadow: ['0 2px 20px 0 rgba(0, 0, 0, 0.1)', null, null, 'none'],
      })}
    }
  }

  &:nth-child(odd) {
    ${Media({
      marginRight: [null, null, '1rem', '0px'],
    })}
  }
`;

export const StyledVerticalCardsDesktop = styled.div`
  ${Media({
    display: ['none', 'none', 'none', 'block'],
  })}
`;

export const StyledVerticalCardsMobile = styled.div`
  ${Media({
    display: ['block', 'block', 'block', 'none'],
  })}
`;
