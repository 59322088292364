/* eslint-disable react/require-default-props */
import React from 'react';
import { useIntl } from 'react-intl';
import { BoxProps } from 'rebass/styled-components';

import Button from '../../Button';
import Cta from '../../Cta';

import AdviceCard, { AdviceCardProps } from './AdviceCard';
import {
  StyledCardsWrapper,
  StyledContainer,
  StyledHeadingAndDescriptionWrapper,
  StyledLoadMoreWrapper,
  StyledModuleDescription,
  StyledModuleHeading,
} from './Styled';

interface AdviceListingModuleProps extends BoxProps {
  title: string;
  shortDescription?: string | null;
  advice: AdviceCardProps[];
  initialAmount?: number;
  incrementAmount?: number;
}

export const AdviceListingModule = React.forwardRef(
  (
    { title, shortDescription, advice, initialAmount = 3, incrementAmount = 8, ...rest }: AdviceListingModuleProps,
    ref,
  ) => {
    const intl = useIntl();
    const [amountOfAdviceItemsToDisplay, setDisplayLimit] = React.useState(initialAmount);

    return (
      <StyledContainer ref={ref} {...(rest as any)} data-appsearch-ignore="true">
        <StyledHeadingAndDescriptionWrapper>
          <StyledModuleHeading>{title}</StyledModuleHeading>
          {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
        </StyledHeadingAndDescriptionWrapper>
        <StyledCardsWrapper>
          {advice
            .map(({ slug, ...adviceRest }: AdviceCardProps) => <AdviceCard key={slug} slug={slug} {...adviceRest} />)
            .slice(0, amountOfAdviceItemsToDisplay)}
        </StyledCardsWrapper>
        {advice.length > amountOfAdviceItemsToDisplay ? (
          <StyledLoadMoreWrapper>
            <Cta>
              <Button
                onClick={event => {
                  event.preventDefault();
                  setDisplayLimit(amountOfAdviceItemsToDisplay + incrementAmount);
                }}
              >
                {intl.formatMessage({ id: 'sections.advice.loadmore' })}
              </Button>
            </Cta>
          </StyledLoadMoreWrapper>
        ) : null}
      </StyledContainer>
    );
  },
);
AdviceListingModule.displayName = 'AdviceListingModule';

export default AdviceListingModule;
