import { getSrc } from 'gatsby-plugin-image';

import {
  ContentfulCategory,
  ContentfulCategoryConnection,
  ContentfulCategoryEdge,
  ContentfulCategoryListingSection,
} from '../../generated/gatsby.types';
import extractCta from '../Cta';
import extractShortDescription from '../ShortDescription';

const extractCategories = (categories: Array<ContentfulCategoryEdge>) => {
  return categories?.map((categoryEdge: ContentfulCategoryEdge) => {
    const categoryItem: ContentfulCategory = categoryEdge.node;
    const link = extractCta(categoryItem);
    return {
      id: categoryItem.id,
      image: getSrc(categoryItem.image?.asset?.gatsbyImageData),
      link: link ? `${link.to}?category=${categoryItem.slug}` : null,
      shortDescription: extractShortDescription(categoryItem),
      title: categoryItem.title,
    };
  });
};

const sortCategories = ({ extractedCategories, categoryOrder }) => {
  const sortedCategories = [];

  categoryOrder.forEach(({ id }) => {
    const categoryIndex = extractedCategories.findIndex(category => category.id === id);

    if (categoryIndex !== -1) {
      const [category] = extractedCategories.splice(categoryIndex, 1);
      sortedCategories.push(category);
    }
  });

  sortedCategories.push(...extractedCategories);

  return sortedCategories;
};

const transformCategoryListingSection = (
  item: ContentfulCategoryListingSection,
  categories: ContentfulCategoryConnection,
) => {
  const categoryOrder = item.categoryOrder || [];
  const extractedCategories =
    categories.edges && Array.isArray(categories.edges) ? extractCategories(categories.edges) : [];

  return {
    __typename: 'CategoryListingSection',
    categories: sortCategories({ categoryOrder, extractedCategories }),
    key: item.id,
    shortDescription: extractShortDescription(item),
    title: item.title,
  };
};

export default transformCategoryListingSection;
