import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import {
  ContentfulCategoryContentfulSubcategoryUnion,
  ContentfulHeroBanner,
  ContentfulImageCard,
  ContentfulImageCardLink,
  ContentfulImageCardShort,
  ContentfulListingManual,
} from '../../__generated__/graphql-types';
import extractCta from '../Cta';

const CAROUSEL_TYPES = {
  HERO: 'Hero',
  IMAGE_CARD: 'Image Card',
  LOGO_CARD: 'Logo Card',
} as const;

type ContentEntries = (
  | ContentfulImageCard
  | ContentfulHeroBanner
  | ContentfulImageCardLink
  | ContentfulImageCardShort
)[];

type ListingManual = Omit<ContentfulListingManual, 'type' | 'contentEntries'> & {
  carouselType: 'Image Card' | 'Hero' | 'Logo Card';
  contentEntries: ContentEntries;
};

const isImageCardLink = (entries: ContentEntries): entries is ContentfulImageCardLink[] => {
  return entries?.map(entry => (entry as ContentfulImageCardLink).backgroundColour === 'Default').includes(true);
};
const isHeroBanner = (entries: ContentEntries): entries is ContentfulHeroBanner[] => {
  return entries?.map(entry => (entry as ContentfulHeroBanner).video === undefined).includes(true);
};
const isImageCard = (entries: ContentEntries): entries is ContentfulImageCard[] => {
  return entries?.map(entry => (entry as ContentfulImageCard).cardLink === undefined).includes(true);
};
const isImageCardShort = (entries: ContentEntries): entries is ContentfulImageCardShort[] => {
  return entries?.map(entry => (entry as ContentfulImageCardShort).article_detail_page !== undefined).includes(true);
};

const getArticlesFromCategory = (
  category: ContentfulCategoryContentfulSubcategoryUnion,
  uniqueArticlesIds: string[],
) => {
  return (category?.article_detail_page ?? [])?.reduce((acc, article) => {
    if (uniqueArticlesIds.includes(article.id)) {
      return acc;
    }

    uniqueArticlesIds.push(article.id);

    return [
      ...acc,
      {
        ctaLabel: 'Lees meer',
        description: article?.imageCard?.shortDescription?.shortDescription,
        image: article?.imageCard?.image,
        key: article?.id,
        link: article?.slug,
        title: article?.imageCard?.title,
      },
    ];
  }, []);
};

const getImageCardEntries = (entries: (ContentfulImageCard | ContentfulImageCardShort)[], locale: string) => {
  if (isImageCardShort(entries)) {
    return transformImageCardShortEntries(entries, locale);
  }

  if (isImageCard(entries)) {
    return transformNewsCardEntries(entries);
  }

  return [];
};

const transformImageCardShortEntries = (contentEntries: ContentfulImageCardShort[], locale: string) => {
  return (
    contentEntries?.map(entry => {
      return {
        ctaLabel: 'Lees meer',
        description: entry.shortDescription?.shortDescription,
        image: entry.image,
        key: entry.id,
        link: entry?.article_detail_page ? `/${locale}/${entry.article_detail_page[0]?.slug}` : '/',
        title: entry.title,
      };
    }) ?? []
  );
};

const transformCarouselEntries = (entry: ListingManual, locale: string) => {
  const uniqueArticlesIds: string[] = [];

  if (entry.categories?.length) {
    const articleDetailPages = entry.categories?.reduce((acc, category) => {
      const categoryEntries = getArticlesFromCategory(category, uniqueArticlesIds);

      const subCategoryEntries =
        category?.contentfulchildren?.reduce((acc, subCategory) => {
          const subCategoryEntries = getArticlesFromCategory(subCategory, uniqueArticlesIds);

          return [...acc, ...subCategoryEntries];
        }, []) ?? [];

      return [...acc, ...categoryEntries, ...subCategoryEntries];
    }, []);

    return articleDetailPages ?? [];
  }

  switch (entry.carouselType) {
    case CAROUSEL_TYPES.HERO:
      return isHeroBanner(entry.contentEntries) ? transformHeroBannerEntries(entry.contentEntries) : [];
    case CAROUSEL_TYPES.IMAGE_CARD:
      return getImageCardEntries(entry.contentEntries, locale) ?? [];
    case CAROUSEL_TYPES.LOGO_CARD:
      return isImageCardLink(entry.contentEntries) ? transformLogoCardEntries(entry.contentEntries) : [];
    default:
      return [];
  }
};

const transformNewsCardEntries = (contentEntries: ContentfulImageCard[]) => {
  return (
    contentEntries?.map(entry => {
      return {
        ctaLabel: entry.callToActionLabel,
        description: entry.shortDescription?.shortDescription,
        image: entry.image,
        key: entry.id,
        // change when pages are ready
        link: '/',
        title: entry.title,
      };
    }) ?? []
  );
};

const transformHeroBannerEntries = (contentEntries: ContentfulHeroBanner[]) => {
  return (
    contentEntries?.map(entry => {
      return {
        cta: extractCta(entry),
        ctaStyle: entry.callToActionStyle,
        description: entry?.description?.raw ? documentToPlainTextString(JSON.parse(entry.description.raw)) : '',
        image: entry.image,
        key: entry.id,
        secondCta: extractCta({ cta: entry.callToActionSecond }),
        secondCtaStyle: entry.callToActionSecondStyle,
        title: entry.title,
      };
    }) ?? []
  );
};

const transformLogoCardEntries = (contentEntries: ContentfulImageCardLink[]) => {
  return (
    contentEntries?.map(entry => {
      return {
        cta: extractCta({ cta: entry.cardLink }),
        image: entry.image,
        key: entry.id,
        title: entry.title,
      };
    }) ?? []
  );
};

const transformListingManual = (item: ListingManual, locale: string) => {
  return {
    __typename: 'ListingManual',
    carouselType: item.carouselType,
    contentEntries: transformCarouselEntries(item, locale),
    key: item.id,
    title: item.title,
  };
};

export default transformListingManual;
