/* eslint-disable react/require-default-props */
import React from 'react';

import DocumentDownload, { DocumentDownloadProps } from '../../DocumentDownload';
import { CategoriesModule, HeroSectionModule, InfoSectionModule, ProductCardModule } from '../../Modules';
import { CategoriesModuleProps } from '../../Modules/CategoriesModule';
import { ProductCardModuleProps } from '../../Modules/ProductCardModule';
import RichText, { RichTextProps } from '../../RichText';

import {
  DocumentDownloadContainer,
  StyledCenteredText,
  StyledContainer,
  StyledHeaderContainer,
  StyledModuleContainer,
} from './Styled';

interface AdvicePageProps {
  title: string;
  shortDescription: string;
  date?: string;
  contentsSection: RichTextProps;
  image: any;
  featuredProducts?: ProductCardModuleProps | null;
  categoriesSection?: CategoriesModuleProps | null;
  documentDownload?: DocumentDownloadProps;
}

const AdvicePage = ({
  title,
  shortDescription,
  contentsSection,
  image,
  featuredProducts,
  categoriesSection,
  documentDownload,
}: AdvicePageProps) => {
  return (
    <>
      <StyledContainer>
        <StyledHeaderContainer>
          <HeroSectionModule title={title} shortDescription={shortDescription} />
        </StyledHeaderContainer>
      </StyledContainer>
      <InfoSectionModule image={image} />
      <StyledCenteredText>
        <RichText {...contentsSection} />
        {documentDownload && (
          <DocumentDownloadContainer>
            <DocumentDownload
              documentTitle={documentDownload.documentTitle}
              documentType={documentDownload.documentType}
              downloadLink={documentDownload.downloadLink}
              fileSize={documentDownload.fileSize}
              showDocumentInfo
            />
          </DocumentDownloadContainer>
        )}
      </StyledCenteredText>
      <StyledModuleContainer data-appsearch-ignore="true">
        {featuredProducts && <ProductCardModule {...featuredProducts} />}
        {categoriesSection && <CategoriesModule variant="secondary" {...categoriesSection} />}
      </StyledModuleContainer>
    </>
  );
};

export default AdvicePage;
