import * as React from 'react';
import { useIntl } from 'react-intl';
import { BoxProps } from 'rebass';

//
import { LocaleType } from '../../../i18n/LocaleType';
import { Close as CloseIcon } from '../../../icons';
import { LangSwitcher } from '../LangSwitcher';
import SecondaryNavigationMobileItem from '../SecondaryNavigationMobileItem';
import { NavItemType } from '../types';

import {
  StyledCloseButton,
  StyledMobileLanguageSelect,
  StyledSecondaryNavigationMobile,
  StyledSelectText,
} from './Styled';

type SubnavItem = NavItemType & {
  open: boolean;
};

interface Props extends BoxProps {
  items: Array<NavItemType>;
  activeMenuUrl: string;
  onClose: () => void;
  defaultLocale: LocaleType;
  activeLocales: Array<string>;
}

const addOpenPropertyToItems = (items: Array<NavItemType>, activeMenuUrl: string): Array<SubnavItem> =>
  items.map((item: NavItemType) => ({
    ...item,
    open: activeMenuUrl === item.to,
  }));

const updateOpenStateOnMenu = (clickedItem: SubnavItem, items: Array<SubnavItem>): Array<SubnavItem> =>
  items.map((item: SubnavItem) => {
    if (clickedItem.to === item.to && item.open === true) {
      return {
        ...item,
        open: false,
      };
    }
    return {
      ...item,
      open: clickedItem.to === item.to,
    };
  });

const SecondaryNavigationMobile = ({ items, activeMenuUrl, onClose, defaultLocale, activeLocales }: Props) => {
  const intl = useIntl();
  const [menuItems, updateMenuItems] = React.useState(addOpenPropertyToItems(items, activeMenuUrl));

  return (
    <>
      <StyledCloseButton onClick={() => onClose()}>
        <span>
          <CloseIcon />
        </span>
      </StyledCloseButton>
      <StyledSecondaryNavigationMobile>
        {menuItems.map((item: SubnavItem) => {
          return (
            <SecondaryNavigationMobileItem
              key={item.to}
              item={item}
              open={item.open}
              activeMenuUrl={activeMenuUrl}
              onClose={() => onClose()}
              onClickItem={clickedItem => {
                updateMenuItems(updateOpenStateOnMenu(clickedItem as SubnavItem, menuItems));
              }}
            />
          );
        })}
      </StyledSecondaryNavigationMobile>
      {activeLocales.length > 1 && (
        <StyledMobileLanguageSelect>
          <StyledSelectText>{intl.formatMessage({ id: 'header.selectlanguage' })}</StyledSelectText>
          <LangSwitcher defaultLocale={defaultLocale.id} activeLocales={activeLocales} openUpwards />
        </StyledMobileLanguageSelect>
      )}
    </>
  );
};

export default SecondaryNavigationMobile;
