/* eslint-disable no-underscore-dangle */
import extractShortDescription from '../ShortDescription';

const getImage = (item: any) => {
  if (item.media?.__typename === 'ContentfulImage') {
    const {
      title,
      alt,
      asset: { landscapeImage, portraitImage, squareImage },
    } = item.media;

    if (item.orientation === 'Portrait') {
      return { alt, asset: { portraitImage }, title };
    }

    if (item.orientation === 'Square') {
      return { alt, asset: { squareImage }, title };
    }

    // Default to landscape
    return { alt, asset: { landscapeImage }, title };
  }

  return null;
};

const transformMedia = (item: any) => {
  const image = getImage(item);

  return {
    __typename: 'Media',
    caption: item.caption?.text,
    embedlyContent: item?.embedlyContent?.embedlyContent,
    halfWidthImage: image && (item.orientation === 'Portrait' || item.orientation === 'Square'),
    image,
    key: item.id,
    shortDescription: extractShortDescription(item),
    title: item.title,
    videoUrl: item.media?.videoUrl,
  };
};

export default transformMedia;
