import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../utils/Media';
//

export const StyledDocumentDownload = styled(Flex)`
  background-color: white;
  display: flex;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  padding: 24px;

  ${Media({
    flexWrap: ['wrap', 'nowrap'],
  })}
`;

export const StyledDocumentDownloadMobile = styled(Flex)`
  background-color: white;
  display: flex;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  padding: 24px;
  flex-wrap: wrap;
`;

export const StyledDownloadButtonText = styled(Text)`
  margin-right: 20px;
  font-size: 1rem;
  font-weight: ${props => props.theme.documentDownload.fontWeight || 'normal'};
  ${Media({
    marginLeft: ['0px', '1rem'],
  })}
`;

export const StyledDownloadButtonTextMobile = styled(Text)`
  margin-right: 20px;
  font-size: 1rem;
`;

export const StyledDownloadIcon = styled(Box)`
  height: 32px;
  width: 32px;
  border: 1px solid ${props => props.theme.documentDownload.arrowColor};
  background-color: ${props => props.theme.documentDownload.arrowColor};
  color: white;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transform: rotate(90deg);
  }

  svg > * {
    fill: white;
  }
`;

export const StyledDownloadButton = styled.a`
  display: flex;
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.theme.documentDownload.color};
  display: flex;
  justify-content: center;
  align-items: center;

  ${Media({
    justifyContent: ['left', 'center'],
    marginTop: ['1rem', '0px'],
    width: ['100%', 'auto'],
  })}

  &:hover {
    color: ${props => props.theme.documentDownload.hoverColor};

    ${StyledDownloadIcon} {
      background-color: transparent;
      border-color: ${props => props.theme.documentDownload.arrowHoverColor};
    }

    svg > * {
      fill: ${props => props.theme.documentDownload.arrowHoverColor};
    }
  }
`;

export const StyledDownloadButtonMobile = styled.a`
  display: flex;
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.theme.documentDownload.color};
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
  justifycontent: left;

  &:hover {
    color: ${props => props.theme.documentDownload.hoverColor};

    ${StyledDownloadIcon} {
      background-color: transparent;
      border-color: ${props => props.theme.documentDownload.arrowHoverColor};
    }

    svg > * {
      fill: ${props => props.theme.documentDownload.arrowHoverColor};
    }
  }
`;

export const StyledFileExtentionIcon = styled(Box)`
  height: 24px;
  width: 24px;
  margin-right: 20px;

  ${Media({
    display: ['none', 'none', 'block'],
  })}
`;

export const StyledDocumentInfo = styled(Box)`
  display: flex;
  align-items: center;

  ${Media({
    textAlign: ['center', 'initial'],
    width: ['100%', 'auto'],
  })}
`;

export const StyledDocumentInfoMobile = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  textalign: center;
`;

export const StyledDocumentTitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: left;
  flex: 1;
  align-items: flex-start;
`;

export const StyledDocumentTitle = styled(Text)`
  font-size: 1rem;
  text-align: left;
  color: ${props => props.theme.colors.text};
  font-weight: ${props => props.theme.fontWeights.headingAlt};
`;

export const StyledDocumentFileSize = styled(Box)`
  font-size: 11px;
  color: ${props => props.theme.colors.text};
  text-transform: uppercase;
  ${Media({
    display: ['none', 'none', 'block'],
  })}
`;
