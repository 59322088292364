import { ContentfulAttachment, Maybe } from '../generated/gatsby.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractFileInforamtion = (file: Maybe<ContentfulAttachment> | null | undefined): any => {
  if (file && file.asset && file.asset.file) {
    const fileSize =
      file.asset.file.details && file.asset.file.details.size
        ? (file.asset.file.details.size / 2 ** 20).toString().substring(0, 4)
        : null;

    return {
      ...file,
      documentTitle: file.title,
      documentType: file.asset.file?.contentType === 'application/pdf' ? 'pdf' : null,
      downloadLink: file.asset.file.url,
      fileSize: fileSize ? `${fileSize}MB` : null,
      showDocumentInfo: true,
    };
  }

  return null;
};

export default extractFileInforamtion;
