import React from 'react';

import { StyledModuleContainer } from '../../Layout/Styled';
import Media from '../../Media';

export const MediaModule = ({ ...rest }) => (
  <StyledModuleContainer smallTopSpacer>
    <Media {...(rest as any)} />
  </StyledModuleContainer>
);

export default MediaModule;
