import { Button as RebassButton } from 'rebass/styled-components';
import styled from 'styled-components';
//

type RebassButtonProps = {
  /** The button variant as per theme definition */
  variant: string;
};

const StyledButton = styled(RebassButton)<RebassButtonProps>`
  ${props => ({ ...props.theme.buttons[props.variant] })};
  height: auto;
`;

export default StyledButton;
