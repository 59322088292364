import * as React from 'react';
import { FlexProps } from 'rebass';

import RichText from '../RichText';

import LegalFooter from './LegalFooter';
import MainNavigation from './MainNavigation';
import SocialIcons from './SocialIcons';
import StyledFooter, {
  StyledContactWrapper,
  StyledFooterContactSection,
  StyledFooterMainNavigation,
  StyledFooterTopSection,
  StyledLegalWrapper,
} from './Styled';
import SubscribeForm from './SubscribeForm';

type link = {
  to: string;
  label: string;
};

type SubscribeForm = {
  showFooterSubscribeForm: boolean;
  showFooterSubscribeFormCountryField: boolean;
  subscribeFormHeading?: any | null;
};

interface Props extends FlexProps {
  legalLinks: Array<link>;
  contacts: any;
  footerNavigation: Array<link>;
  socialLinks: Array<link>;
  onSubmitSubscribe: (email: string, country: string, locale: string) => void;
  pageLocale: string;
  subscribeForm: SubscribeForm;
  banner?: any;
}

const Footer = ({
  legalLinks,
  contacts,
  footerNavigation,
  socialLinks,
  onSubmitSubscribe,
  pageLocale,
  subscribeForm,
  banner,
}: Props) => {
  const { showFooterSubscribeForm: showSubscribeForm, showFooterSubscribeFormCountryField: showCountrySelector } =
    subscribeForm || {};

  return (
    <StyledFooter>
      <StyledFooterTopSection>
        <StyledContactWrapper>
          <RichText data={contacts.json} />
        </StyledContactWrapper>
        <StyledFooterMainNavigation>
          <MainNavigation navigation={footerNavigation} />
          <SocialIcons socialLinks={socialLinks} />
        </StyledFooterMainNavigation>
        <StyledFooterContactSection>
          {showSubscribeForm && (
            <SubscribeForm
              onSubmit={(email: string, country: string) => onSubmitSubscribe(email, country, pageLocale)}
              showCountrySelector={showCountrySelector}
            />
          )}
        </StyledFooterContactSection>
      </StyledFooterTopSection>
      <StyledLegalWrapper>
        <LegalFooter items={legalLinks} banner={banner} />
      </StyledLegalWrapper>
    </StyledFooter>
  );
};

export default Footer;
