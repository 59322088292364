/* eslint-disable import/prefer-default-export */
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';
import Cta from '../../Cta';

export const StyledCard = styled(Flex)`
  width: 100%;
  max-width: 441px;
  background-color: white;
  flex-direction: column;
`;

export const StyledImageBox = styled(Box)`
  width: 100%;
  display: block;
  background-size: cover;
  background-color: ${props => props.theme.colors.muted};
`;

export const StyledImageBoxLink = styled(Cta)`
  text-decoration: none;
  position: relative;
  display: block;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc((311 / 441) * 100%);
  }

  > ${StyledImageBox} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export const StyledContentBox = styled(Flex)`
  flex-direction: column;
  flex: 1;

  ${Media({
    padding: ['1.5rem 0', null, '2rem 0'],
  })}
`;

export const StyledTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;

  a {
    color: ${props => props.theme.colors.darkGray};
    text-decoration: none;
  }
`;

export const StyledDescription = styled(Text)`
  font-size: 1rem;
  line-height: 1.75;
  color: ${props => props.theme.colors.darkGray};
  white-space: pre-wrap;

  h3 + & {
    ${Media({
      marginTop: ['0.5rem', null, '1.5625rem'],
    })}
  }
`;

export const StyledCtaWrapper = styled.div`
  padding-bottom: 1.4375rem;
  padding-top: 1.4375rem;
  margin-top: 0;

  ${Media({
    marginBottom: ['-1.5rem', null, '-2rem'],
    marginLeft: ['-1.5rem', null, '-2rem'],
    marginRight: ['-1.5rem', null, '-2rem'],
    paddingLeft: ['1.5rem', null, '2rem'],
    paddingRight: ['1.5rem', null, '2rem'],
  })}
`;

export const StyledCta = styled(Cta)`
  color: ${props => props.theme.cardCtas.color};
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
  text-transform: ${props => props.theme.cardCtas.textTransform};
  letter-spacing: ${props => props.theme.cardCtas.letterSpacing};
  font-size: ${props => props.theme.cardCtas.fontSize};
  line-height: ${props => props.theme.cardCtas.lineHeight};

  > span {
    vertical-align: middle;
  }

  > svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.75rem;
    transform: translateX(-4px);
    fill: ${props => props.theme.cardCtas.arrowColor};

    > * {
      fill: ${props => props.theme.cardCtas.arrowColor};
    }
  }

  &:hover {
    color: ${props => props.theme.cardCtas.hoverColor};

    > svg {
      fill: ${props => props.theme.cardCtas.arrowHoverColor};

      > * {
        fill: ${props => props.theme.cardCtas.arrowHoverColor};
      }
    }
  }
`;
