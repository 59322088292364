import querystring from 'query-string';

import transformAdviceListingSection from '../transforms/BasicPage/AdviceListingSection';
import transformAdvicesSection from '../transforms/BasicPage/AdvicesSection';
import transformArticleListingSection from '../transforms/BasicPage/ArticleListingSection';
import transformCategoryListingSection from '../transforms/BasicPage/CategoryListing';
import transformColumnComponent from '../transforms/BasicPage/ColumnComponent';
import transformContactsSection from '../transforms/BasicPage/ContactsSection';
import transformCtaGroup from '../transforms/BasicPage/CtaGroup';
import transformCtaSection from '../transforms/BasicPage/CtaSection';
import transformCustomBlock from '../transforms/BasicPage/CustomBlock';
import transformCustomBlocksSection from '../transforms/BasicPage/CustomBlocks';
import transformDocument from '../transforms/BasicPage/Document';
import transformEmbeddedContent from '../transforms/BasicPage/EmbeddedContent';
import transformEventsListingSection from '../transforms/BasicPage/EventsListing';
import transformFeaturesSection from '../transforms/BasicPage/FeaturesSection';
import transformFindADealerSection from '../transforms/BasicPage/FindADealerSection';
import transformHeroBanner from '../transforms/BasicPage/HeroBanner';
import tranformHeroSection from '../transforms/BasicPage/heroSection';
import transformImage from '../transforms/BasicPage/Image';
import transformImageAndText from '../transforms/BasicPage/ImageAndText';
import transformImageCard from '../transforms/BasicPage/ImageCard';
import transformInfoSection from '../transforms/BasicPage/InfoSection';
import transformLatestNewsSection from '../transforms/BasicPage/LatestNews';
import transformListingManual from '../transforms/BasicPage/ListingManual';
import transformMedia from '../transforms/BasicPage/Media';
import transformNewsLetter from '../transforms/BasicPage/NewsLetterSubscription';
import transformPastEventsSection from '../transforms/BasicPage/PastEvents';
import transformProductListingSection from '../transforms/BasicPage/ProductListing';
import transformProductListingTableSection from '../transforms/BasicPage/ProductListingTable';
import transformTextSection from '../transforms/BasicPage/RichText';
import transformSearchResultsSection from '../transforms/BasicPage/SearchResults';
import transformShortBanner from '../transforms/BasicPage/ShortBanner';
import transformSubcategoryListingSection from '../transforms/BasicPage/SubcategoryListing';
import transformTable from '../transforms/BasicPage/Table';
import transformVideo from '../transforms/BasicPage/Video';
import transformNewsListingSection from '../transforms/NewsListing';
import transformProductCategoriesSection from '../transforms/ProductCategories';
import transformProductsServicesSection from '../transforms/ProductsServices';
import transformUpcomingEventsSection from '../transforms/UpcomingEvents';

export const getContentsSectionCollection = (
  modules: any,
  data: any,
  location: any,
  pageContext: any,
  title?: string,
) => {
  return {
    items: modules
      .map(module => {
        if (!module) {
          return null;
        }

        if (module.type === 'features-section') {
          return transformerMap['features-section'](module);
        }
        if (module.type === 'cta-group') {
          return transformerMap['cta-group'](module);
        }
        if (module.type === 'upcoming-events-section') {
          return transformerMap['upcoming-events-section'](module, data.allContentfulEvent);
        }
        if (module.type === 'events-listing-section') {
          return transformerMap['events-listing-section'](module, data.allContentfulEvent);
        }
        if (module.type === 'past-events-section') {
          return transformerMap['past-events-section'](module, data.allContentfulEvent);
        }
        if (module.type === 'latest-news-section') {
          return transformerMap['latest-news-section'](module, data.allContentfulNews);
        }
        if (module.type === 'find-a-dealer-section') {
          return transformerMap['find-a-dealer-section'](module, data.allContentfulDealer);
        }
        if (module.type === 'search-results-section') {
          return transformerMap['search-results-section'](
            module,
            location.search ? querystring.parse(location.search) : {},
            data.contentfulSettings,
          );
        }
        if (module.type === 'advice-listing-section') {
          return transformerMap['advice-listing-section'](module, data.allContentfulAdvice);
        }
        if (module.type === 'news-listing-section') {
          return transformerMap['news-listing-section'](module, data.allContentfulNews);
        }
        if (module.type === 'product-listing-section') {
          return transformerMap['product-listing-section'](
            module,
            data.allContentfulProduct,
            location.search ? querystring.parse(location.search) : {},
          );
        }
        if (module.type === 'category-listing-section') {
          return transformerMap['category-listing-section'](module, data.allContentfulCategory);
        }
        if (module.type === 'contacts-section') {
          return transformerMap['contacts-section'](
            module,
            data.contentfulSettings.contactsShowSubscribeForm,
            pageContext.node_locale,
            title,
          );
        }
        if (module.type === 'text-section') {
          return transformerMap['text-section'](module, pageContext.node_locale);
        }
        if (module.type === 'info-section') {
          return transformerMap['info-section'](module, pageContext.node_locale);
        }
        if (module.type === 'news-letter-subscription') {
          return transformerMap['news-letter-subscription'](module, data.allContentfulProduct);
        }
        if (module.type === 'custom-block') {
          return transformerMap['custom-block'](module, pageContext.node_locale);
        }
        if (module.type === 'listing-manual') {
          return transformerMap['listing-manual'](module, pageContext.node_locale);
        }
        if (module.type === 'hero-banner') {
          return transformerMap['hero-banner'](module, pageContext.node_locale);
        }
        if (module.type === 'columns-component') {
          return transformerMap['columns-component'](module, pageContext.node_locale);
        }
        if (module.type === 'video') {
          return transformerMap['video'](module);
        }
        if (module.type === 'article-listing-section') {
          return transformerMap['article-listing-section'](module);
        }
        if (module.type === 'image') {
          return transformerMap['image'](module);
        }

        // Leave this at the bottom!
        if (transformerMap[module.type]) {
          return transformerMap[module.type](module);
        }

        return null;
      })
      .filter(Boolean),
  };
};

export const transformerMap: any = {
  'advice-listing-section': transformAdviceListingSection,
  'advice-section': transformAdvicesSection,
  'article-listing-section': transformArticleListingSection,
  'category-listing-section': transformCategoryListingSection,
  'columns-component': transformColumnComponent,
  'contacts-section': transformContactsSection,
  'cta-banner': transformCtaSection,
  'cta-group': transformCtaGroup,
  'cta-section': transformCtaSection,
  'custom-block': transformCustomBlock,
  'custom-blocks-section': transformCustomBlocksSection,
  document: transformDocument,
  'embedded-content': transformEmbeddedContent,
  'events-listing-section': transformEventsListingSection,
  'features-section': transformFeaturesSection,
  'find-a-dealer-section': transformFindADealerSection,
  'hero-banner': transformHeroBanner,
  'hero-section': tranformHeroSection,
  image: transformImage,
  'image-and-text': transformImageAndText,
  'image-card': transformImageCard,
  'info-section': transformInfoSection,
  'latest-news-section': transformLatestNewsSection,
  'listing-manual': transformListingManual,
  media: transformMedia,
  'news-letter-subscription': transformNewsLetter,
  'news-listing-section': transformNewsListingSection,
  'past-events-section': transformPastEventsSection,
  'product-categories-section': transformProductCategoriesSection,
  'product-listing-section': transformProductListingSection,
  'products-listing-table-section': transformProductListingTableSection,
  'products-services-section': transformProductsServicesSection,
  'search-results-section': transformSearchResultsSection,
  'short-banner': transformShortBanner,
  'subcategory-listing-section': transformSubcategoryListingSection,
  table: transformTable,
  'text-section': transformTextSection,
  'upcoming-events-section': transformUpcomingEventsSection,
  video: transformVideo,
};
