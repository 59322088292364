import { Input } from '@rebass/forms';
import * as geolib from 'geolib';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, BoxProps } from 'rebass/styled-components';

import { Alert as AlertIcon } from '../../../icons';
import locate from '../../../utils/geolocation';
import Button from '../../Button';

import { DealerCategoriesProps, DealerProps } from './Dealer/Dealer';
import MultiSelectFilter from './MultiSelectFilter';
import {
  StyledButtonContainer,
  StyledCategoryTitle,
  StyledCategoryTitleWithIcon,
  StyledCloseIcon,
  StyledContainer,
  StyledDealer,
  StyledDealersContainer,
  StyledDealersHeader,
  StyledDealersSearch,
  StyledFailureMessage,
  StyledModuleDescription,
  StyledModuleHeading,
  StyledResultsLength,
  StyledSearchResultDescription,
} from './Styled';

interface FindADealerModuleProps extends BoxProps {
  /** title */
  title: string;
  /** optional module short description */
  shortDescription?: string;
  /** card cta */
  dealers: DealerProps[];
  categories?: DealerCategoriesProps[];
  filters?: [];
  filterLabel: string;
}
const sortDealers = (latitude: number, longitude: number, dealers: Array<DealerProps>) =>
  geolib.orderByDistance(
    {
      latitude,
      longitude,
    },
    dealers,
  );

const FindADealerModule = React.forwardRef(
  ({ title, shortDescription, dealers, categories, filterLabel, ...rest }: FindADealerModuleProps, ref) => {
    const inputRef = React.useRef();
    const intl = useIntl();

    const [allDealers] = useState(dealers);
    const dealersSortedAlphabetically = allDealers.sort((a, b) => a.title?.localeCompare(b.title));
    const [activeDealers, setActiveDealers] = React.useState(dealersSortedAlphabetically);
    const [activeLat, setActiveLat] = React.useState(0);
    const [activeLon, setActiveLon] = React.useState(0);
    const [clickedShowAll, setClickedShowAll] = React.useState(false);
    const [ranSearch, setRanSearch] = React.useState(false);
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);
    const [selected, setSelected] = useState([]);
    const [options, setOptions] = useState(
      (categories &&
        categories
          .filter((x: any) => x && x.length > 0)
          .map(cat => {
            return { checked: false, label: cat, value: cat };
          })) ||
        [],
    );

    const getFilteredDealers = () => {
      const selectedValues = selected.map((x: any) => x.value);
      let selectedData = [] as any;

      selectedValues.forEach(selectedValue => {
        const filteredByCategory = allDealers.filter((x: any) => x.filters && x.filters.includes(selectedValue));

        if (filteredByCategory.length > 0) {
          selectedData.push(...filteredByCategory);
        } else if (selectedData.length === 0) {
          selectedData = [];
        }
      });

      return selectedData.filter((value: any, index: any, self: any) => self.indexOf(value) === index);
    };

    React.useEffect(() => {
      if (selected.length && !ranSearch) {
        setActiveDealers(getFilteredDealers() as any);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (ranSearch) {
          // eslint-disable-next-line no-use-before-define
          handleSearch();
        } else {
          setActiveDealers(allDealers);
        }
      }
    }, [selected]);

    const onClickShowAllDealers = () => {
      setClickedShowAll(true);
      const filtered = selected.length ? (getFilteredDealers() as any) : allDealers;
      setActiveDealers(sortDealers(activeLat, activeLon, filtered) as Array<DealerProps>);
    };

    const showErrror = () => {
      setActiveDealers(dealersSortedAlphabetically);
      setClickedShowAll(true);
      setRanSearch(true);
      setShowErrorMessage(true);
    };

    const handleSearch = () => {
      setShowErrorMessage(false);
      setRanSearch(false);
      setClickedShowAll(false);
      if (inputRef.current) {
        const { value } = inputRef.current;

        if (!value && !activeDealers) {
          setActiveDealers(dealersSortedAlphabetically);
          setRanSearch(true);
          setClickedShowAll(true);
        }

        locate(value)
          .then((response: any) => {
            if (response.status === 'ZERO_RESULTS' || response.status === 'REQUEST_DENIED') {
              showErrror();
            }
            if (response.status === 'OK' && response.results[0]) {
              setActiveLat(response.results[0].geometry.location.lat());
              setActiveLon(response.results[0].geometry.location.lng());
              setRanSearch(true);
              const targetDealers = selected.length ? getFilteredDealers() : allDealers;
              setActiveDealers(
                sortDealers(
                  response.results[0].geometry.location.lat(),
                  response.results[0].geometry.location.lng(),
                  targetDealers,
                ).slice(0, 4) as Array<DealerProps>,
              );
            }
          })
          .catch(e => {
            // eslint-disable-next-line no-console
            console.error(e);
            showErrror();
          });
      }
    };

    const handleSelectedObjects = (data: any) => {
      const result =
        options &&
        options.map(x => {
          const item = x;
          if (x.value === data.value) {
            item.checked = data.checked;
          }

          return item;
        });

      setOptions(result);
      setSelected(data);
    };

    const removeSelectedCategory = (category: any) => {
      const tags = [] as any;
      selected.map((x: any) => {
        if (x.value !== category.value) {
          tags.push(x);
        }

        return tags;
      });

      const newOptions =
        options &&
        options.map((x: any) => {
          const item = x;
          if (x.value === category.value) {
            item.checked = false;
          }
          return item;
        });
      setOptions(newOptions);
      setSelected(tags);
    };

    return (
      <StyledContainer ref={ref} {...(rest as any)}>
        <StyledDealersHeader>
          <Box>
            <StyledModuleHeading>{title}</StyledModuleHeading>
            {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
          </Box>

          <StyledDealersSearch>
            <Input
              onKeyDown={e => e.key === 'Enter' && handleSearch()}
              ref={inputRef}
              name="search"
              placeholder={intl.formatMessage({ id: 'sections.dealer.placeholder' })}
            />
            {options && options.length > 0 && (
              <MultiSelectFilter selected={handleSelectedObjects} options={options} label={filterLabel} />
            )}
            <Button onClick={handleSearch}>{intl.formatMessage({ id: 'sections.dealer.search' })}</Button>
          </StyledDealersSearch>
          {showErrorMessage && (
            <StyledFailureMessage>
              <AlertIcon />
              <p>{intl.formatMessage({ id: 'sections.dealer.invalidaddress' })}</p>
            </StyledFailureMessage>
          )}
        </StyledDealersHeader>
        {selected.length > 0 && (
          <StyledSearchResultDescription>
            <StyledResultsLength> Showing {activeDealers.length} results for:</StyledResultsLength>
            {selected.map((category: any) => (
              <StyledCategoryTitleWithIcon key={category.value} onClick={() => removeSelectedCategory(category)}>
                <StyledCategoryTitle> {category.value}</StyledCategoryTitle>
                <StyledCloseIcon>
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                  </svg>
                </StyledCloseIcon>
              </StyledCategoryTitleWithIcon>
            ))}
          </StyledSearchResultDescription>
        )}
        {activeDealers.length > 0 && (
          <StyledDealersContainer>
            {activeDealers.map((dealer: DealerProps) => (
              <Box key={dealer.title}>
                <StyledDealer {...dealer} />
              </Box>
            ))}
          </StyledDealersContainer>
        )}
        {ranSearch && !clickedShowAll && activeDealers.length >= 4 ? (
          <StyledButtonContainer>
            <Button onClick={() => onClickShowAllDealers()}>
              {intl.formatMessage({ id: 'sections.dealer.loadmore' })}
            </Button>
          </StyledButtonContainer>
        ) : null}
      </StyledContainer>
    );
  },
);
FindADealerModule.displayName = 'FindADealerModule';

export default FindADealerModule;
