import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import React from 'react';

import { StyledContentWrapper, StyledDescription, StyledImageWrapper, StyledTitle, StyledWrapper } from './Styled';

interface BrandCard {
  id: string;
  title: string;
  shortDescription: string;
  logo: any;
  media: any;
  dispatch: (data: any) => void;
}

const BrandCard = ({ id, title, shortDescription, logo, dispatch }: BrandCard) => (
  <StyledWrapper onClick={() => dispatch({ selectedBrandId: id })}>
    {logo?.asset && (
      <StyledImageWrapper>
        <GatsbyImage
          image={getImage(logo.asset) as IGatsbyImageData}
          alt={logo.alt ? logo.alt : logo.title}
          objectFit="contain"
          style={{ margin: '0 auto', maxWidth: '150px' }}
        />
      </StyledImageWrapper>
    )}
    <StyledContentWrapper>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{shortDescription}</StyledDescription>
    </StyledContentWrapper>
  </StyledWrapper>
);

export default BrandCard;
