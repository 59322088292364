import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

//
import Media from '../../../utils/Media';

export const StyledModuleHeading = styled.h1`
  color: ${props => props.theme.colors.text};
  font-weight: ${props => props.theme.upComingEvents.titleFontWeight || 'normal'};
  word-break: break-word;

  ${Media({
    fontSize: ['40px', null, null, '3.5rem'],
    marginBottom: ['1rem', null, null, '1.5rem'],
  })}
`;

export const StyledModuleDescription = styled(Text)`
  color: ${props => props.theme.colors.text};
  line-height: 1.875rem;
  font-size: 1.25rem;

  ${Media({
    marginBottom: ['3.0625rem', null, null, '3.25rem'],
  })}
`;

export const StyledHeadingAndDescriptionWrapper = styled(Box)`
  max-width: 605px;
  margin: 0 auto;
`;

export const StyledCardContainer = styled(Flex)`
  align-items: top;
  justify-content: left;
  width: 100%;

  &.cards-2 {
    ${Media({
      width: ['100%', null, null, '66.66%'],
    })}
  }

  &.cards-1 {
    ${Media({
      width: ['100%', null, null, '33.33%'],
    })}
  }

  ${Media({
    boxShadow: [null, null, null, '0 2px 20px 0 rgba(0, 0, 0, 0.1)'],
    flexWrap: ['wrap', null, null, 'nowrap'],
    position: ['relative', null, null, 'static'],
  })}

  > *:not(:last-child) {
    > div {
      background: transparent;
    }

    ${Media({
      boxShadow: [null, null, null, 'inset -1px 0px 0px 0px #dedede'],
      // marginBottom: ['1rem', null, null, '0'],
    })}
  }
`;

export const StyledEventCardWrapper = styled(Box)`
  ${Media({
    marginBottom: ['1rem', null, null, '0'],
    width: ['100%', null, 'calc(50% - 0.5rem)', '33.33%'],
  })}

  &.cards-2 {
    ${Media({
      width: ['100%', null, 'calc(50% - 0.5rem)', '50%'],
    })}
  }

  &.cards-1 {
    ${Media({
      width: ['100%', null, 'calc(50% - 0.5rem)', '100%'],
    })}
  }

  &:nth-child(odd) {
    ${Media({
      marginRight: [null, null, '1rem', '0px'],
    })}
  }

  > .vertical {
    height: 100%;
  }

  > .vertical,
  > .vertical div {
    max-width: none;

    ${Media({
      boxShadow: [null, null, null, 'none'],
    })}
  }
`;

export const StyledHorizontalEventCardWrapper = styled(Flex)`
  ${Media({
    marginBottom: ['1rem', null, null, '0'],
    width: ['100%', null, 'calc(50% - 0.5rem)', '100%'],
  })}

  &:nth-child(odd) {
    ${Media({
      marginRight: [null, null, '1rem', '0px'],
    })}
  }

  > .horizontal,
  > .horizontal div {
    max-width: none;
  }

  > .horizontal {
    ${Media({
      marginTop: [null, null, null, '3.5rem'],
    })}
  }
`;

export const StyledHorizontalEventsCardWrapper = styled(Flex)`
  flex-wrap: wrap;
  width: 100%;
`;
