/* eslint-disable react/require-default-props */
import React from 'react';
import { BoxProps } from 'rebass/styled-components';

import Button from '../../Button';
import DocumentDownload from '../../DocumentDownload';
import { ProductType } from '../../Product/ProductBasicTable/types';
import { DocumentDownloadProps } from '../types';

import {
  StyledContainer,
  StyledContentContainer,
  StyledCta,
  StyledDocumentDownloadWrapper,
  StyledHeadingAndDescriptionContainer,
  StyledHeadingAndDescriptionWrapper,
  StyledModuleDescription,
  StyledModuleHeading,
  StyledProductBasicTable,
  StyledSidebar,
  StyledSidebarDescription,
  StyledSidebarHeading,
} from './Styled';

type CtaProps = {
  to: string;
  label: string;
};

interface ProductListingTableModuleProps extends BoxProps {
  title: string;
  shortDescription?: string | null;
  tableRows: ProductType[];
  sidebarTitle: string;
  sidebarDescription: string;
  productCatalogue?: DocumentDownloadProps;
  cta: CtaProps;
}

const ProductListingTableModule = React.forwardRef(
  (
    {
      title,
      shortDescription,
      tableRows,
      sidebarTitle,
      sidebarDescription,
      productCatalogue,
      cta,
      ...rest
    }: ProductListingTableModuleProps,
    ref,
  ) => (
    <StyledContainer ref={ref} {...(rest as any)}>
      <StyledHeadingAndDescriptionContainer>
        <StyledHeadingAndDescriptionWrapper>
          <StyledModuleHeading>{title}</StyledModuleHeading>
          {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
        </StyledHeadingAndDescriptionWrapper>
      </StyledHeadingAndDescriptionContainer>
      <StyledContentContainer>
        <StyledProductBasicTable rows={tableRows} />
        <StyledSidebar>
          <StyledSidebarHeading>{sidebarTitle}</StyledSidebarHeading>
          <StyledSidebarDescription>{sidebarDescription}</StyledSidebarDescription>
          {productCatalogue && (
            <StyledDocumentDownloadWrapper>
              <DocumentDownload {...productCatalogue} />
            </StyledDocumentDownloadWrapper>
          )}
          {cta && cta.to ? (
            <StyledCta to={cta.to}>
              <Button>{cta.label}</Button>
            </StyledCta>
          ) : null}
        </StyledSidebar>
      </StyledContentContainer>
    </StyledContainer>
  ),
);
ProductListingTableModule.displayName = 'ProductListingTableModule';

export default ProductListingTableModule;
