import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';
//

const StyledSubscribeFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  ${Media({
    paddingLeft: ['0px', null, null, '0px'],
  })}
`;

export const StyledInput = styled.input<any>`
  font-size: 16px;
  padding-left: 24px;
  border: 1px solid ${props => props.theme.colors.darkGray};
  height: 48px;
  border-radius: ${props => props.theme.formInputs.borderRadius};
  ${Media({
    marginRight: [null, null, '8px', '8px', '8px'],
    width: ['100%', null, '334px', '334px', '334px'],
  })}
  &:focus {
    border: 2px solid ${props => props.theme.formInputs.activeColor};
  }
  outline: none;
`;

export const StyledForm = styled(Flex)`
  flex-wrap: wrap;

  button {
    ${Media({
      marginTop: ['8px', null, '0', '0', '0'],
      minWidth: [`100%`, null, '147px'],
    })}
  }
`;

export const StyledSuccessMessage = styled.div`
  color: ${props => props.theme.colors.green};
  display: flex;
  align-items: center;
  font-size: 12px;

  p {
    margin-left: 7px;
  }

  svg path {
    fill: ${props => props.theme.colors.green};
  }
`;

export const StyledFailureMessage = styled.div`
  color: ${props => props.theme.colors.red};
  display: flex;
  align-items: center;
  font-size: 12px;

  p {
    margin-left: 7px;
    flex: 1;
  }

  svg path {
    fill: ${props => props.theme.colors.red};
  }
`;

export const StyledInfoText = styled.p`
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.footer.fontSize || '12px'};
  margin-bottom: 24px;
  ${Media({
    width: [null, null, null, '349px'],
  })}
`;

export default StyledSubscribeFormContainer;
