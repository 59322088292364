import React from 'react';

import { HeroSectionModule } from '../../Modules';

type CtaProps = {
  to: string;
  label: string;
};

interface NotFoundPageProps {
  title?: string;
  shortDescription?: string;
  cta?: CtaProps;
}

const NotFoundPage = ({ title, shortDescription, cta }: NotFoundPageProps) => (
  <HeroSectionModule title={title} shortDescription={shortDescription} cta={cta} wide />
);

export default NotFoundPage;
