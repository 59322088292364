import * as React from 'react';

const HamburgerBrandX = () => (
  <svg height="18" viewBox="0 0 27 18" width="27" xmlns="http://www.w3.org/2000/svg">
    <g id="menu" transform="translate(-4.5 -9)">
      <path
        d="M29.813,12.375H6.188A1.688,1.688,0,0,1,6.188,9H29.813a1.687,1.687,0,0,1,0,3.375Z"
        data-name="Path 2451"
        fill="#505927"
        id="Path_2451"
      />
      <path
        d="M29.813,19.688H6.188a1.688,1.688,0,0,1,0-3.375H29.813a1.688,1.688,0,0,1,0,3.375Z"
        data-name="Path 2452"
        fill="#505927"
        id="Path_2452"
      />
      <path
        d="M29.813,27H6.188a1.688,1.688,0,0,1,0-3.375H29.813a1.688,1.688,0,0,1,0,3.375Z"
        data-name="Path 2453"
        fill="#505927"
        id="Path_2453"
      />
    </g>
  </svg>
);

export default HamburgerBrandX;
