import type { ProductCardProps, RichTextProps } from '@arvesta-websites/ui-components';
import { graphql } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import {
  ContentfulCertification,
  ContentfulProduct,
  ContentfulProductConnection,
  ContentfulSettings,
  Site,
} from '../generated/gatsby.types';
import transformCtaSection from '../transforms/BasicPage/CtaSection';
import transformCertification from '../transforms/Certification';
import extractCta from '../transforms/Cta';
import extractFileInforamtion from '../transforms/File';
import extractShortDescription from '../transforms/ShortDescription';
import { ProductPage } from '../ui-lib/components';
import richTextRawToJson from '../utils/contentful-rich-text-raw-to-json';

type ProductQueryType = {
  contentfulProduct: ContentfulProduct;
  site: Site;
  contentfulSettings: ContentfulSettings;
  translatedSlugs: ContentfulProductConnection;
};

type PageContextType = {
  id: number;
  node_locale: string;
};

type ProductProps = {
  data: ProductQueryType;
  pageContext: PageContextType;
};

const Product = ({ data, pageContext }: ProductProps) => {
  const {
    title,
    image,
    shortDescription,
    contentsSection,
    attributes,
    document,
    additionalDocuments = [],
    certification,

    relatedProducts,
    relatedProductsCta,
    updatedAt,
    ctaModule,
    seoMetadata,
  } = data.contentfulProduct;

  const attributesJson = attributes ? richTextRawToJson(attributes, pageContext.node_locale)?.json : null;

  const pageProps = {
    contentfulSettings: data.contentfulSettings,
    id: pageContext.id,
    isHomepage: false,
    ogPageType: 'article',
    pageDescription: extractShortDescription(shortDescription as any),
    pageImage: image || undefined,
    pageLocale: pageContext.node_locale,
    pageSettings: seoMetadata,
    pageSlug: data.contentfulProduct.slug ? data.contentfulProduct.slug : '',
    pageTitle: title as string,
    pageType: 'product',
    siteUrl: data.site.siteMetadata?.siteUrl as string,
    updatedAt,
  };

  const subscribeForm = {
    showFooterSubscribeForm: data.contentfulSettings.footerShowSubscribeForm || false,
    showFooterSubscribeFormCountryField: false,
    subscribeFormHeading: data.contentfulSettings.footerSubscribeFormHeading?.footerSubscribeFormHeading,
  };

  return (
    <Layout pageProps={pageProps} subscribeForm={subscribeForm} translatedSlugs={data.translatedSlugs.nodes}>
      <ProductPage
        title={title as string}
        shortDescription={extractShortDescription(shortDescription as any)}
        image={image}
        contentsSection={{
          data: contentsSection ? richTextRawToJson(contentsSection, pageContext.node_locale)?.json : null,
        }}
        attributes={attributesJson ? ({ data: attributesJson } as RichTextProps) : undefined}
        document={extractFileInforamtion(document)}
        additionalDocuments={
          additionalDocuments && Array.isArray(additionalDocuments)
            ? additionalDocuments.map(doc => extractFileInforamtion(doc))
            : []
        }
        certification={transformCertification(certification as Array<ContentfulCertification>)}
        relatedProducts={{
          cta: extractCta({ cta: relatedProductsCta }),
          products: relatedProducts
            ? (relatedProducts
                .map(relatedProduct => {
                  if (!relatedProduct) {
                    return null;
                  }
                  return {
                    id: relatedProduct.id,
                    image: relatedProduct.image,
                    link: relatedProduct.slug,
                    price: relatedProduct.price,
                    shortDescription: extractShortDescription(relatedProduct as any),
                    showLink: true,
                    title: relatedProduct.title,
                    variant: 'vertical',
                  };
                })
                .filter(Boolean) as ProductCardProps[])
            : [],
          shortDescription: null,
        }}
        ctaModule={ctaModule ? { ...transformCtaSection(ctaModule) } : null}
      />
    </Layout>
  );
};

export default Product;

export const pageQuery = graphql`
  query ($id: String!, $node_locale: String!, $brand_tag: String!, $contentful_id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    translatedSlugs: allContentfulProduct(filter: { contentful_id: { eq: $contentful_id } }) {
      nodes {
        contentful_id
        slug
        node_locale
      }
    }
    contentfulProduct(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      id
      title
      slug
      updatedAt(formatString: "YYYYMMDDHHmmss")
      seoMetadata {
        id
        description
        keywords
        no_index
        no_follow
        canonicalUrlpath
        title
      }
      shortDescription {
        text: shortDescription
      }
      category {
        slug
        title
      }
      price
      image {
        title
        alt: altText
        asset {
          description
          # fluid(quality: 85) {
          #   ...QueryImageFluid
          # }
          gatsbyImageData(quality: 85)
        }
        socialImage: asset {
          # fixed(width: 1200, height: 1200, resizingBehavior: CROP, quality: 100) {
          #   ...QueryImageFixed
          # }
          gatsbyImageData(layout: FIXED, width: 1200, height: 630, resizingBehavior: CROP, quality: 100)
        }
      }
      contentsSection {
        raw
        references {
          __typename
          ... on ContentfulAdvice {
            contentful_id
            slug
          }

          ... on ContentfulBasicPage {
            contentful_id
            slug
          }

          ... on ContentfulEvent {
            contentful_id
            slug
          }

          ... on ContentfulNews {
            contentful_id
            slug
          }

          ... on ContentfulProduct {
            contentful_id
            slug
          }

          ... on ContentfulAsset {
            contentful_id
            file {
              url
            }
          }

          ... on ContentfulAttachment {
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            title
            asset {
              file {
                url
                details {
                  size
                }
                contentType
              }
            }
          }

          ... on ContentfulBlockquote {
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            quote {
              text: quote
            }
            author
            authorInfo
            image {
              title
              alt: altText
              asset {
                file {
                  url
                }
                gatsbyImageData
              }
            }
          }

          ... on ContentfulImage {
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            title
            alt: altText
            asset {
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
              gatsbyImageData
            }
          }

          ... on ContentfulMediaBlock {
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            title
            shortDescription {
              text: shortDescription
            }
            caption {
              text: caption
            }
            orientation
            media {
              ... on ContentfulImage {
                __typename
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                title
                alt: altText
                asset {
                  file {
                    url
                  }
                  portraitImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.74)
                  squareImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
                  landscapeImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.77)
                }
              }

              ... on ContentfulVideo {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                videoUrl
              }
            }
          }

          ... on ContentfulShortBanner {
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            title
            image {
              title
              alt: altText
              asset {
                file {
                  url
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
                gatsbyImageData
              }
            }
            cta {
              title
              internalEntry {
                __typename
                ... on ContentfulAdvice {
                  contentful_id
                  slug
                }

                ... on ContentfulBasicPage {
                  contentful_id
                  slug
                }

                ... on ContentfulEvent {
                  contentful_id
                  slug
                }

                ... on ContentfulNews {
                  contentful_id
                  slug
                }

                ... on ContentfulProduct {
                  contentful_id
                  slug
                }
              }
              externalUrl
            }
          }

          ... on ContentfulTextSectionHighlight {
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            richTextContents {
              raw
              references {
                __typename
                ... on ContentfulAdvice {
                  contentful_id
                  slug
                }

                ... on ContentfulBasicPage {
                  contentful_id
                  slug
                }

                ... on ContentfulEvent {
                  contentful_id
                  slug
                }

                ... on ContentfulNews {
                  contentful_id
                  slug
                }

                ... on ContentfulProduct {
                  contentful_id
                  slug
                }

                ... on ContentfulAsset {
                  contentful_id
                  file {
                    url
                  }
                }
              }
            }
          }

          ... on ContentfulVideo {
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            videoUrl
          }

          ... on ContentfulTable {
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            table {
              tableData
            }
            fixFirstColumn
          }

          ... on ContentfulRichTextSection {
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            alignment
            richText {
              raw
              references {
                __typename
                ... on ContentfulAdvice {
                  contentful_id
                  slug
                }

                ... on ContentfulBasicPage {
                  contentful_id
                  slug
                }

                ... on ContentfulEvent {
                  contentful_id
                  slug
                }

                ... on ContentfulNews {
                  contentful_id
                  slug
                }

                ... on ContentfulProduct {
                  contentful_id
                  slug
                }

                ... on ContentfulAsset {
                  contentful_id
                  file {
                    url
                  }
                }

                ... on ContentfulAttachment {
                  contentful_id
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                  title
                  asset {
                    file {
                      url
                      details {
                        size
                      }
                      contentType
                    }
                  }
                }

                ... on ContentfulBlockquote {
                  contentful_id
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                  quote {
                    text: quote
                  }
                  author
                  authorInfo
                  image {
                    title
                    alt: altText
                    asset {
                      file {
                        url
                      }
                      gatsbyImageData
                    }
                  }
                }

                ... on ContentfulImage {
                  contentful_id
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                  title
                  alt: altText
                  asset {
                    file {
                      url
                      details {
                        image {
                          width
                          height
                        }
                      }
                    }
                    gatsbyImageData
                  }
                }

                ... on ContentfulMediaBlock {
                  contentful_id
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                  title
                  shortDescription {
                    text: shortDescription
                  }
                  caption {
                    text: caption
                  }
                  orientation
                  media {
                    ... on ContentfulImage {
                      __typename
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      title
                      alt: altText
                      asset {
                        file {
                          url
                        }
                        portraitImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.74)
                        squareImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
                        landscapeImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.77)
                      }
                    }

                    ... on ContentfulVideo {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      videoUrl
                    }
                  }
                }

                ... on ContentfulShortBanner {
                  contentful_id
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                  title
                  image {
                    title
                    alt: altText
                    asset {
                      file {
                        url
                        details {
                          image {
                            width
                            height
                          }
                        }
                      }
                      gatsbyImageData
                    }
                  }
                  cta {
                    title
                    internalEntry {
                      __typename
                      ... on ContentfulAdvice {
                        contentful_id
                        slug
                      }

                      ... on ContentfulBasicPage {
                        contentful_id
                        slug
                      }

                      ... on ContentfulEvent {
                        contentful_id
                        slug
                      }

                      ... on ContentfulNews {
                        contentful_id
                        slug
                      }

                      ... on ContentfulProduct {
                        contentful_id
                        slug
                      }
                    }
                    externalUrl
                  }
                }

                ... on ContentfulTable {
                  contentful_id
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                  table {
                    tableData
                  }
                  fixFirstColumn
                }

                ... on ContentfulTextSectionHighlight {
                  contentful_id
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                  richTextContents {
                    raw
                    references {
                      __typename
                      ... on ContentfulAdvice {
                        contentful_id
                        slug
                      }

                      ... on ContentfulBasicPage {
                        contentful_id
                        slug
                      }

                      ... on ContentfulEvent {
                        contentful_id
                        slug
                      }

                      ... on ContentfulNews {
                        contentful_id
                        slug
                      }

                      ... on ContentfulProduct {
                        contentful_id
                        slug
                      }

                      ... on ContentfulAsset {
                        contentful_id
                        file {
                          url
                        }
                      }
                    }
                  }
                }

                ... on ContentfulVideo {
                  contentful_id
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                  videoUrl
                }
              }
            }
          }
        }
      }
      attributes {
        raw
        references {
          __typename
          ... on ContentfulAdvice {
            contentful_id
            slug
          }

          ... on ContentfulBasicPage {
            contentful_id
            slug
          }

          ... on ContentfulEvent {
            contentful_id
            slug
          }

          ... on ContentfulNews {
            contentful_id
            slug
          }

          ... on ContentfulProduct {
            contentful_id
            slug
          }

          ... on ContentfulAsset {
            contentful_id
            file {
              url
            }
          }
        }
      }
      document {
        id
        title
        asset {
          description
          file {
            contentType
            url
            details {
              size
            }
            fileName
          }
        }
      }
      additionalDocuments {
        id
        title
        asset {
          description
          file {
            contentType
            url
            details {
              size
            }
            fileName
          }
        }
      }
      certification {
        title
        asset {
          description
          # fixed(width: 112, height: 112, resizingBehavior: FILL, quality: 85) {
          #   ...QueryImageFixed
          # }
          gatsbyImageData(layout: FIXED, width: 112, height: 112, resizingBehavior: FILL, quality: 85)
        }
        url
      }
      relatedProducts {
        id
        slug
        title
        shortDescription {
          text: shortDescription
        }
        image {
          title
          alt: altText
          asset {
            description
            # fluid(quality: 85) {
            #   ...QueryImageFluid
            # }
            gatsbyImageData(layout: CONSTRAINED, quality: 85)
          }
        }
      }
      relatedProductsCta {
        externalUrl
        internalEntry {
          ...FragmentNavigationLink
        }
        title
      }
      ctaModule {
        ...QueryCtaBanner
      }
    }

    contentfulSettings(
      node_locale: { eq: $node_locale }
      metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
    ) {
      ...QuerySiteSettings
    }
  }
`;
