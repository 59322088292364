import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';

export const StyledContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  max-width: ${props => props.theme.sizes.xl};
  position: relative;
  ${Media({
    marginLeft: ['1rem', null, '2rem', '58px'],
    marginRight: ['1rem', null, '2rem', '58px'],
  })}
`;

export const StyledOfficeContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  white-space: pre-line;
  justify-content: space-between;
  max-width: 600px;
`;

export const StyledSalesTeamList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledInfoContainer = styled(Box)`
  ${Media({
    flex: ['100%', null, null, '50%'],
    width: ['100%', null, null, '50%'],
  })}
`;

export const StyledFormContainer = styled(Flex)`
  align-items: flex-start;
  position: relative;

  ${Media({
    flex: ['100%', null, null, '50%'],
    justifyContent: ['flex-start', null, null, 'flex-end'],
    paddingTop: ['0px', null, '56px', '0px'],
    width: ['100%', null, '75%', '50%'],
  })}
`;

export const StyledModuleHeading = styled(Text)`
  line-height: 1.14;
  margin-bottom: 24px;
  word-break: break-word;
  font-weight: ${props => props.theme.contacts.titleFontWeight || 'normal'};

  ${Media({
    fontSize: ['2.5rem', null, null, '3.5rem'],
    marginTop: ['32px', null, null, '81px'],
  })}

  color: ${props => props.theme.colors.text};
`;

export const StyledModuleDescription = styled(Text)`
  font-size: 1.25rem;
  margin-bottom: 48px;
  color: ${props => props.theme.colors.text};
`;

export const StyledContactsInforamtionSection = styled.div`
  margin-right: 40px;

  a {
    display: block;
  }
`;

export const StyledSalesTeamTitle = styled.div`
  font-size: 2rem;
  line-height: 1.25;
  margin-top: 56px;

  color: ${props => props.theme.colors.text};
  ${Media({
    marginBottom: ['20px', null, null, '48px'],
  })}
`;

export const StyledRectangle = styled(Box)`
  position: absolute;
  top: 80px;
  right: 0;
  height: 724px;
  background-color: ${props => props.theme.colors.primary};
  z-index: -1;

  ${Media({
    display: ['none', null, null, 'block'],
    width: ['420px', null, null, null, '478px'],
  })}
`;

export const StyledRectangleTablet = styled(Box)`
  position: absolute;
  top: 0px;
  height: 760px;
  left: 300px;
  background-color: ${props => props.theme.colors.primary};
  z-index: -1;
  width: 424px;

  ${Media({
    display: ['none', null, 'block', 'none'],
  })}
`;

export const StyledForm = styled(Box)`
  max-width: 533px;
  width: 100%;
  background-color: white;
  margin-top: 247px;
  margin-right: 20px;

  ${Media({
    boxShadow: ['none', null, '0 2px 20px 0 rgba(0, 0, 0, 0.1)', '0 2px 20px 0 rgba(0, 0, 0, 0.1)'],
    marginRight: ['0px', null, null, '3.5rem'],
    marginTop: ['20px', null, null, '247px'],
    padding: ['0px', null, '30px', '30px'],
  })}

  @media all and (max-width: 1355px) {
    margin-left: 0px;
  }
`;

export const StyledFormButton = styled(Box)`
  margin-top: 30px;
`;

export const StyledHeading = styled(Text)`
  font-size: 2rem;
  color: ${props => props.theme.colors.text};
  margin-bottom: 2rem;
`;
