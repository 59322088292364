/* eslint-disable no-underscore-dangle */
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { getSrc } from 'gatsby-plugin-image';

import {
  ContentfulEventConnection,
  ContentfulEventEdge,
  ContentfulLocationContentfulMagentoStoreContentfulRichTextSectionUnion,
} from '../../generated/gatsby.types';
import extractShortDescription from '../ShortDescription';

const getEventLocation = (location: ContentfulLocationContentfulMagentoStoreContentfulRichTextSectionUnion) => {
  if (location.__typename === 'ContentfulLocation') {
    return `${location.title} ${location.address?.address}`;
  }

  if (location.__typename === 'ContentfulRichTextSection') {
    return location?.richText?.raw ? documentToPlainTextString(JSON.parse(location.richText.raw)) : '';
  }

  return null;
};

const extractEvents = (edges: Array<ContentfulEventEdge>) => {
  return edges
    .map((edge: ContentfulEventEdge) => {
      const eventItem = edge.node;

      if (!eventItem) {
        return null;
      }

      return {
        displayDate: eventItem.displayDate,
        id: eventItem.id,
        image: getSrc(eventItem.hero?.image?.asset?.gatsbyImageData),
        link: eventItem.slug || '/',
        location: eventItem.location ? getEventLocation(eventItem.location) : '',
        shortDescription: extractShortDescription(eventItem),
        title: eventItem.hero?.title,
      };
    })
    .filter(Boolean);
};

const transformEventsListingSection = (item: any, events: ContentfulEventConnection) => {
  return {
    __typename: 'EventsListingSection',
    events: extractEvents(events.edges),
    key: item.id,
    shortDescription: extractShortDescription(item),
    title: item.title,
  };
};

export default transformEventsListingSection;
